<template>
    <div>
        <el-row v-for="criticise,index in article_criticise" :key="index"> 
            <el-col span="24"> 
                <el-row> 
                    <el-col>
                        <small class="text-muted">踩的时间：{{ criticise.create_time }}</small>
                        <p></p>
                    </el-col>
                </el-row>
                <el-row> 
                    <el-col span="18"> 
                        <router-link :to="{path:'articleshowview',query:{id:criticise.article_id,user_id:criticise.article_user_id}}">
                            <p style="font-size:15px;color:#FF7F00">
                                {{ criticise.article_title }}
                            </p>
                        </router-link>
                    </el-col>
                    <el-col span="6">
                        <el-button @click="cancelCriticise(criticise.article_id)" type="danger" icon="el-icon-delete" size="small" circle></el-button>
                    </el-col>
                </el-row>
                <el-row> 
                    <el-col span="1"> 
                        <img :src="criticise.article_user_head_img" class="img-circle-30"/>
                    </el-col>
                    <el-col span="23"> 
                        <p style="font-size:14px">{{ criticise.article_user_name }}</p>
                    </el-col>
                </el-row>
                <hr/>
            </el-col>
            
        </el-row>
    
    </div>
</template>

<script>

import { cancelCriticise } from '@/api/article-server/saveData';

export default{

    props:{
        article_criticise:{
            type:Object,
            required:true
        }
    },
    methods:{
        async cancelCriticise(articleId){
            const result = await cancelCriticise(articleId)
            if(result.data.code==0){
                this.$emit('toRefreshCriticise')
            }
        }
    }

}

</script>


<style lang="scss" scoped>

a{
    text-decoration: none;
}

</style>