<template>
    <div>
        <div class="row"> 
            <div class="row" style="text-align:left"> 
                <h6>浏览历史</h6>
                <hr/>
            </div>

        </div>
    
    
    </div>
</template>

<script>



export default{



}

</script>


<style lang="scss" scoped>


</style>