<template>
    <div>
        <el-tabs  v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="我的收藏" name="1">
                <article-browse-collection-view @toRefreshCollection="getCollection" :article_collection="article_collection"></article-browse-collection-view>
            </el-tab-pane>
            <el-tab-pane label="我的点赞" name="2">
                <article-browse-praise-view @toRefreshPraise="getPraise" :article_praise="article_praise"></article-browse-praise-view>
            </el-tab-pane>
            <el-tab-pane label="我的踩" name="3">
                <article-browse-criticise-view @toRefreshCriticise="getCriticise" :article_criticise="article_criticise"></article-browse-criticise-view>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { getArticleCollection,getArticlePraise,getArticleCriticise } from '@/api/article-server/getData'
import ArticleBrowseCollectionView from './Components/ArticleBrowseCollectionView.vue'
import ArticleBrowsePraiseView from './Components/ArticleBrowsePraiseView.vue'
import ArticleBrowseCriticiseView from './Components/ArticleBrowseCriticiseView.vue'



export default{

    components: { 
        ArticleBrowseCollectionView,
        ArticleBrowsePraiseView,
        ArticleBrowseCriticiseView
    },

    data(){
        return{
            activeName:"1", 
            article_collection:'',
            article_praise:'',
            article_criticise:''
        }
    },

    methods:{

        async getCollection(){
            const result = await getArticleCollection()
            if(result.data.code==0){
                this.article_collection = result.data.data
            }
        },
        async getPraise(){
            const result = await getArticlePraise()
            if(result.data.code==0){
                this.article_praise = result.data.data
            }
        },
        async getCriticise(){
            const result = await getArticleCriticise()
            if(result.data.code==0){
                this.article_criticise = result.data.data
            }
        }
    },

    created(){
        this.getCollection();
        this.getPraise();
        this.getCriticise();
    }



}

</script>


<style lang="scss" scoped>


</style>