<template>
    <div>
        <el-row v-for="collection,index in article_collection" :key="index"> 
            <el-col span="24"> 
                <el-row> 
                    <el-col>
                        <small class="text-muted">收藏时间：{{ collection.create_time }}</small>
                        <p></p>
                    </el-col>
                </el-row>
                <el-row> 
                    <el-col span="18" > 
                        <router-link :to="{path:'articleshowview',query:{id:collection.article_id,user_id:collection.article_user_id}}">
                            <p style="font-size:15px;color:#FF7F00">
                                {{ collection.article_title }}
                            </p>
                        </router-link>
                    </el-col>
                    <el-col span="6">
                        <el-button @click="cancelCollection(collection.article_id)" type="danger" icon="el-icon-delete" size="small" circle></el-button>
                    </el-col>
                </el-row>
                <el-row> 
                    <el-col span="1"> 
                        <img :src="collection.article_user_head_img" class="img-circle-30"/>
                    </el-col>
                    <el-col span="23"> 
                        <p style="font-size:14px">{{ collection.article_user_name }}</p>
                    </el-col>
                </el-row>
                <hr/>
            </el-col>
            
        </el-row>
    
    </div>
</template>

<script>
import { cancelCollection } from '@/api/article-server/saveData';


export default{

    props:{
        article_collection:{
            type:Object,
            required:true
        }
    },
    methods:{
        async cancelCollection(articleId){
            const result = await cancelCollection(articleId)
            if(result.data.code==0){
                this.$emit('toRefreshCollection')
            }
        }
    }

}

</script>


<style lang="scss" scoped>
a{
    text-decoration: none;
}

</style>