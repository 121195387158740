<template>
<div style="text-align:left"> 
    <p></p>
    <div class="card text-dark bg-light mb-3 layout-scroll-parent" style="width: 100%;height: auto;background-color: white;" >
        <div class="card-header"><h6>当前专栏：&nbsp;"{{classify_name}}"</h6></div>
        <div class="card-body layout-scroll-child">
            <p class="card-text text-secondary" v-for="article,index in article_classify" :key="index">
                <!-- <b class="card-num">{{index+1}}</b>&nbsp;&nbsp;<small class="card-title"><a  @click="refreshPage(article.id,article.user_id)">{{article.big_title}}</a></small> -->              
                <!-- <el-button @click="refreshPage(article.id,article.user_id)" style="text-align:left" class="btn" type="text">
                    <b class="card-num">{{index+1}}</b>
                    &nbsp;&nbsp;
                    {{article.big_title}}
                    &nbsp;
                    <small>{{article.viewed}}</small>
                </el-button>    -->
                <router-link :to="{query:{id:article.id,user_id:article.user_id}}">
                    <b class="card-num">{{index+1}}</b>
                    &nbsp;&nbsp;
                    <small class="card-title"><a  @click="refreshPage(article.id,article.user_id)">{{article.big_title}}</a></small>
                    &nbsp;
                    <small>{{article.viewed}}</small>
                </router-link>                                 
            </p>                    
        </div>
    </div>
</div>
</template>

<script>



export default{

    props:{
        article_classify:{
            type:Object,
            required:true
        },
        classify_name:{
            type:Object,
            required:true
        }        
    },

    methods:{
        refreshPage(id,user_id){
            //提交父方法，刷新页面
            this.$emit('toRefreshAll',id,user_id)
        }
    }

}

</script>


<style lang="scss" scoped>

    .btn{
		width: auto;
	    word-wrap: break-word;
	    overflow-wrap: break-word;
	    white-space: normal;
	    height: auto;
	    overflow: hidden;
        border: none;
	}


.card-header{
    padding-top: 15px;
    text-align: left;
    background-color: #ffffff;
}
.card-body{
    text-align: left;
    background-color: #ffffff;
}
.card-num{
    color: #c75e2a;
    font-size: 15px;
}
.card-title{
    // color: black;
    font-size:14px;
}

a{
    text-decoration: none;
    color: black;
}


</style>