<template>
  <div class="bg">
      <div>
        <head-view :UserDetail="UserDetail"></head-view>
      </div>
      <br>
      <el-row>
        <el-col span=24>
          <el-row>
            <el-col span=4 class="layout-el-col-blank">

            </el-col>
            <el-col span=12>
              <div class="col-12">
                <div class="input-group mb-3 ">
                  <input v-model="big_title" @keyup.enter="search" type="text" class="form-control head-search-input" placeholder="输入关键词搜索……" aria-label="Example text with button addon" aria-describedby="button-addon1"/>
                  <button @click="search" class="btn btn-outline-secondary head-search-button"  type="button" id="button-addon1">
                    <b-icon icon="search" variant="warning"></b-icon>
                  </button>
                </div>
              </div>
            </el-col>
            <el-col span=5>

            </el-col>
          </el-row>
          <el-row>
            <el-col span=4 class="layout-el-col-blank">

            </el-col>
            <el-col span=12 style="background-color: white">
              <el-card class="box-card">
                <el-tabs v-model="activeName" @tab-click="handleClick" >
                  <el-tab-pane label="文章" name="article">
                    <!-- <article-list-view :ArticleList="ArticleList"></article-list-view> -->
                      <el-row>
                        <div
                        class="infinite-list" 
                        v-infinite-scroll="load"  
                        infinite-scroll-distance="5"  
                        style="overflow-y:scroll;
                        height:600px"
                        >                       
                        <el-col span=24 v-for="(article,index) in ArticleList" :key="index" style="text-align: left">
                          <router-link :to="{path:'/articleshowview',query:{id:article.id,user_id:article.user_id}}" style="color: black">
                            <el-row>
                              <el-col span=24 >
                                <h6 v-html="article.big_title"></h6>
                              </el-col>
                            </el-row>
                            <el-row>
                              <el-col span=24>
                                <small v-html="article.summary" class="text-muted"></small>
                              </el-col>
                            </el-row>
                            <el-row>
                              <el-col span=24>
                                <small  class="text-muted">{{article.viewed}}</small> 
                              </el-col>
                            </el-row>
                            <hr/>
                          </router-link>
                        </el-col>
                      </div>
                      </el-row>
                  </el-tab-pane>
                  <el-tab-pane label="课程" name="second">暂无课程</el-tab-pane>
                  <el-tab-pane label="题库" name="third">暂无题库</el-tab-pane>
                  <el-tab-pane label="视频" name="fourth">暂无视频</el-tab-pane>
                </el-tabs>
              </el-card>

            </el-col>
          </el-row>
        </el-col>
      </el-row>
  </div>
</template>

<script>

import HeadView from "@/components/HeadView"
import {getArticlesForTitleByPublic} from "@/api/article-server/getData";
import ArticleListView from "@/views/Search/Components/ArticleListView";

export default {

  components:{
    ArticleListView,
    HeadView
  },

  data(){
    return{
      UserDetail:'',
      ArticleList:'',
      big_title:'',
      activeName: 'article',
      page:1,
      size:10,
      total_page:0
    }
  },

  watch:{
    big_title(){
      this.search()
    }
  },

  methods:{

    load(){
      if(this.page<this.total_page){
        this.page+=1
        this.getArticlesForTitleByPublicLoad(this.big_title)
      }
    },

    handleClick(tab, event) {
      console.log(tab, event);
    },

    search(){
      this.page=1
      this.getArticlesForTitleByPublic(this.big_title);
    },

    async getArticlesForTitleByPublicLoad(big_title){
      const result = await getArticlesForTitleByPublic(big_title,this.page,this.size);
      if(result.data.code==0){
        this.ArticleList = this.ArticleList.concat(result.data.data.current_data)
        this.total_page = result.data.data.total_page
      }
    },


  

    async getArticlesForTitleByPublic(big_title){
      const result = await getArticlesForTitleByPublic(big_title,this.page,this.size);
      if(result.data.code==0){
        this.ArticleList = result.data.data.current_data
        this.total_page = result.data.data.total_page
      }
    }

  },
  

  created() {
    this.UserDetail = {"head_img":this.$utils.localStorageGetItem("user_head_img")};
    this.big_title = this.$route.params.big_title
    this.getArticlesForTitleByPublic(this.$route.params.big_title);
  }

}

</script>

<style lang="scss" scoped>

::v-deep(em){
  font-style: normal;
  color: #b84d4d;
}

.bg{
  background-color: #f5f5f5;
  height: 1200px;
}

.head-search{
  position: sticky;
  margin-left: 35%;
  margin-top: 150px;
  top: 0;
}
.input-group{
  height: 45px;
  position:sticky;
  top: 0;
}
.head-search-input{
  border-radius: 90px;
      //height: 42px;
  width: 80%;//给另一个元素按钮留出20%空间
}
.head-search-button{
  border-radius: 90px;
  //    height: 38px;
  background-color: #e73803;
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
  border-left-color: #ffffff;
  border-right-color: #ffffff;
}
</style>