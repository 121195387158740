import axios from "./base";


/**
 * 获取图形验证码key
 * @returns 图形验证码的key
 */
export const getImageCodeKey=()=>axios.get("notify/pub/v1/get_image_code_key")


/**
 * 获取用户的详细信息
 * @returns 用户的详细信息
 */
export const getUserInfo=(token)=>axios.post("info/pri/v1/get_user_detail_by_token",
{
    token
},
{
    headers:{
        token
    }
}
)

export const getUserSummary=(user_id)=>axios.get("info/pub/v1/get_user_summary_by_id",
{
    params:{
        user_id
    }
})