<template>
    <div>
        <el-row> 
            <el-col span=24> 
                <el-row> 
                    <el-col span=24> 
                        <el-row> 
                            <el-card class="box-card">
                                <div class="row"> 
                                    <div class="col-2">                    
                                        <el-image class="img-circle-50"                        
                                            :src="UserDetail.head_img" 
                                            :preview-src-list="[UserDetail.head_img]">
                                        </el-image>
                                    </div>
                                    <div class="col-10">
                                        <div class="row"> 
                                            <div class="col-12" style="font-size:14px;text-align: left;">                                 
                                                &nbsp;&nbsp;&nbsp;
                                                {{UserDetail.name}}
                                                &nbsp;&nbsp;
                                                Lv7
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </div>
                                        </div>                
                                        <div class="row" style="font-size:14px;color: darkgray;"> 
                                            <div class="col-6" style="text-align: left;"> 
                                                &nbsp;&nbsp;&nbsp;
                                                书龄3年
                                                &nbsp;&nbsp;&nbsp;                           
                                            </div>
                                            <div class="col-6"> 
                                                <a style="color:darkgray">
                                                    <i class="el-icon-close"></i>
                                                    暂无认证
                                                </a>
                                            </div>                            
                                        </div>         
                                    </div>
                                    <hr style="color:darkgray"/>
                                </div>
                                <el-row style="font-size:14px;text-align: center;"> 
                                    <el-col span="4">{{UserDetail.article}}<p>原创</p></el-col>
                                    <el-col span="5">{{UserDetail.week_rank}}<p>周排名</p></el-col>
                                    <el-col span="5">{{UserDetail.total_rank}}<p>总排名</p></el-col>
                                    <el-col span="5">{{UserDetail.visit}}<p>访问</p></el-col>
                                    <el-col span="5">{{UserDetail.visit}}<p>等级</p></el-col>
                                </el-row>
                                <el-row style="font-size:14px;text-align: center;">
                                    <el-col span="4">{{UserDetail.fans}}<p>粉丝</p></el-col>
                                    <el-col span="5">{{UserDetail.praise}}<p>获赞</p></el-col>
                                    <el-col span="5">{{UserDetail.comments}}<p>评论</p></el-col>
                                    <el-col span="5">{{UserDetail.collection}}<p>收藏</p></el-col>
                                    <el-col span="5">{{UserDetail.collection}}<p>积分</p></el-col>
                                </el-row>               
                                <div class="row"> 
                                    <div class="col-2"> 
                                        <el-image class="img-circle-30" :src="url"  :preview-src-list="srcList"></el-image>
                                    </div>
                                    <div class="col-2"> 
                                        <el-image class="img-circle-30" :src="url" :preview-src-list="srcList"></el-image>
                                    </div>
                                    <div class="col-2"> 
                                        <el-image class="img-circle-30" :src="url" :preview-src-list="srcList"></el-image>
                                    </div>
                                    <div class="col-2"> 
                                        <el-image class="img-circle-30" :src="url" :preview-src-list="srcList"></el-image>
                                    </div>
                                    <div class="col-2"> 
                                        <el-image class="img-circle-30" :src="url" :preview-src-list="srcList"></el-image>
                                    </div>
                                    <div class="col-2"> 
                                        <el-image class="img-circle-30" :src="url"  :preview-src-list="srcList"></el-image>
                                    </div>
                                </div>
                                <div class="row"> 
                                    <div class="col-2"> 
                                        <el-image class="img-circle-30" :src="url"  :preview-src-list="srcList"></el-image>
                                    </div>
                                    <div class="col-2"> 
                                        <el-image class="img-circle-30" :src="url"  :preview-src-list="srcList"></el-image>
                                    </div>
                                    <div class="col-2"> 
                                        <el-image class="img-circle-30"  :src="url"  :preview-src-list="srcList"></el-image>
                                    </div>
                                    <div class="col-2"> 
                                        <el-image class="img-circle-30"  :src="url"  :preview-src-list="srcList"></el-image>
                                    </div>
                                    <div class="col-2"> 
                                        <el-image class="img-circle-30" :src="url" :preview-src-list="srcList"></el-image>
                                    </div>
                                    <div class="col-2"> 
                                        <el-image class="img-circle-30"  :src="url"  :preview-src-list="srcList"></el-image>
                                    </div>
                                </div>
                                <hr style="color:darkgrey"/>
                                <div class="row" style="text-align:center"> 
                                    <div class="col-6"><el-button type="primary" size="small" round>私信</el-button></div>
                                    <div class="col-6"><el-button type="danger" size="small" round>关注</el-button></div>
                                </div>
                            </el-card>   
                        </el-row>
                    </el-col>
                </el-row>
                <p></p>
                <el-row> 
                    <el-col span=24> 
                        <el-row> 
                            <div class="card text-dark bg-light mb-3 layout-scroll-parent" style="width: 100%;height: auto;background-color: white;" >
                                <div class="card-header"><h6>热门榜单</h6></div>
                                <div class="card-body layout-scroll-child">
                                    <p class="card-text text-secondary" v-for="(article,index) in article_hot" :key="index">
                                        <router-link :to="{name:'ArticleShowView',path:'/articleshowview',query:{id:article.id,user_id:article.user_id}}">
                                            <b class="card-num">{{index+1}}</b>&nbsp;&nbsp;<small class="card-title">
                                                <a  @click="refreshPage(article.id,article.user_id)">{{article.big_title}}</a>
                                            </small>
                                            &nbsp;
                                            <small>{{article.viewed}}</small>  
                                        </router-link>                    
                                    </p>                    
                                </div>
                            </div>
                        </el-row>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>    
    </div>
</template>

<script>



export default{
    data(){
        return{
            url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            srcList: [
            'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
            'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
            ]
        }
    },

    props:{
        UserDetail:{
            type:Object,
            required:true
        },
        article_hot:{
            type:Object,
            required:true
        }
    }

}

</script>


<style lang="scss" scoped>

a{
    text-decoration: none;
    color: black;
}


.card-header{
    padding-top: 15px;
    text-align: left;
    background-color: #ffffff;
}
.card-body{
    text-align: left;
    background-color: #ffffff;
}
.card-num{
    color: #c75e2a;
    font-size: 15px;
}
.card-title{
    color: black;
    font-size:14px;
}



</style>