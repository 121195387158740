<template>
<div>   
    <div style="border: 1px solid #ccc;">
        <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
        />
        <Editor
            style="height: 650px; overflow-y: hidden;"
            v-model="html"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="onCreated"
        />
    </div>
</div>
</template>
<script>
import Vue from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default Vue.extend({
    components: { 
        Editor, 
        Toolbar 
    },
    data() {
        return {
            editor: null,
            html: '<p>hello</p>',
            toolbarConfig: { 
                excludeKeys:['uploadVideo']
            },
            editorConfig: { 
                placeholder: '请输入内容...',
                // 所有的菜单配置，都要在 MENU_CONF 属性下
                MENU_CONF: {
                    uploadImage: {
                        server: this.$utils.getApiHostPort()+this.$url.get_upload_image_server(),
                        // server: 'http://127.0.0.1:5200/article-server/api/upload/pri/v1/article_content_img',
                        // form-data fieldName ，默认值 'wangeditor-uploaded-image'
                        fieldName: 'article_content_img',

                        // 单个文件的最大体积限制，默认为 2M
                        maxFileSize: 4 * 1024 * 1024, // 4M

                        // 最多可上传几个文件，默认为 100
                        maxNumberOfFiles: 5,

                        // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
                        allowedFileTypes: ['image/*'],

                        // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
                        meta: {
                            token: this.$utils.localStorageGetItem("token"),
                            otherKey: 'yyy'
                        },

                        // 将 meta 拼接到 url 参数中，默认 false
                        metaWithUrl: true,

                        // 自定义增加 http  header
                        headers: {
                            // Accept: 'text/x-json',
                            Accept: '*/*',
                            otherKey: 'xxx'
                        },

                        // 跨域是否传递 cookie ，默认为 false
                        withCredentials: false,

                        // 超时时间，默认为 10 秒
                        timeout: 10 * 1000, // 10 秒
                   }
               } 
            },
            mode: 'default', // or 'simple'
        }
    },
    methods: {
        onCreated(editor) {         
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },
    },
    watch:{
        html(){
            this.$emit('childEditorContent',this.html)
        }
    },
    mounted() {
        // 模拟 ajax 请求，异步渲染编辑器
        setTimeout(() => {
            this.html = '<p>hai,请输入内容...</p>'
        }, 1500)
    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    }
})
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>