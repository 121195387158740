<template>
    <div>
        <el-row> 
            <el-col span=24> 
                <head-view :UserDetail="{head_img:this.$utils.localStorageGetItem('user_head_img')}"></head-view>
            </el-col>
        </el-row>

        <el-row gutter=10 style="background-image:url(https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F0370fea5a5da8d1a80120121fd2dbc5.jpg&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1664690349&t=5c686b619697d0274b220864e7efaf19)">
                    <br/>
                <br/>
        <br/>
        <br/>
        <br/>
            <el-col span=4 class="layout-el-col-blank"> 

            </el-col> 
            <el-col span=4> 
                <classify-left-view :UserDetail="UserDetail" :article_hot="article_hot"></classify-left-view>
            </el-col>
            <el-col span=12> 
                <el-row>
                    <classify-center-view :UserDetail="UserDetail" :article_classify="article_classify" :classify="classify"></classify-center-view>
                </el-row>
            </el-col>
            <el-col span=4> 

            </el-col>
        </el-row>
        
        <el-row> 
            <el-col span=24> 
                <copy-right-view></copy-right-view>
            </el-col>
        </el-row>
    </div> 
</template>

<script>
import HeadView from '@/components/HeadView.vue'
import CopyRightView from '@/components/CopyRightView.vue'
import ClassifyLeftView from './Components/ClassifyLeftView.vue';
import ClassifyCenterView from './Components/ClassifyCenterView.vue';
import { getUserSummary } from '@/api/user-server/getData';
import { getArticleHotByUser,getArticlesByClassifyId,getClassifyByClassifyId } from '@/api/article-server/getData';

export default{

  components: { 
    HeadView,
    CopyRightView,
    ClassifyLeftView,
    ClassifyCenterView 
  },

  data(){
    return{
        UserDetail:'',
        article_hot:'',
        article_classify:'',
        classify:''
    }
  },


  methods:{
    async getUserSummary(){
        const result = await getUserSummary(this.$route.query.user_id)
        if(result.data.code==0){
            this.UserDetail = result.data.data
        }
    },
    async getArticleHotByUser(){
        const result = await getArticleHotByUser(this.$route.query.user_id,1,10,30)
        if(result.data.code==0){
            this.article_hot = result.data.data.current_data
        }
    },
    async getArticlesByClassifyId(){
        const result = await getArticlesByClassifyId(this.$route.query.classify_id)
        if(result.data.code==0){
            this.article_classify = result.data.data
        }
    },
    async getClassifyByClassifyId(){
        const result = await getClassifyByClassifyId(this.$route.query.classify_id)
        if(result.data.code==0){
            this.classify = result.data.data
        }
    }

  },

  created(){
    this.getUserSummary();
    this.getArticleHotByUser();
    // this.getArticlesByClassifyId();
    this.getClassifyByClassifyId();
  }

}

</script>


<style lang="scss" scoped>


</style>