<template>
  <div class="bg">
    <head-view :UserDetail="UserDetail"></head-view>
    <home-logo-view></home-logo-view>
    <div class="row" >
      <div class="col-1"> 
        <!-- <router-link to="adminhomeview">adkefefs</router-link> -->
      </div>
      <div class="col-10">        
        <div class="row">          
          <div class="col-3">
            <div class="row">
              <div class="col-12"> 
                <left-view></left-view>
              </div>              
            </div>
          </div>
          <div class="col-9"> 
            <div class="row">
              <div class="col-12"> 
                <rignt-view></rignt-view>
              </div>             
            </div>
            <div class="row"></div>
          </div>
        </div>
      </div>
      <div class="col-1"></div>
    </div>
    <br>
    <div class="row">
      <copy-right-view></copy-right-view>
    </div>
  </div>
</template>

<script>

import HeadView from "@/components/HeadView.vue";
import LeftView from "./Components/LeftView.vue";
import RigntView from "./Components/RigntView.vue";
import CopyRightView from "../../components/CopyRightView.vue";
import HomeLogoView from "./Components/HomeLogoView.vue";

// import {getxxx} from '@/api/.....'

export default{

    //2、注册组件
    components:{
    HeadView,
    LeftView,
    RigntView,
    CopyRightView,
    HomeLogoView
},

    //存储数据
    data(){
      return{
        UserDetail:""
      }
    },

    methods:{
    },
    
    mounted(){
    },

    created(){
      this.UserDetail = {"head_img":this.$utils.localStorageGetItem("user_head_img")}
    }
    
}

</script>


<style lang="scss" scoped>


.bg{
background-color: #f5f5f5;
}

</style>