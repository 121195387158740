<template >

  <el-row >
    <el-row v-loading="loading" >
        <el-col span=2 style="text-align: center;">
          <!-- <input type="checkbox"  id="all" @click="allCheck" />全选&nbsp;&nbsp;&nbsp;&nbsp; -->
        </el-col>
        <el-col span=20 class="layout-el-col-blank">
        </el-col>
        <el-col span=2>
<!--          <small style="color:dodgerblue">一键删除</small>-->
        </el-col>
    </el-row>
    <br/>
    <el-col span=24 v-for="(comment,index) in article_comments" :key="index">
      <el-row>
        <!-- <el-col span=1 style="text-align: center">
          <input type="checkbox"  name="one"   @click="oneChecked(comment.id)" />
        </el-col> -->
        <el-col span=2 style="text-align: center">
          <img :src="comment.from_head_img" class="img-circle-60"/>
        </el-col>
        <el-col span=17>
          <el-row>
            <el-col span=24>
              <p class="text-muted"><small style="color: black">{{comment.from_name}}</small>&nbsp;&nbsp;<small>评论了你的文章</small></p>
<!--              <p></p>-->
            </el-col>
          </el-row>
          <el-row>
            <el-col span=24>
              <h6>{{comment.content}}</h6>
            </el-col>
          </el-row>
          <el-row>
            <el-col span=24>
              <small class="text-muted">{{comment.article_title}}</small>
            </el-col>
          </el-row>
        </el-col>
        <el-col span=4>
          <el-row>
            <el-col span=24>
              <small class="text-muted">{{comment.create_time}}</small>
            </el-col>
          </el-row>
          <el-row>
            <el-col span=24 class="layout-el-col-blank">
<!--              <small style="color: red">删除</small>-->
              <p></p>
              <p></p>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <p></p>
              <small style="color: red">删除</small>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <hr/>
    </el-col>
  </el-row>
</template>

<script>
export default {

  data(){
    return{
      loading:true,
      one:[0]

    }
  },

  props:{
    article_comments:{
      type:Object,
      required:true
    },
    article_comments_ids:{
      type:Object,
      required: true
    }
  },
  watch:{
    article_comments(){
        this.loading=false;
    },


  },

  methods:{
    oneChecked(id){
      var r=0;
      for(var i=0;i<this.one.length;i++){
        if(this.one[i]===id){
          r=i;
          break;
        }
      }

      if(r==0){
        this.one.push(id)
      }else {
        this.one.splice(r,1)
      }



      console.log(this.one)

    },

    allCheck() {
      this.one = this.article_comments_ids
      console.log(this.one)
      var arr = document.getElementsByName("one");
      if(document.getElementById("all").checked == true)
        for(var i = 0;i < arr.length;i++) {
          arr[i].checked = true;

        }
      else {
        for(var i = 0;i < arr.length;i++) {
          arr[i].checked = false;
        }
      }

    }
  },
  created() {

  }

}
</script>

<style lang="scss" scoped>

</style>