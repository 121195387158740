var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"row"}),_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-2"}),_c('div',{staticClass:"col-2",staticStyle:{"text-align":"right","color":"#0099ff"}},[_vm._v(" 修改 "),_c('font',{staticStyle:{"color":"black"}},[_vm._v("/")]),_vm._v(" 找回 ")],1)]),_c('hr'),_c('p'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4",staticStyle:{"text-align":"left"}},[_c('p',[_vm._v("手      机            "+_vm._s(_vm.UserDetail.phone.substring(0,3))+"****"+_vm._s(_vm.UserDetail.phone.substring(7,11)))])]),_c('div',{staticClass:"col-6"}),_c('div',{staticClass:"col-2",staticStyle:{"text-align":"right","color":"#0099ff"}},[(_vm.UserDetail==null)?_c('p',[_vm._v("绑定手机")]):_c('p',[_vm._v(" 更换 "),_c('font',{staticStyle:{"color":"black"}},[_vm._v("/")]),_vm._v(" 解绑 ")],1)])]),_c('hr'),_c('p'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-5",staticStyle:{"text-align":"left"}},[_c('p',[_vm._v("邮      箱            "+_vm._s(_vm.UserDetail.email.split('@')[0].substring(0,3))+"***@"+_vm._s(_vm.UserDetail.email.split('@')[1])+" ")])]),_c('div',{staticClass:"col-5"}),_c('div',{staticClass:"col-2",staticStyle:{"text-align":"right","color":"#0099ff"}},[(_vm.UserDetail==null)?_c('p',[_vm._v("绑定邮箱")]):_c('p',[_vm._v(" 更换 "),_c('font',{staticStyle:{"color":"black"}},[_vm._v("/")]),_vm._v(" 解绑")],1)])]),_c('hr'),_c('p'),_vm._m(2),_c('hr'),_c('p'),_vm._m(3),_c('hr'),_c('p'),_vm._m(4),_c('hr'),_vm._m(5),_c('p'),_vm._m(6),_c('p'),_vm._m(7),_c('p')])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row",staticStyle:{"text-align":"left"}},[_c('h6',[_vm._v("账号设置")]),_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-8",staticStyle:{"text-align":"left"}},[_c('p',[_vm._v("密      码            *********** （初始密码为手机号后四位+注册时收到的短信验证码） ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-5",staticStyle:{"text-align":"left"}},[_c('p',[_vm._v("三方账号            QQ/Weichat/Sina")])]),_c('div',{staticClass:"col-5"}),_c('div',{staticClass:"col-2",staticStyle:{"text-align":"right","color":"#8c939d"}},[_vm._v(" 开发中 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-5",staticStyle:{"text-align":"left"}},[_c('p',[_vm._v("登录记录            存在风险，请查看记录")])]),_c('div',{staticClass:"col-5"}),_c('div',{staticClass:"col-2",staticStyle:{"text-align":"right","color":"#0099ff"}},[_vm._v(" 查看记录 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-5",staticStyle:{"text-align":"left"}},[_c('p',[_vm._v("账号注销           ")])]),_c('div',{staticClass:"col-5"}),_c('div',{staticClass:"col-2",staticStyle:{"text-align":"right","color":"#0099ff"}},[_vm._v(" 立即注销 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"}),_c('div',{staticClass:"col-2",staticStyle:{"color":"green"}},[_vm._v(" 90 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"}),_c('div',{staticClass:"col-2",staticStyle:{"color":"green"}},[_vm._v(" 低风险 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"}),_c('div',{staticClass:"col-5",staticStyle:{"color":"red"}},[_vm._v(" 为了更好的保障您的账号安全，请继续完善您的邮箱 ")])])
}]

export { render, staticRenderFns }