<template>
    <div class="">
        <div class="row"> 
          <div class="col-12"> 
            <head-view :UserDetail="UserDetail"></head-view>
          </div>

        </div>
        
        <div class="row" >
            <div class="col-12"> 
              <login-center></login-center>
            </div>           
        </div>
        <div class="row">
            <div class="col-12"> 
              <copy-right-view></copy-right-view>

            </div>
        </div>
    </div>
</template>

<script>
import HeadView from '@/components/HeadView.vue'
import LoginCenter from './Components/LoginCenter.vue'
import CopyRightView from '@/components/CopyRightView.vue'



export default{

  components: { 
    HeadView,
    LoginCenter ,
    CopyRightView
  },

  data(){
    return{
      UserDetail:""
    }
  },

  created(){
    this.UserDetail = {"head_img":this.$utils.localStorageGetItem("user_head_img")}
  }

}

</script>
<style lang="scss" scoped>


</style>