<template>
    <div>
        <div class="row"> 
            <div class="row"> 
                
            </div>
            <div class="row"> 
                <!-- <b-card style="text-align:left"> -->
                        <!-- <b-card-text > -->
                            <div class="row" style="text-align:left"> 
                                <h6>账号设置</h6>
                                <hr/>
                            </div>
                            <div class="row">
                                <div class="col-8" style="text-align:left">
                                    <p>密&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    ***********
                                    （初始密码为手机号后四位+注册时收到的短信验证码）
                                    </p>
                                    
                                </div>
                                <div class="col-2"> 

                                </div>
                                <div class="col-2" style="text-align:right;color: #0099ff;">
                                    修改
                                  <font style="color: black">/</font>
                                  找回
                                </div>
                            </div>
                            <hr/>
                            <p></p>
                            <div class="row">
                                <div class="col-4" style="text-align:left">
                                    <p>手&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;机
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {{UserDetail.phone.substring(0,3)}}****{{UserDetail.phone.substring(7,11)}}</p>
                                </div>
                                <div class="col-6"> 
                                    
                                </div>
                                <div class="col-2"  style="text-align:right;color: #0099ff;">
                                    
                                    <p v-if="UserDetail==null">绑定手机</p>
                                    <p v-else>
                                      更换
                                      <font style="color: black">/</font>
                                      解绑
                                    </p>
                                </div>
                            </div>
                            <hr/>
                            <p></p>
                            <div class="row">
                                <div class="col-5" style="text-align:left">
                                    <p>邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {{UserDetail.email.split('@')[0].substring(0,3)}}***@{{UserDetail.email.split('@')[1]}}
                                    </p>
                                </div>
                                <div class="col-5"> 
                                    
                                </div>
                                <div class="col-2"  style="text-align:right;color: #0099ff;">
                                    <p v-if="UserDetail==null">绑定邮箱</p>
                                    <p v-else>
                                      更换
                                      <font style="color: black">/</font>
                                      解绑</p>
                                </div>
                            </div>
                            <hr/>
                            <p></p>
                                <div class="row">
                                <div class="col-5" style="text-align:left">
                                    <p>三方账号
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    QQ/Weichat/Sina</p>
                                </div>
                                <div class="col-5"> 
                                    
                                </div>
                                <div class="col-2"  style="text-align:right;color: #8c939d;">
                                    开发中
                                </div>
                            </div>
                            <hr/>
                            <p></p>
                                <div class="row">
                                <div class="col-5" style="text-align:left">
                                    <p>登录记录
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    存在风险，请查看记录</p>
                                </div>
                                <div class="col-5"> 
                                    
                                </div>
                                <div class="col-2"  style="text-align:right;color: #0099ff;">
                                    查看记录
                                </div>
                            </div>
                            <hr/>
                            <p></p>
                                <div class="row">
                                <div class="col-5" style="text-align:left">
                                    <p>账号注销
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                    
                                </div>
                                <div class="col-5"> 
                                    
                                </div>
                                <div class="col-2"  style="text-align:right;color: #0099ff;">
                                    立即注销
                                </div>
                            </div>
                            <hr/>
                            <div class="row"> 
                        <div class="col-4"> 

                        </div>
                        <div class="col-2" style="color:green"> 
                            90
                        </div>
                    
                    </div>
                    <p></p>
                    <div class="row"> 
                        <div class="col-4"> 

                        </div>
                        <div class="col-2" style="color:green"> 
                            低风险
                        </div>
                    </div>
                    <p></p>
                    <div class="row"> 
                        <div class="col-3"> 

                        </div>
                        <div class="col-5" style="color:red"> 
                            为了更好的保障您的账号安全，请继续完善您的邮箱
                        </div>
                    
                    </div>
                    <p></p>
                    <!-- </b-card-text> -->
                <!-- </b-card> -->
            </div>
        </div>
        
    </div>
</template>

<script>



export default{

props:{
    UserDetail:{
        type:Object,
        required:true
    }
}

}

</script>


<style lang="scss" scoped>


</style>