<template>
<div class="bg-center" >
    <div class="row">
        <div class="col-2"></div>
        <div class="col-3"></div>
        <div class="col-2"> 
            <b-spinner v-if="isShowLoadSpinner" style="position:relative;top:260px" variant="primary" label=""></b-spinner>
        </div>
        <div class="col-1">
        </div>
        <div class="col-3">
            <b-tabs class="login-tab" content-class="mt-3" justified>
              <b-tab title="密码登录" active>
                <div v-if="login_error_show" class="row" style="text-align:left;color:red;"><div class="col-1"></div><div class="col-10"><small>{{login_error}}</small></div></div>
                <div class="input-group mb-3 ">
                  <input type="text" class="form-control head-search-input" v-model="login_phone"  placeholder="手机号" aria-label="Example text with button addon" aria-describedby="button-addon1">
                </div>
                <div class="input-group mb-3 ">
                  <input type="password" class="form-control head-search-input"  v-model="login_pwd" placeholder="密码" aria-label="Example text with button addon" aria-describedby="button-addon1">
                </div>
                <div class="input-group mb-3 ">
                  <input type="text" class="form-control head-search-input" v-model="login_image_code" placeholder="图形验证码" aria-label="Example text with button addon" aria-describedby="button-addon1">
                  <img v-if="isShowLoginImageCode" @click="getCaptcha2()" ref="captcha2" style="width:40%;" :src="image_code_src"/>
                  <button v-else @click="deleteLoginGetCaptchaButton" class="btn btn-outline-secondary head-search-button"  type="button" id="button-addon1">
                    获取图形验证码
                  </button>
                </div>
                <div class="input-group mb-3" >
                  <b-button @click="login()" variant="danger" class="login-button">
                    登录
                  </b-button>
                </div>
                <div class="input-group mb-3">
                  <div style="width:100%;text-align: center">
                    <small class="login-tip">
                      若忘记密码，则可以凭借手机号
                      <router-link :to="{path:'/findpasswordview'}" style="color: dodgerblue">
                        找回密码
                      </router-link>
                    </small>
                  </div>
                </div>
              </b-tab>
              <b-tab title="免密注册" >
                    <div v-if="register_error_show" class="row" style="text-align:left;color:red;"><div class="col-1"></div><div class="col-11"><small>{{register_error}}</small></div></div>
                    <div class="input-group mb-3 ">
                        <button class="btn btn-outline-secondary head-search-button"  type="button" id="button-addon4">
                            <b-icon icon="telephone-plus" variant="warning"></b-icon>&nbsp;+86
                        </button>
                        <input v-model="register_phone"  type="text" class="form-control head-search-input"  placeholder="手机号" aria-label="Example text with button addon" aria-describedby="button-addon1">                   
                    </div>
                    
                    <div class="input-group mb-3 ">
                        <input v-model="register_sms_code" type="text" class="form-control head-search-input" placeholder="6位数字验证码" aria-label="Example text with button addon" aria-describedby="button-addon1">
                        <button :disabled="isShowPhoneCodeButtonDisabled"  @click="sendSms" class="btn btn-outline-secondary head-search-button"  type="button" id="button-addon2">
                            <a v-if="isShowPhoneCodeButton">获取手机验证码</a>
                            <a v-else> {{phone_code_seconds}}秒后再次发送</a>
                        </button>                        
                    </div>
                    <div class="input-group mb-3 ">
                        <input v-model="register_image_code" type="text" class="form-control head-search-input" placeholder="图形验证码" aria-label="Example text with button addon" aria-describedby="button-addon1">
                        <img v-if="isShowRegisterImageCode" @click="getCaptcha1()" ref="captcha1" style="width:40%;" :src="image_code_register_src"/>
                        <button v-else @click="deleteRegisterGetCaptureButton" class="btn btn-outline-secondary head-search-button"  type="button" id="button-addon3">
                            获取图形验证码
                        </button>                    
                    </div>
                    <div class="input-group mb-3" >
                        <b-button @click="register" variant="danger" class="login-button">注册</b-button>
                    </div>
                    <div class="input-group mb-3" >
                      <div style="width:100%;text-align: center">
                        <small class="login-tip">
                          新用户输入验证码点击注册，即同意
                          <a href="" style="color: dodgerblue">
                            服务条款
                          </a>
                          和
                          <a href="" style="color: dodgerblue">
                            隐私协议
                          </a>
                        </small>
                      </div>
                    </div>
                </b-tab>

                              
            </b-tabs>
        </div>
        <div class="col-1"></div> 
    </div>
</div>
    
</template>

<script>

import {getImageCodeKey,getUserInfo} from '@/api/user-server/getData'
import {login}           from '@/api/user-server/checkData'
import {register}        from '@/api/user-server/saveData'
import {sendSms}         from '@/api/user-server/checkData'

export default{
    data(){
        return{

            login_expire:2*60*60*1000,


            phone:"",                           //手机号
            phone_code:"",                      //短信验证码
            isShowPhoneCodeButton:true,         //是否显示"获取短信验证码"的按钮
            isShowPhoneCodeButtonDisabled:false,//是否"禁止操作""获取短信验证码"的按钮（不禁用，有加速读秒的bug）
            phone_code_seconds:120,             //发送短信

            isShowRegisterImageCode:false,      //是否显示注册面板的图形验证码
            isShowLoginImageCode:false,         //是否显示登录面板的图形验证码

            image_code_login_src_base:this.$utils.getApiHostPort()+this.$url.get_image_code_login_src_base(),//图形验证码基础链接地址

            image_code_register_src_base:this.$utils.getApiHostPort()+this.$url.get_image_code_register_src_base(),//图形验证码基础链接地址

            image_code_src:'',                  //图片源地址
            image_code_register_src:'',
            image_code_key:'',                  //图形验证码key

            register_phone:"",                  //注册手机号
            register_sms_code:"",               //注册短信验证码
            register_image_code:"",             //注册图形验证码
            register_error_show:false,          //注册错误提示是否显示
            register_error:"",                  //注册错误提示内容

            login_phone:"",                     //登录账号
            login_pwd:"",                       //登录密码
            login_image_code:"",                //登录图形验证码
            login_error_show:false,             //登录错误提示是否显示
            login_error:"",                     //登录错误提示内容

            isShowLoadSpinner:false
        }
    },


    methods:{

        /**
         * 发送短信间隔
         */
        sendSms(){
            //判断手机号长度length、手机号是否为数字Number("",10)、手机号是否为空isNaN()
            if(this.register_phone.length==11 && !isNaN(Number(this.register_phone,10))){
                sendSms(this.register_phone).then(
                    res=>{
                        if(res.data.code==0){                         
                            //不显示获取验证码
                            this.isShowPhoneCodeButton=false
                            //禁止操作获取验证码的按钮
                            this.isShowPhoneCodeButtonDisabled=true
                            //显示提示注册的错误信息
                            this.register_error_show=true
                            //将注册错误信息赋值
                            this.register_error=res.data.msg
                            //使用计时器缩减时间间隔
                            this.phone_code_seconds_();

                        }else{
                            this.register_error = res.data.msg
                            this.register_error_show = true
                        }
                    }
                )
                
            }
            //否则，弹出提示信息
            else{
                alert("手机号不正确！！")
            }
        },

      /**
       * 递减发送短信时间间隔
       */
      phone_code_seconds_(){
            //定义计时器
            var interval = setInterval(()=>{
              this.phone_code_seconds--;
              if(this.phone_code_seconds==0){
                  //显示获取验证码
                  this.isShowPhoneCodeButton=true
                  //允许点击发送验证码按钮
                  this.isShowPhoneCodeButtonDisabled=false
                  //将手机验证码时间间隔重置为 120 秒
                  this.phone_code_seconds=120
                  //关闭计时器
                  clearInterval(interval)
              }
              //时间间隔1秒
            },1000)
      },


        /**
         * 手机号注册面板，删除获取图形验证码按钮
         */
        deleteRegisterGetCaptureButton(){
            //图形验证码基础链接地址 拼接key
            this.image_code_register_src =this.image_code_register_src_base + this.image_code_key
            //显示图形验证码
            this.isShowRegisterImageCode=true  
        },


        /**
         * 账号登录面板，删除获取图片验证码的按钮
         */
        deleteLoginGetCaptchaButton(){
            //图形验证码基础链接地址 拼接key
            this.image_code_src = this.image_code_login_src_base + this.image_code_key
            //显示图形验证码
            this.isShowLoginImageCode = true
        },


        /**
         * 手机号注册面板，动态刷新图形验证码
         * @param {点击事件} event 
         */
        getCaptcha1(event){
            //重置图片源地址
            this.$refs.captcha1.src = this.image_code_register_src_base+this.image_code_key+'&time='+Date.now()
        },


        /**
         * 账号密码登录面板，动态刷新图形验证码
         * @param {点击事件} event 
         */
        getCaptcha2(event) {
            //重置图片源地址
            this.$refs.captcha2.src = this.image_code_login_src_base+this.image_code_key+'&time='+Date.now()
        },



        /**
         * 登录
         */
         login(){
            this.login_error_show=false
            this.isShowLoadSpinner=true
            //通过按钮提交请求，需要使用"login().then(res=>{})"的方式处理返回数据
            login(this.login_phone,this.login_pwd,this.image_code_key,this.login_image_code).then(
                res=>{
                    if(res.data.code==0){
                        // localStorage.setItem("token",res.data.data)
                        // yujianbouJsUtils.localStorageSetItem("token",res.data.data,30*60*1000)
                        this.$utils.localStorageSetItem("token",res.data.data,4*30*60*1000)
                        this.$store.dispatch('setToken',res.data.data)
                        // this.getUserDetail()
                        this.$router.push({path:'/personview'})
                    }else{
                        this.login_error="呃……登录失败了，请重新检查一下账号信息吧"
                        this.login_error_show=true
                    }
                    this.isShowLoadSpinner=false
                }
            )
        },

        register(){
            this.register_error_show=false
            this.isShowLoadSpinner=true
            register(this.register_phone,this.register_sms_code,this.image_code_key,this.register_image_code).then(
                res=>{
                    if(res.data.code ===0){
                        // localStorage.setItem("token",res.data.data)
                        // yujianbouJsUtils.localStorageSetItem("token",res.data.data,30*60*1000)
                        this.$utils.localStorageSetItem("token",res.data.data,this.login_expire)
                        this.$store.dispatch('setToken',res.data.data)
                        
                        this.$router.push({path:'/personview'})
                    }else{
                        this.register_error="呃……注册失败了，请重新检查一下短信和图片验证码吧"
                        this.register_error_show=true
                    }
                    this.isShowLoadSpinner=false
                }
            )

        },
        // async getUserDetail(){
        //     const result = await getUserInfo(this.$utils.localStorageGetItem('token'));
        //     if(result.data.code ==0){
        //         this.$utils.localStorageSetItem("user_id",result.data.data.id,30*60*1000)
        //         this.$utils.localStorageSetItem("user_name",result.data.data.name,30*60*1000)
        //         this.$utils.localStorageSetItem("user_phone",result.data.data.phone,30*60*1000)
        //         this.$utils.localStorageSetItem("user_email",result.data.data.email,30*60*1000)
        //         this.$utils.localStorageSetItem("user_head_img",result.data.data.head_img,30*30*1000)
        //         this.$utils.localStorageSetItem("user_score",result.data.data.score,30*60*1000)
        //         this.$utils.localStorageSetItem("user_money",result.data.data.money,30*60*1000)
        //         this.$utils.localStorageSetItem("user_follow",result.data.data.follow,30*60*1000)
        //         this.$utils.localStorageSetItem("user_fans",result.data.data.fans,30*60*1000)
        //         this.$utils.localStorageSetItem("user_visit",result.data.data.visit,30*60*1000)
        //         this.$utils.localStorageSetItem("user_article",result.data.data.article,30*60*1000)
        //         this.$utils.localStorageSetItem("user_summary",result.data.data.summary,30*60*1000)
        //         this.$utils.localStorageSetItem("user_create_time",result.data.data.create_time,30*60*1000)
        //         this.$utils.localStorageSetItem("user_update_time",result.data.data.update_time,30*60*1000)
        //         // this.$utils.sendToastByYJB("加载用户信息成功",1000)
        //     }else{
        //         this.$utils.sendToastByYJB("获取用户信息失败",1000)
        //     }
        // },


        /**
         * 从服务器获取图形验证码的key
         */
        async getImageCodeKeyFromServer(){
            const result  = await getImageCodeKey();
            if(result.data.code==0){
                this.image_code_key = result.data.data
            }
        }
    },

  components: {  },

  created(){
    this.getImageCodeKeyFromServer()
  }




}

</script>


<style lang="scss" scoped>

.bg-center{
    position: relative;
    // top:5px;
    width: 100%;
    height: 810px;
    background-repeat: no-repeat;
    background-size: 100% 810px;
    background-image: url('@/assets/login/login_bg_02.png');
}
.col-3{
    position: relative;
    top: 180px;
}

.login-tab{
    width: 420px;
    height: 370px;
    background-color: white;
}

.login-tab .input-group{
    width: 100%;
    position: relative;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 10px;
}

.login-button{
    width: 100%;
}
.login-tip{
    font-size: 10px;

}

a{
    text-decoration: none;
    color: rgb(7, 6, 6);
    background: none;
    
}


</style>