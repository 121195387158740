<template>
<div> 
    <p></p>    
    <el-row >     
        <el-col  span="24" style="text-align:left;"> 
            <!-- 文章 -->
            <el-card class="box-card"> 
                <el-row> 
                    <el-col span="24">
                        <!-- 文章的标题 -->
                        <el-row>
                            <el-col style="text-align:left">                           
                                <h4>{{article.big_title}}</h4>
                            </el-col>                         
                        </el-row>
                        <!-- 文章关键信息 -->
                        <el-row style="background-color: #F0F2F5;">
                            <el-col span="2" style="text-align:center;"> 
                                原创
                            </el-col>
                            <el-col span="22" >
                                <el-row style="text-align:left;">
                                    <el-col span="24"> 
                                        <small>                                           
                                            <i class="el-icon-user-solid"></i>
                                            &nbsp;{{article.user_vo.name}}&nbsp;&nbsp;&nbsp;&nbsp;
                                            于
                                            &nbsp;{{article.create_time}}&nbsp;
                                            发布
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <i class="el-icon-view"></i>{{article.viewed}}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <i class="el-icon-star-off"></i>{{collection}}
                                        </small>    
                                    </el-col>
                                </el-row>
                                <el-row> 
                                    <el-col span="24"> 
                                        <small> 
                                            <i class="el-icon-notebook-2"></i>
                                            &nbsp;
                                            文章标签：{{article.small_title}}
                                        </small>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                        <!-- 文章内容 -->
                        <p></p>
                        <el-row> 
                            <el-col span="24" style="text-align:left"> 
                                <div class="content"> 
                                   <p v-html="article.content" ></p> 
                                </div>
                            </el-col>
                        </el-row>
                        <p></p>
                        <!-- 文章的浏览信息 -->
                        <el-row>
                            <el-col span="24"> 
                                <el-card style="text-align: left;"> 
                                    <el-row> 
                                        <el-col span="12"> 
                                            <a style="font-size:18px;"><b>{{article.user_vo.name}}</b></a>
                                            &nbsp;
                                            <el-button @click="cancelFollowUser" v-if="isFollow" type="info" size="small" round>已关注</el-button>
                                            <el-button @click="saveFollowUser" v-else type="danger" size="small" round>关注</el-button>
                                        </el-col>
                                        <el-col span="2">
                                            <el-badge :value="praise" class="item">
                                                <b-icon @click="cancelPraiseArticle" v-if="isPraise" icon="hand-thumbs-up-fill" style="width:auto;height:20px;color:red;"></b-icon>
                                                <b-icon @click="savePraiseArticle" v-else icon="hand-thumbs-up" style="width:auto;height:23px"></b-icon>
                                            </el-badge>
                                        </el-col>
                                        <el-col span="2"> 
                                            <el-badge :value="criticise" class="item">
                                                <b-icon @click="cancelCriticiseArticle" v-if="isCriticise"  icon="hand-thumbs-down-fill" style="width:auto;height:20px;color:red;"></b-icon>
                                                <b-icon @click="saveCriticiseArticle" v-else  icon="hand-thumbs-down" style="width:auto;height:23px"></b-icon>
                                            </el-badge>
                                        </el-col>
<!--                                        <el-col span="2"> -->
<!--                                            <el-badge :value="article_comment.length" class="item">-->
<!--                                                <b-icon @click="drawer = true" icon="chat-dots" style="width:auto;height:20px"> </b-icon>-->
<!--                                            </el-badge>-->
<!--                                        </el-col>-->
                                        <el-col span="2"> 
                                            <el-badge :value="collection" class="item">
                                                <b-icon @click="cancelCollectionArticle"  v-if="isCollection" icon="star-fill" style="width:auto;height:20px;color:red;"></b-icon>
                                                <b-icon @click="saveCollectionArticle" v-else  icon="star"   style="width:auto;height:23px"></b-icon>
                                            </el-badge>
                                        </el-col>
                                        <el-col span="2"> 
                                            <el-badge  class="item">
                                                <b-icon @click="copyShareLink()" v-if="isShare" icon="share-fill"  style="width:auto;height:20px;color:red;"></b-icon>
                                                <b-icon @click="copyShareLink()" v-else icon="share"  style="width:auto;height:23px"></b-icon>
                                            </el-badge>
                                        </el-col>
                                        <el-col span="2"> 
                                            <el-button size="small" round>分类专栏</el-button> 
                                        </el-col>
                                    </el-row>                                                                
                                </el-card>
                            </el-col>                           
                        </el-row>
                    </el-col>
                </el-row>
            </el-card>
            <p></p>
            <!-- 评论 -->
            <el-card class="box-card"> 
                <el-row> 
                    <el-col span="4"> 
                        评论 <small>{{article_comment.length}}条 ></small>
                    </el-col>
                    <el-col span="18" class="layout-el-col-blank">

                    </el-col>
                    <el-col span="2"> 
                        <!-- <b-button @click="drawer = true"  variant="danger" pill size="sm">写评论</b-button> -->
                        <b-button v-if="isShow==false" @click="isShow=true"  variant="danger" pill size="sm">写评论</b-button>
                        <b-button v-else               @click="isShow=false"  variant="danger" pill size="sm">写评论</b-button>

                    </el-col>
                </el-row>
                <p></p>
                <el-row style="text-align:left" v-if="article_comment!=null&&article_comment!=''"> 
                    <el-col span=16>
                        <img :src="article_comment[0].user_vo.head_img" class="img-circle-25"/>                           
                        &nbsp;&nbsp;&nbsp;
                        <small>{{article_comment[0].user_vo.name}}</small>                        
                        <small  style="color:red;background-color: yellow;">&nbsp;热评</small>：
                        &nbsp;
                        <small style="color:black">{{article_comment[0].content}}</small>
                    </el-col>
                    <el-col span="8" style="text-align:right">
                        <small style="color:darkgray">{{article_comment[0].create_time}}</small>
                    </el-col>
                </el-row>
            </el-card>
            
            <!-- 评论弹窗 -->
            
            <div class="component_drawer" :class="{component_drawer_show:isShow}">
                
                <el-row>                     
                    <div  @click="isShow=false"> 
                        <el-col span="1" style="background-color: #F2F6FC;height: 1200px;line-height: 1200px;text-align: center;">
                            <i class="el-icon-caret-right"></i> 
                        </el-col> 
                        <el-col span="1" class="layout-el-col-blank"></el-col>
                    </div>                   
                    <el-col span="22"> 
                        <br/><br/>
                        <el-row> 
                            <el-col span="24"> 
                                <h5>评论区</h5>
                                <hr style="color:darkgray"/>
                            </el-col>
                        </el-row>
                        <el-row> 
                            <el-col span="24"> 
                                <el-form>
                                    <el-form-item  label="">
                                        <el-row>
                                            <el-col span="2">                  
                                                <el-image class="img-circle-30" :src="this.$utils.localStorageGetItem('user_head_img')" ></el-image>                                               
                                            </el-col>
                                            <el-col span="19" style="text-align:left"> 
                                                <div style="line-height:40px;text-align:left;height: 40px;"> 
                                                    <div style="vertical-align: middle;display:inline-block;"> 
                                                        <h6 v-if="this.$utils.localStorageGetItem('user_name')==null"><router-link to="/loginview" style="text-decoration:none">登录/注册</router-link></h6>
                                                        <h6 v-else>{{this.$utils.localStorageGetItem('user_name')}}</h6>
                                                    </div>                            
                                                </div>
                                            </el-col>
                                        </el-row>                
                                        <el-input @keyup.enter.native="saveComment" v-model="comment_content"  type="textarea" placeholder="欢迎高质量的评论，低质量的评论会被折叠"></el-input>                         
                                        <div class="row" style="text-align:right"> 
                                            <div class="col-12"> 
                                                <el-button @click="saveComment" type="success"  size="small" round>发送</el-button>
                                            </div>
                                        </div>
                                    </el-form-item>                               
                                </el-form>   
                            </el-col>
                        </el-row>
                        <el-row> 
                            <el-col span="24" v-if="article_comment!=null&&article_comment!=''">
                                <div style="position:relative; overflow: auto; width: auto; height: calc(60vh);" > 
                                    <el-row style="text-align:left" v-for="comment in article_comment" :key="comment">                                   
                                        <el-col span=24>
                                            <el-row> 
                                                <el-col span="2"> 
                                                    <img  :src="comment.user_vo.head_img" class="img-circle-25"/>
                                                </el-col>
                                                <el-col span="12">
                                                    <small style="color:darkgrey;font-size: 14px;">{{comment.user_vo.name}}</small>
                                                    &nbsp;
                                                    <small style="color:darkgrey;font-size: 13px;">回复</small> 
                                                </el-col>
                                                <el-col span="10" style="text-align:right"> 
                                                    <small style="color:darkgray;font-size: 13px;">{{comment.create_time}}</small>
                                                </el-col>
                                            </el-row>                                        
                                            <el-row> 
                                                <el-col span="2" class="layout-el-col-blank"> 

                                                </el-col>
                                                <el-col span="18"> 
                                                    <small style="color:black;font-size: 14px;">{{comment.content}}</small>
                                                </el-col>
                                                <el-col span="4" style="text-align:right"> 
                                                    <small style="color:darkgrey;font-size: 13px;">回复</small> 
                                                </el-col>
                                            </el-row>
                                            <hr style="color:darkgray"/>
                                        </el-col>
                                    </el-row>   
                                </div>                              
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </div>
            <p></p>           
            <!-- 相关文章 -->
            <!-- <el-card class="box-card">
            <ul class="infinite-list" v-infinite-scroll="load" infinite-scroll-distance="1" style="overflow-y:scroll;height:200px"> 
                <li class="infinite-list-item" v-for="(article,index) in article_relate" :key="index"> 
                    {{article.big_title}}
                    <h6 v-html="article.big_title"></h6>

                </li>

            </ul>
            </el-card> -->
            <el-card class="box-card" >
                <div slot="header" class="clearfix">
                    <span>相关文章</span>   
                </div> 

                <div class="infinite-list" v-infinite-scroll="load"  infinite-scroll-distance="10"  style="overflow-y:scroll;height:600px"> 
                    <el-row v-for="(article,index) in article_relate" :key="index">                        
                        <router-link  :to="{query:{id:article.id,user_id:article.user_id}}">             
                            <el-col  span=24 style="text-align:left" >                                              
                                <el-row > 
                                    <el-col span=19>
                                        <h6 v-html="article.big_title" style="font-size:16px;color: black;" @click="refreshPage(article.id,article.user_id)">
                                        </h6>
                                    </el-col>
                                    <el-col span=3> 
                                        <small class="text-muted"><i class="el-icon-user"></i>&nbsp;&nbsp;{{article.user_vo.name}}</small>
                                    </el-col>
                                    <el-col span=2> 
                                        <small class="text-muted"><i class="el-icon-view"></i>&nbsp;&nbsp;{{article.viewed}}</small>
                                    </el-col>
                                </el-row>
                                <el-row> 
                                    <el-col span=24> 
                                        <small v-html="article.summary" class="text-muted" style="font-size:14px;" @click="refreshPage(article.id,article.user_id)" ></small>
                                        <hr style="color:darkgray"/>
                                    </el-col>
                                </el-row>                                                                            
                            </el-col>
                        </router-link>                    
                    </el-row>
                </div>                             
            </el-card>
        </el-col>
    </el-row>
</div>

</template>

<script >
import { saveComment } from '@/api/article-server/saveData';
import { saveFollow,cancelFollow } from '@/api/follofans-server/saveData';
import { saveCollection,cancelCollection,savePraise,cancelPraise,saveCriticise,cancelCriticise } from '@/api/article-server/saveData';
import { getArticleRelate} from '@/api/article-server/getData';
export default{
    data(){
        return{
            drawer:false,
            isShow:false,
            isShare:false,
            href:'',
            comment_content:'',
            comment_from_id:'',
            comment_to_id:'',
            page:1,
            size:10,
            total_page:0,
            article_relate:'',
        }
    },

    props:{

        article:{
            type:Object,
            required:true
        },

        article_comment:{
            type:Object,
            required:true
        },

        isFollow:{
            type:Boolean,
            required:true
        },
        isCollection:{
            type:Boolean,
            required:true
        },
        isPraise:{
            type:Boolean,
            required:true
        },
        isCriticise:{
            type:Boolean,
            required:true
        },

        collection:{
            type:Object,
            required:true
        },

        praise:{
            type:Object,
            required:true
        },
        criticise:{
            type:Object,
            required:true
        },
    },

    watch:{
        $route(){
            this.getRelateArticle(this.$route.query.id)
            this.$forceUpdate(); 
        }
    },

    methods:{

        load(){
            console.log("xxx当前页，总页数："+this.page+","+this.total_page)
            if(this.page<this.total_page){
                this.page+=1
                this.getArticleRelateLoad(this.page,this.size)
            }
        },

        async getArticleRelateLoad(page,size){
            const result = await getArticleRelate(this.$route.query.id,page,size)
            if(result.data.code==0){
                this.article_relate = this.article_relate.concat(result.data.data.current_data)
                this.total_page= result.data.data.total_page
            }
        },
 
        async getRelateArticle(article_id){
            this.page=1
            const result = await getArticleRelate(article_id,this.page,this.size)
            if(result.data.code==0){
                this.article_relate=result.data.data.current_data
                this.total_page = result.data.data.total_page
            }
        },


        /**
         * 复制链接分享
         */
        copyShareLink() {
            var input = document.createElement("input"); // 创建input对象
            input.value = window.location.href; // 设置复制内容
            document.body.appendChild(input); // 添加临时实例
            input.select(); // 选择实例内容
            document.execCommand("Copy"); // 执行复制
            document.body.removeChild(input); // 删除临时实例
            this.$message.success('复制文章链接成功！');
            if(this.isShare){
                this.isShare=false
            }else{
                this.isShare=true
            }
        },
        /**
         * 刷新页面
         * @param {文章id} id 
         * @param {用户id} user_id 
         */
        refreshPage(id,user_id){
            //提交到父组件刷新页面
            this.$emit('toRefreshAll',id,user_id)
        },
        /**
         * 保存评论
         */
        async saveComment(){
            if(this.$utils.localStorageGetItem("user_id")==null||this.$utils.localStorageGetItem("user_id")==""){
              this.$router.push("/loginview")
            }else {
              const result = await saveComment(this.article.id,this.comment_content,this.article.user_id,this.$utils.localStorageGetItem("user_id"),0)
              if(result.data.code==0){
                this.$emit('refreshComment',this.article.id)
                this.comment_content=''
              }
            }
        },

        /**
         * 关注用户
         */
        async saveFollowUser(){
            if(this.$utils.localStorageGetItem("token")==null){
                this.$router.push("loginview")
            }else{
                const result = await saveFollow(this.article.user_vo.id)
                if(result.data.code==0){
                    this.isFollow=true
                    this.$emit('toRefreshFollow')
                }
            }
        },
        /**
         * 取消关注
         */
        async cancelFollowUser(){
            if(this.$utils.localStorageGetItem("token")==null){
                this.$router.push("loginview")
            }else{
                const result = await cancelFollow(this.article.user_vo.id)
                if(result.data.code==0){
                    this.isFollow=false
                    this.$emit('toRefreshFollow')
                }
            }
        },

        /**
         * 收藏文章
         */
        async saveCollectionArticle(){
            if(this.$utils.localStorageGetItem("token")==null){
                this.$router.push("loginview")
            }else{
                const result = await saveCollection(this.article.id)
                if(result.data.code==0){
                    this.$emit('toRefreshCollection')
                }
            }
        },
    
        /**
         * 取消收藏文章
         */
        async cancelCollectionArticle(){
            if(this.$utils.localStorageGetItem("token")==null){
                this.$router.push("loginview")
            }else{
                const result = await cancelCollection(this.article.id)
                if(result.data.code==0){
                    this.$emit('toRefreshCollection')
                }
            }
        },

        /**
         * 点赞文章
         */
        async savePraiseArticle(){
            if(this.$utils.localStorageGetItem("token")==null){
                this.$router.push("loginview")
            }else{
                const result = await savePraise(this.article.id)
                if(result.data.code==0){
                    this.$emit('toRefreshPraise')
                }
            }
        },

        /**
         * 取消点赞
         */
        async cancelPraiseArticle(){
            if(this.$utils.localStorageGetItem("token")==null){
                this.$router.push("loginview")
            }else{
                const result = await cancelPraise(this.article.id)
                if(result.data.code==0){
                    this.$emit('toRefreshPraise')
                }
            }
        },

        /**
         * 踩文章
         */
        async saveCriticiseArticle(){
            if(this.$utils.localStorageGetItem("token")==null){
                this.$router.push("loginview")
            }else{
                const result = await saveCriticise(this.article.id)
                if(result.data.code==0){
                    this.$emit('toRefreshCriticise')
                }
            }
        },

        /**
         * 取消踩
         */
        async cancelCriticiseArticle(){
            if(this.$utils.localStorageGetItem("token")==null){
                this.$router.push("loginview")
            }else{
                const result = await cancelCriticise(this.article.id)
                if(result.data.code==0){
                    this.$emit('toRefreshCriticise')
                }
            }
        },

    }, 

    created(){
        this.getRelateArticle(this.$route.query.id)
    }


}

</script>


<style lang="scss" scoped>
::v-deep(pre){
    display:block;
    overflow-x:auto;
    padding:1em;
    // background-color: #2a2a45;
    background-color: #282C34;
    // font-weight: bold;
}
::v-deep(code){
    color: rgb(255, 255, 255);
}

a{
    text-decoration: none;
    color: black;
}

//定义相关文章区域关键词高亮
::v-deep(em){
  font-style: normal;
  color: #b84d4d;
}
// ::-webkit-scrollbar {display:none}
</style>
