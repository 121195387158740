<template>
    <div class="bg">
        <head-view :UserDetail="UserDetail"></head-view>
        <br/>
<!--        <br/>-->
        <div class="row">
            <div class="col-1">

            </div>
            <div class="col-10"> 
                <div class="row" style="text-align:left;background-color: white">
                <div class="col-2">
                   <div class="menu-title">首页 </div>
                   <el-collapse accordion>                       
                        <el-collapse-item>
                            <template slot="title" >
                                <div class="menu-title"> 
                                   文章管理 
                                </div>                               
                                <!-- <i class="header-icon el-icon-info"></i> -->
                            </template>
                            <div class="menu" >
                                <p class="menu-list" style="margin:0px;padding:0px;"><el-button type="text" @click="changeView(1)">发布文章</el-button></p> 
                                <p class="menu-list" style="margin:0px;padding:0px;"><el-button type="text" @click="changeView(2)">内容管理</el-button></p>
                                <p class="menu-list" style="margin:0px;padding:0px;"><el-button type="text" @click="changeView(3)">评论管理</el-button></p>
                                <p class="menu-list" style="margin:0px;padding:0px;"><el-button type="text" @click="changeView(4)">专栏管理</el-button></p>
                                <p class="menu-list" style="margin:0px;padding:0px;"><el-button type="text" @click="changeView(5)">浏览管理</el-button></p>                                                                                       
                            </div>
                        </el-collapse-item>
                        <el-collapse-item>
                            <template slot="title" class="menu-title">
                                <div class="menu-title"> 
                                   数据结算
                                </div>
                                <!-- <i class="header-icon el-icon-info"></i> -->
                            </template>
                            <div class="menu"> 
                                <p></p> 
                                <p>作品数据</p>
                                <p>收益数据</p>
                                <p>粉丝数据</p>
                                <p>一周小结</p>
                            </div>                            
                        </el-collapse-item>
                        <el-collapse-item>
                            <template slot="title" class="menu-title">
                                <div class="menu-title"> 
                                   创作活动 
                                </div>
                                <!-- <i class="header-icon el-icon-info"></i> -->
                            </template>
                            <div class="menu"> 
                                <p></p> 
                                <p>活动列表</p>
                                <p>投稿管理</p>
                            </div>                           
                        </el-collapse-item>
                        <el-collapse-item>
                            <template slot="title" class="menu-title">
                                <div class="menu-title"> 
                                   创作权益 
                                </div>
                                <!-- <i class="header-icon el-icon-info"></i> -->
                            </template>
                            <div class="menu">
                                <p></p> 
                                <p>等级权益</p>
                                <p>自定义模块</p>
                                <p>自定义域名</p>
                                <p>原创保护</p>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
                <div class="col-10">
                    <article-create-view v-if="ViewIndex==1"></article-create-view>
                    <article-content-view v-if="ViewIndex==2"></article-content-view>
                    <article-comment-view v-if="ViewIndex==3"></article-comment-view>
                    <article-classify-view v-if="ViewIndex==4"></article-classify-view>
                    <article-browse-view v-if="ViewIndex==5"></article-browse-view>
                </div>
            </div>
            </div>
            <div class="col-1"> 

            </div>            
        </div>
    </div>
</template>

<script>
import HeadView from '@/components/HeadView.vue'
import ArticleCommentView from './ArticleComment/ArticleCommentView.vue'
import ArticleEditView from './ArticleEdit/ArticleEditView.vue'
import ArticleCreateView from './ArticleCreate/ArticleCreateView.vue'
import ArticleContentView from './ArticleContent/ArticleContentView.vue'
import ArticleClassifyView from './ArticleClassify/ArticleClassifyView.vue'
import ArticleBrowseView from './ArticleBrowse/ArticleBrowseView.vue'
import EditorView from './ArticleCreate/Components/EditorView.vue'


export default{

  components: { 
    HeadView, 
    ArticleCommentView, 
    ArticleEditView,
    ArticleCreateView, 
    ArticleContentView, 
    ArticleClassifyView,
    ArticleBrowseView,
    EditorView 
  },

  data(){
    return{
        UserDetail:'',
        isShowArticleContent:false,
        ViewIndex:2,
  }},

  methods:{

    /**
     * 切换视图
     * @param {视图索引} index 
     */
    changeView(index){
        if(index==1){
            //跳转-创建文章
            this.$router.push('/articlecreateview') 
        }
        else if(index==2){
            //内容管理
            this.ViewIndex=2
        }
        else if(index==3){
            //评论管理
            this.ViewIndex=3
        }
        else if(index==4){
            //专栏管理
            this.ViewIndex=4
        }
        else if(index==5){
            //浏览管理
            this.ViewIndex=5
        }
    },
  },
  created(){
    this.UserDetail = {"head_img":this.$utils.localStorageGetItem("user_head_img")}
  }

}

</script>


<style lang="scss" scoped>
.bg{
  background-color: #f5f5f5;
}
.menu-title{
    font-size: 17px;
    font-weight: normal;
}
.menu{
    font-size: 15px;
}
.menu-list:hover{
    background-color: #edecec;
    color:rgb(11, 17, 17)
}

</style>