import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import utils from './utils/yujianbo-js-utils-1.0.0'
import url from './api/abase-server/request'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import ElementUI from 'element-ui';
import hljs from 'vue-highlightjs'

import 'highlight.js/styles/atom-one-dark.css'
import 'element-ui/lib/theme-chalk/index.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './utils/yujianbo-css-utils-1.0.0.css'



//生产状态下的提示
Vue.config.productionTip = false
//引用工具
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(ElementUI)
Vue.use(hljs)
Vue.prototype.$utils = utils
Vue.prototype.$url = url
ElementUI.Dialog.props.lockScroll.default = false
/**
 *路由访问前验证登录权限
 *注意，当验证无效时，清理浏览器缓存，或者新开浏览器窗口
 */
router.beforeEach((to,from,next)=>{
  //校验本地token是否过期
  const token = utils.localStorageGetItem('token')
  // const token = this.$utils.localStorageGetItem('token')//在main.js中不可以使用这种方式this.$utils.method
  // const token = localStorage.getItem('token')
  if(to.matched.some(record=>record.meta.requiresAuth)){
    if(token){
      next();
    }else{
      next({path:"/loginview"})
    }
  }else{
    next();
  }
})

Vue.directive('highlight',function (el) {
  let blocks = el.querySelectorAll('pre code');
  blocks.forEach((block)=>{
      hljs.highlightBlock(block)
  })
})



//将router，store，render，引入
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
