<template>
    <div >

        <el-row> 
            <el-col span=24> 
                <head-view :UserDetail="{head_img:this.$utils.localStorageGetItem('user_head_img')}"></head-view>
            </el-col>
        </el-row>
        <el-row class="bg" style="background-image:url(https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F0370fea5a5da8d1a80120121fd2dbc5.jpg&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1664690349&t=5c686b619697d0274b220864e7efaf19)"> 
            <!-- <el-row class="bg" style="background-image:url(@/assets/bgshow.gif)">  -->

            <br/>
            <br/>
            <el-col span=4 class="layout-el-col-blank"></el-col>
            <el-col span=16 >
                <person-show-head-view :isFollow="isFollow" :toRefreshFollow="refreshFollow" :UserDetail="UserDetail"></person-show-head-view>
                <p></p>
                <el-row gutter="5"> 
                    <el-col span=7 class="layout-el-col-blank">                       
                        <person-show-left-view :UserDetail="UserDetail" :article_number_year="article_number_year" :thisYear="thisYear" :lastYear="lastYear" :beforeLastYear="beforeLastYear"></person-show-left-view>
                    </el-col>
                    <el-col span=17 > 
                        <person-show-center-view :UserDetail="UserDetail"></person-show-center-view>
                    </el-col>
                </el-row>
            </el-col>
            <el-col span=4> 

            </el-col>
        </el-row>  
        <el-row> 
            <el-col> 
                <copy-right-view></copy-right-view>
            </el-col>
        </el-row> 
    </div>
</template>

<script>
import HeadView from '@/components/HeadView.vue'
import PersonShowHeadView from './Components/PersonShowHeadView.vue'
import PersonShowLeftView from './Components/PersonShowLeftView.vue'
import PersonShowCenterView from './Components/PersonShowCenterView.vue'
import CopyRightView from '@/components/CopyRightView.vue'
import { getUserSummary } from '@/api/user-server/getData'
import {getNumberForPerYear} from '@/api/article-server/getData'
import { verifyFollow } from '@/api/follofans-server/checkData'

export default{

    components: { 
        HeadView,
        PersonShowHeadView,
        PersonShowLeftView,
        PersonShowCenterView,
        CopyRightView 
    },

    data(){
        return{
            UserDetail:{head_img:this.$utils.localStorageGetItem('user_head_img')},
            isFollow:false,
            article_number_year:'',
            thisYear:0,
            lastYear:0,
            beforeLastYear:0
        }
    },
    methods:{
        async getUserSummary(){
            const result = await getUserSummary(this.$route.query.user_id)
            if(result.data.code==0){
                this.UserDetail = result.data.data
                this.verifyFollowed(result.data.data.id)
            }
        },

        refreshFollow(){
            if(this.isFollow){
                this.isFollow=false
            }else{
                this.isFollow=true
            }
        },

        async verifyFollowed(follow_id){
            if(this.$utils.localStorageGetItem("token")!=null){
                const result = await verifyFollow(follow_id)
                if(result.data.code==0){
                    this.isFollow=true;
                }else{
                    this.isFollow=false;
                }
            }
            
        },

        async getNumberForPerYear(){
            const result = await getNumberForPerYear(this.$route.query.user_id);
            if(result.data.code==0){
                this.article_number_year = result.data.data
                const keys = Object.keys(this.article_number_year)
                var data = this.article_number_year
                this.thisYear = data[keys[2]]
                this.lastYear = data[keys[1]]
                this.beforeLastYear = data[keys[0]] 
            }
        }
        
    },
    created(){
        this.getUserSummary();
        this.getNumberForPerYear();
    }
}

</script>


<style lang="scss" scoped>
    .bg{
        background-color: #eaeaea;
    }

</style>