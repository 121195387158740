import axios from "./base";
import utils from '@/utils/yujianbo-js-utils-1.0.0'


/**
 * 校验是否收藏
 * @param {文章id} article_id 
 * @returns 
 */
export const verifyCollection=(article_id)=>axios.get("/collection/pri/v1/verify_collection",{
    params:{
        article_id,
        token: utils.localStorageGetItem("token")
    }
})

/**
 * 校验是否点赞
 * @param {文章id} article_id 
 * @returns 
 */
export const verifyPraise=(article_id)=>axios.get("/praise/pri/v1/verify_praise",{
    params:{
        article_id,
        token: utils.localStorageGetItem("token")
    }
})

/**
 * 校验是否踩过
 * @param {文章id} article_id 
 * @returns 
 */
export const verifyCriticise=(article_id)=>axios.get("/criticise/pri/v1/verify_criticise",{
    params:{
        article_id,
        token: utils.localStorageGetItem("token")
    }
})

