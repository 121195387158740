<template>
    <div>
        <div class="row"> 
        </div>
            <div class="infinite-list" 
                v-infinite-scroll="load"  
                infinite-scroll-distance="3"  
                style="overflow-y:scroll;overflow-x:hidden;height:600px"
                > 
                <div class="row" v-for="article in article_list" :key="article"> 
                    <div class="row"> 
                        <div class="col-8" style="word-break:break-all"> 
                            <h6 v-html="article.big_title"></h6>
                            <p></p>
                        </div>
                        <div class="col-2"> 

                        </div>
                        <div class="col-2" style="text-align:right"> 
                            <small style="color:darkgrey">{{article.published_date_time}}</small>
                        </div>
                    </div>
                    <div class="row"> 
                        <div class="col-2">                 
                            <small v-if="article.publiced==0&&article.published==0&&article.deleted==0" style="font-size:12px;color:darkgray"><p>草稿</p></small>
                            <small v-if="article.publiced==1&&article.published==1&&article.status==0&&article.deleted==0" style="font-size:12px;color:darkgray"><p>公开</p></small>
                            <small v-if="article.publiced==0&&article.published==1&&article.deleted==0" style="font-size:12px;color:darkgray"><p>私有</p></small>                   
                            <small v-if="article.status==2" style="font-size:12px;color:darkgray"><p>审核中</p></small>
                            <small v-if="article.deleted==1" style="font-size:12px;color:darkgray"><p>回收站</p></small>
                        </div>
                    </div>
                    <div class="row"> 
                        <div class="col-3"> 
                            <small style="color:darkgrey"><p>展现量 0 &nbsp;阅读 0 评论 {{article.viewed}} 收藏 0</p></small>
                        </div>
                        <div class="col-6"> 

                        </div>
                        <div class="col-3" style="font-size:14px;text-align: right;"> 
                            <!-- <el-button @click="toEdit(article.id)" type="primary" icon="el-icon-edit" size="small" circle></el-button> -->
                            <!-- <el-button @click="toShow(article.id)" type="success" icon="el-icon-document" size="small"  circle></el-button> -->
                            <!-- <el-button @click="toDelete(article.id)" type="danger" icon="el-icon-delete" size="small" circle></el-button> -->
                            <el-button @click="toRecovery(article.id)" type="danger" icon="el-icon-refresh-left" size="small" circle></el-button>
                        </div>
                    </div>
                    <hr/>
                </div>
            </div>
    
    </div>
</template>

<script>
 
import { getArticlesRecycleByUser } from '@/api/article-server/getData';
import { recoveryArticleByArticleId } from '@/api/article-server/saveData'


export default{

    data(){
        return{
            article_list:'',
            page:1,
            size:10,
            total_page:0
        }
    },

    methods:{

        load(){
            if(this.page<this.total_page){
                this.page+=1
                this.getArticlesRecycleByUserLoad();
            }
        },

        async getArticlesRecycleByUserLoad(){
            const result  = await getArticlesRecycleByUser(this.page,this.size);
            if(result.data.code ==0){
                this.article_list = this.article_list.concat(result.data.data.current_data)
            }
        },
            
        /**
         * 获取用户的回收站文章
         */
        async getArticlesRecycleByUser(){
            this.page=1
            const result  = await getArticlesRecycleByUser(this.page,this.size);
            if(result.data.code ==0){
                this.article_list = result.data.data.current_data
                this.total_page = result.data.data.total_page
            }
        },

        async recoveryArticleByArticleId(article_id){
            const result = await recoveryArticleByArticleId(article_id)
            if(result.data.code==0){
                alert("恢复成功")
                this.getArticlesRecycleByUser()
            }
        },
    /**
     * 前往文章的内容页面
     * @param {文章的id} article_id 
     */
     toShow(article_id){
        this.$router.push(
            {
                path:'/articleshowview',
                query:{
                user_id:this.$utils.localStorageGetItem('user_id'),
                id:article_id
                }
            }
        )
    },
    /**
     * 前往文章的编辑页面
     * @param {文章的id} article_id 
     */
    toEdit(article_id){
        this.$router.push(
            {
            path:'/articleeditview',
            query:{
                id:article_id
            }
            }
        )
    },
    toRecovery(article_id){
      this.recoveryArticleByArticleId(article_id);
      
    }

    },

    created(){
        this.getArticlesRecycleByUser()
    }


}

</script>


<style lang="scss" scoped>


</style>