<template>
<el-row> 
                            <el-col span=24 style="background-color:white"> 
                                <el-row> 
                                    <el-col span=2 class="layout-el-col-blank">
                                        <el-image :src="UserDetail.head_img" class="img-circle-80"/>
                                    </el-col> 
                                    <el-col span=20 style="text-align:left"> 
                                        <p></p>
                                        <h5 >
                                            {{UserDetail.name}}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <el-button v-if="isFollow" @click="cancelFollowUser" type="info"  round size="small">已关注</el-button>
                                            <el-button v-else @click="saveFollowUser" type="danger"  round size="small">关注</el-button>
                                        </h5>
                                        <small> 
                                            <p>
                                                访问量
                                                <a class="person-num">{{UserDetail.visit}}</a>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                原创
                                                <a class="person-num">{{UserDetail.article}}</a>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                排名
                                                <a class="person-num">{{UserDetail.total_rank}}</a>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                粉丝
                                                <a class="person-num">{{UserDetail.fans}}</a>
                                            </p>
                                            <p>IP属地：中国&nbsp;&nbsp;&nbsp;&nbsp;加入小情书时间：{{UserDetail.create_time}}</p>
                                            <p>个人简介：{{UserDetail.summary}}</p>
                                            
                                        </small>
                                        
                                    </el-col>
                                    <el-col span=2>
                                        <p></p>
                                        
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row> 
</template>

<script>

import { saveFollow,cancelFollow } from '@/api/follofans-server/saveData'

export default{
    data(){
        return{
        }
    },
    props:{
        UserDetail:{
            type:Object,
            required:true
        },
        isFollow:{
            type:Boolean,
            required:true
        }
    },
    methods:{
        
        async saveFollowUser(){
            if(this.$utils.localStorageGetItem("token")==null){
                this.$router.push("loginview")
            }else{
                const result = await saveFollow(this.UserDetail.id)
                if(result.data.code==0){
                    this.isFollow=true
                    this.$emit('toRefreshFollow')
                }
            }
            
        },
        async cancelFollowUser(){
            if(this.$utils.localStorageGetItem("token")==null){
                this.$router.push("loginview")
            }else{
                const result = await cancelFollow(this.UserDetail.id)
                if(result.data.code==0){
                    this.isFollow=false
                    this.$emit('toRefreshFollow')
                }
            }
        }
    }



}

</script>


<style lang="scss" scoped>

.content{
    position: relative;
    top: 150px;
}

.person-num{
    text-decoration: none;
    color: #b84d4d;
    font-size: 20px;
    font-weight: bold;
}


</style>