<template>
    <div> 
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="博客评论" name="1.1">
                <el-tabs v-model="activeName1" @tab-click="handleClick1">
                    <el-tab-pane label="我的文章评论" name="1.1.1">
                      <article-comment-list-view :article_comments="article_comments" :article_comments_ids="article_comments_ids"></article-comment-list-view>
                    </el-tab-pane>
                    <el-tab-pane label="待我审核的评论" name="1.1.2">
                      <article-comment-user-verify-list-view :article_comments_user_verify="article_comments_user_verify"></article-comment-user-verify-list-view>
                    </el-tab-pane>
                    <el-tab-pane label="我发表的评论" name="1.1.3">
                      <article-comment-to-other-list-view :article_comments_to_other="article_comments_to_other"></article-comment-to-other-list-view>
                    </el-tab-pane>
                </el-tabs>
            </el-tab-pane>
            <el-tab-pane label="问答评论" name="1.2">
                <el-tabs v-model="activeName2" @tab-click="handleClick2">
                    <el-tab-pane label="我收到的评论" name="1.2.1">
                    </el-tab-pane>
                    <el-tab-pane label="我发表的评论" name="1.2.2">
                    </el-tab-pane>
                </el-tabs>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>

import ArticleCommentListView from "./Components/ArticleCommentListView";
import ArticleCommentToOtherListView from "@/views/Article/ArticleComment/Components/ArticleCommentToOtherListView";
import ArticleCommentUserVerifyListView from "@/views/Article/ArticleComment/Components/ArticleCommentUserVerifyListView";
import {
  getCommentsByUserId,
  getCommentsToOtherByUserId,
  getCommentsUserVerifyByUserId
} from "@/api/article-server/getData";

export default{

  components:{
    ArticleCommentListView,
    ArticleCommentToOtherListView,
    ArticleCommentUserVerifyListView
  },


    data() {
      return {
        activeName: '1.1',
        activeName1:'1.1.1',
        activeName2:'1.2.1',
        article_comments: '无数据',
        article_comments_ids:[],
        article_comments_to_other:'无数据',
        article_comments_user_verify:'无数据'
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
      handleClick1(tab, event) {
        if(tab.name=='1.1.1'){
          this.getCommentsByUserId();
        }else if(tab.name=="1.1.2"){
          this.getCommentsUserVerifyByUserId();
        }else if(tab.name=="1.1.3"){
          this.getCommentsToOtherByUserId();
        }
        console.log(tab, event);
      },
      handleClick2(tab, event) {
        console.log(tab, event);
      },

      async getCommentsByUserId(){
        const result =await getCommentsByUserId(this.$utils.localStorageGetItem("user_id"))
        if(result.data.code===0){
          this.article_comments = result.data.data
          this.comments2ids(this.article_comments)
        }else {
          this.article_comments = "null"
        }
      },

      comments2ids(article_comments){
        for(var i=0;i<article_comments.length;i++){
          this.article_comments_ids[i].id=article_comments[i]
        }
      },

      async getCommentsToOtherByUserId(){
        const result = await getCommentsToOtherByUserId(this.$utils.localStorageGetItem("user_id"))
        if(result.data.code===0){
          this.article_comments_to_other = result.data.data
        }else {
          this.article_comments_to_other="null"
        }
      },

      async getCommentsUserVerifyByUserId(){
        const result = await getCommentsUserVerifyByUserId(this.$utils.localStorageGetItem("user_id"));
        if(result.data.code===0){
          this.article_comments_user_verify = result.data.data
        }else {
          this.article_comments_user_verify = "null"
        }
      }

    },
  created() {
    this.getCommentsByUserId();
  }

}

</script>


<style lang="scss" scoped>


</style>