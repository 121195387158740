<template>
    <div> 
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="专栏管理" name="1.1">
                <el-tabs  v-model="activeName1_1" @tab-click="handleClick1_1">
                    <el-tab-pane label="全部" name="1.1.1">
                        <article-classify-list-view :classify_list="classify_list" @refreshClassify="getClassifyByUser"></article-classify-list-view>
                    </el-tab-pane>
                    <el-tab-pane label="审核未通过" name="1.1.2">
                        均已通过
                    </el-tab-pane>
                    <el-tab-pane label="回收站" name="1.1.3">
                        <article-classify-recycle-list-view @refreshRecycle="getClassifyRecycleByUser" :classify_list="classify_list"></article-classify-recycle-list-view>
                    </el-tab-pane>
                    <el-tab-pane label="新建" name="1.1.4">
                        <article-classify-new></article-classify-new>
                    </el-tab-pane>
                </el-tabs>
            </el-tab-pane>
            <el-tab-pane label="订阅专栏" name="1.2">
                <el-tabs v-model="activeName1_2" @tab-click="handleClick1_2">
                    <el-tab-pane label="我的订阅" name="1.2.1">
                        我的订阅
                    </el-tab-pane>
                    <el-tab-pane label="订阅我的" name="1.2.2">
                        订阅我的
                    </el-tab-pane>
                </el-tabs>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import ArticleClassifyListView from './Components/ArticleClassifyListView.vue';
import {getClassifyByUser, getClassifyRecycleByUser} from '@/api/article-server/getData'
import ArticleClassifyRecycleListView from './Components/ArticleClassifyRecycleListView.vue';
import ArticleClassifyNew from './Components/ArticleClassifyNew.vue';


export default{
  components: { 
    ArticleClassifyListView, 
    ArticleClassifyRecycleListView,
    ArticleClassifyNew 
    },

    data() {
      return {
        activeName: '1.1',
        activeName1_1:'1.1.1',
        activeName1_2:'1.2.1',
        classify_list:'',
        isCreate:false,
        isEdit:false,
      };
    },
    methods: {

      handleClick(tab, event) {
        console.log(tab, event);
      },
      handleClick1_1(tab, event) {
        if(tab.name=="1.1.1"){
            this.getClassifyByUser();
        }if(tab.name=="1.1.3"){
            this.getClassifyRecycleByUser();
        }if(tab.name='1.1.4'){

        }
        console.log(tab, event);
      },
      handleClick1_2(tab, event) {
        console.log(tab, event);
      },
      async getClassifyByUser(){
        this.classify_list = ''
        const result = await getClassifyByUser(this.$utils.localStorageGetItem("user_id"),1,1000);
        if(result.data.code==0){
            this.classify_list = result.data.data.current_data
        }else{
            // this.$router.push('/loginview')
        }
      },
      async getClassifyRecycleByUser(){
        this.classify_list = ''
        const result = await getClassifyRecycleByUser(this.$utils.localStorageGetItem("user_id"));
        if(result.data.code==0){
            this.classify_list = result.data.data
        }else{
            // this.$router.push('/loginview')
        }
      }
    },
    created(){
        this.getClassifyByUser();
    }

}

</script>


<style lang="scss" scoped>


</style>