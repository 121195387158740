<template>
    <div>
        <div class="row"> 
            <div class="row">
                <div class="row" style="text-align:left">
                    <h6>个人资料</h6>
                    <hr/>
                </div>
                <div class="row"> 
                    <div class="col-2"> 
                        <!-- 头像 -->
                        <img class="img-circle-110" :src="UserDetail.head_img"/>
                    </div>
                    <div class="col-8"> 
                        <!-- 具体信息 -->
                        <div class="row"> 
                            <div class="col-2"> 
                               {{UserDetail.name}}
                            </div>
                            <div class="col-3"> 
                                码龄五年，已实名
                            </div>
                            <div class="col-2"> 
                                
                            </div>
                            <div class="col-4"> 

                            </div>
                        </div>
                        <p></p>
                        <div class="row"> 
                            <div class="col-5"> 
                                勋章，勋章，勋章，查看所有勋章  >
                            </div>
                            

                        </div>
                        <p></p>
                        <div class="row"> 
                            开通会员
                        </div>

                    </div>
                    <div class="col-2"> 
                        <!-- 活动信息 -->
                    </div>

                </div>
                <p></p>
                <div class="row"> 
                    <div class="col-2"> 
                        我的余额：0
                    </div>
                    <div class="col-3"> 
                        去充值&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;个人主页 >
                    </div>
                    <div class="col-2"> 
                    
                    </div>
                </div>
            </div>
            <p></p>
            <div class="row"> 
                 <b-card-group deck>
                    <b-card border-variant="light" header="基本资料"  style="text-align:left">
                        <b-card-text>
                            <div class="row">
                                <div class="col-2">
                                    用户昵称
                                </div>
                                <div class="col-2">
                                    {{UserDetail.name}}
                                </div>
                            </div>
                            <p></p>
                            <div class="row">
                                <div class="col-2">
                                    用&nbsp;&nbsp;户&nbsp;&nbsp;ID
                                </div>
                                <div class="col-2">
                                    {{UserDetail.id}}
                                </div>
                            </div>
                            <p></p>
                            <div class="row">
                                <div class="col-2">
                                    性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别
                                </div>
                                <div class="col-2">
                                    女
                                </div>
                            </div>
                            <p></p>
                            <div class="row">
                                <div class="col-2">
                                    个人简介
                                </div>
                                <div class="col-10">
                                    {{UserDetail.summary}}
                                </div>
                            </div>
                            <p></p>
                            <div class="row">
                                <div class="col-2">
                                    所在地区
                                </div>
                                <div class="col-2">
                                    山东
                                </div>
                            </div>
                            <p></p>
                            <div class="row">
                                <div class="col-2">
                                    地址管理
                                </div>
                                <div class="col-2">
                                    新增地址+
                                </div>
                            </div>
                            <p></p>
                            <div class="row">
                                <div class="col-2">
                                    出生日期

                                </div>
                                <div class="col-5">
                                    <div v-if="basic_birth_date_ops_show">
                                        <!-- <label for="example-datepicker">Choose a date</label> -->     
                                        <div class="row"> 
                                            <div class="col-10"> 
                                                <b-form-datepicker  id="example-datepicker" v-model="value" class="mb-2"></b-form-datepicker>                                           
                                            </div>
                                            <div class="col-2">
                                                <a @click="basic_birth_date_ops_show_onclick" >完成</a>  
                                            </div>
                                        </div>                                                                                                                                                                             
                                    </div>
                                    <div v-else> 
                                        {{value}}&nbsp;&nbsp;<b-icon @click="basic_birth_date_ops_show_onclick" icon="pencil"></b-icon>                                          
                                    </div>
                                </div>
                                <div class="col-2"> 
                                </div>
                            </div>
                            <p></p>
                             <div class="row">
                                <div class="col-2">
                                    开始工作
                                </div>
                                <div class="col-2">
                                    2000
                                </div>
                            </div>
                        </b-card-text>
                    </b-card>
                    <b-card border-variant="light" header="教育信息"  style="text-align:left">
                        <b-card-text>
                            <div class="row">
                                <div class="col-2">
                                    学校名称
                                </div>
                                <div class="col-2">
                                    xxxxx
                                </div>
                            </div>
                            <p></p>
                            <div class="row">
                                <div class="col-2">
                                    专业名称
                                </div>
                                <div class="col-2">
                                    xxxxx
                                </div>
                            </div>
                            <p></p>
                            <div class="row">
                                <div class="col-2">
                                    入学时间
                                </div>
                                <div class="col-2">
                                    xxxxxxx
                                </div>
                            </div>
                            <p></p>
                            <div class="row">
                                <div class="col-2">
                                    最高学历
                                </div>
                                <div class="col-2">
                                    sxxxxxxx
                                </div>
                            </div>
                        </b-card-text>
                    </b-card>
                    <b-card border-variant="light" header="工作信息"  style="text-align:left">
                                       <b-card-text>
                            <div class="row">
                                <div class="col-2">
                                    公司名称
                                </div>
                                <div class="col-2">
                                    xxxxx
                                </div>
                            </div>
                            <p></p>
                            <div class="row">
                                <div class="col-2">
                                    职位名称
                                </div>
                                <div class="col-2">
                                    xxxxx
                                </div>
                            </div>
                            <p></p>
                            <div class="row">
                                <div class="col-2">
                                    所属行业
                                </div>
                                <div class="col-2">
                                    xxxxx
                                </div>
                            </div>
                        </b-card-text>
                    </b-card>
                    <b-card border-variant="light" header="兴趣标签"  style="text-align:left">
                        <b-card-text>
                            <div class="row">
                                <div class="col-2">
                                    很感兴趣
                                </div>
                                <div class="col-2">
                                    xxxxx
                                </div>
                            </div>
                            <p></p>
                            <div class="row">
                                <div class="col-2">
                                    不感兴趣
                                </div>
                                <div class="col-2">
                                    xxxxx
                                </div>
                            </div>
                        </b-card-text>
                    </b-card>
                </b-card-group>
            </div>
        </div>
    </div>
</template>

<script>



export default{

data(){
    return{
        value:"2022-09-26",
        basic_birth_date_ops_show:false
    }
},
props:{
    UserDetail:{
        type:Object,
        required:true
    }
}
,
methods:{
    basic_birth_date_ops_show_onclick(){
        if(this.basic_birth_date_ops_show){
            
            this.basic_birth_date_ops_show = false
        }else{
            this.basic_birth_date_ops_show = true
        }
       
    }
}


}

</script>


<style lang="scss" scoped>


</style>