import axios from "./base";
import utils from '@/utils/yujianbo-js-utils-1.0.0'




/**
 * 获取单个文章内容
 * @param {文章的id} article_id
 */
export const getArticle=(article_id)=>axios.get("article/pri/v1/get_one_by_article_id",{
    params:{
        article_id,
        token: utils.localStorageGetItem("token")
    }
})


/**
 * 获取公开文章数据
 * @returns 文章
 */
export const getArticlePublic=(page,size)=>axios.get("article/pub/v1/get_articles_public",{

    params:{
        page,
        size
    }
})

/**
 * 获取公开的热门榜单
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getArticleHotByPublic=()=>axios.get("article/pub/v1/get_articles_hot_by_public",{

})

/**
 * 获取公开的最新榜单
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getArticleNewByPublic=()=>axios.get("article/pub/v1/get_articles_new_by_public",{

})


/**
 * 获取单个文章的所有评论
 * @param {文章的id} article_id 
 * @returns 
 */
export const getArticleComments=(article_id)=>axios.get("comment/pub/v1/get_all_by_article_id",{
    params:{
        article_id
    }
})

/**
 * 获取单个文章的内容以及相关的用户，分类，专栏信息
 * @param {文章的id} article_id 
 * @returns 
 */
export const getArticleAndOther=(article_id)=>axios.get("article/pub/v1/get_one_and_user_by_article_id",{
    params:{
        article_id
    }
})

/**
 * 获取对应用户的热门文章
 * @param {用户的id} user_id 
 * @returns 
 */
export const getArticleHotByUser=(user_id,page,size,title_length)=>axios.get("article/pub/v1/get_hot_by_user_id",{
    params:{
        user_id,
        page,
        size,
        title_length
    }
})

/**
 * 获取对应用户的最新文章
 * @param {用户的id} user_id 
 * @returns 
 */
export const getArticleNewByUser=(user_id,page,size,title_length)=>axios.get("article/pub/v1/get_new_by_user_id",{
    params:{
        user_id,
        page,
        size,
        title_length
    }
})

/**
 * 获取当前文章的相关文章
 * @param {公共分类的id} classify_basic_id 
 * @param {关键字} small_title 
 * @param {第几页} page
 * @param {每页大小} size
 * @returns 
 */
export const getArticleRelate=(article_id,page,size)=>axios.get("article/pub/v1/get_relate_by_title",
{
    params:{
        article_id,
        page,
        size
    }
})

/**
 * 分页获取用户个人主页公开的文章
 * @param {用户id} user_id 
 * @param {第几页} page 
 * @param {每页大小} size 
 * @returns 
 */
export const getArticlesPublicByUserShow=(user_id,page,size)=>axios.get("article/pub/v1/get_public_by_user_id",{
    params:{
        user_id,
        page,
        size
    }
})

/**
 * 根据标题公开搜索
 * @param big_title 标题
 * @returns {Promise<AxiosResponse<any>>}
 */
export const  getArticlesForTitleByPublic=(big_title,page,size)=>axios.get("article/pub/v1/get_articles_by_match_title",
{
    params:{
        big_title,
        page,
        size,
    }
})

/**
 * 获取基础分类数据
 * @returns 分类
 */
export const getClassifyBasic=()=>axios.get("classify_basic/pub/v1/get_classify_basic_summary",
{})

/**
 * 根据私有分类专栏id获取对应的文章数据
 * @returns 
 */
export const getArticleListByClassifyBasicId=(classify_basic_id,page,size)=>axios.get("article/pub/v1/get_articles_by_classify_basic_id",
{
    params:{
        classify_basic_id,
        page,
        size
    }
})

/**
 * 根据私有分类的id获取文章
 * @param {私有分类的id} classify_id 
 * @returns 
 */
export const getArticlesByClassifyId=(classify_id,page,size,title_length)=>axios.get("article/pub/v1/get_articles_by_classify_id",{
    params:{
        classify_id,
        page,
        size,
        title_length
    }
})
/**
 * 获取对应用户的私有分类数据
 * @param {用户的id} user_id 
 * @returns 
 */
export const getClassifyByUser=(user_id,page,size) => axios.get("classify/pub/v1/get_all_by_user_id",
{
    params:{
        user_id,
        page,
        size
    }
})
 /**
  * 获取个人分类专栏的详情
  * @param {分类专栏id} classify_id 
  * @returns 
  */
 export const getClassifyByClassifyId=(classify_id)=>axios.get("classify/pub/v1/get_one_by_classify_id",
 {
    params:{
        classify_id,
    }
 })



/**
 * 获取文章的收藏数量
 * @param {文章id} article_id 
 * @returns 
 */
export const getNumberOfArticleCollection=(article_id)=>axios.get("collection/pub/v1/get_number_of_collection",{
    params:{
        article_id
    }
})

/**
 * 获取文章的点赞数量
 * @param {文章id} article_id 
 * @returns 
 */
export const getNumberOfArticlePraise=(article_id)=>axios.get("praise/pub/v1/get_number_of_praise",{
    params:{
        article_id
    }
})

/**
 * 获取文章的踩数量
 * @param {文章id} article_id 
 * @returns 
 */
export const getNumberOfArticleCriticise=(article_id)=>axios.get("criticise/pub/v1/get_number_of_criticise",{
    params:{
        article_id
    }
})

/**
 * 获取最近三年每年的文章数量
 * @param {用户id} user_id 
 * @returns 
 */
export const getNumberForPerYear=(user_id)=>axios.get("article/pub/v1/get_number_for_per_year",{
    params:{
        user_id
    }
})





// ************************************************* 私 有 接口*************************************************




/**
 * 获取对应用户的全部文章
 * @param {用户id} user_id 
 * @param {第几页} page
 * @param {每页大小} size
 * @returns 
 */
export const getArticlesAllByUser=(page,size)=>axios.get("article/pri/v1/get_articles_all",
{
    params:{
        page,
        size,
        token: utils.localStorageGetItem("token")
    }
})

/**
 * 根据分类和标题名称私有搜索
 * @param classify_basic_id 公共分类id
 * @param classify_id 私有分类id
 * @param big_title 标题
 * @param user_id 用户id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getArticlesForClassifyAndTitleByUser=(classify_basic_id,classify_id,big_title,page,size)=>axios.get("article/pri/v1/get_articles_by_classify_and_title",
{
    params:{
        classify_basic_id,
        classify_id,
        big_title,
        page,
        size,
        token: utils.localStorageGetItem("token")
    }
})

/**
 * 获取对应用户的私密文章
 * @param {用户的id} user_id 
 * @returns 
 */
export const getArticlesPrivateByUser=(page,size) => axios.get("article/pri/v1/get_articles_private",
{
    params:{
        page,
        size,
        token: utils.localStorageGetItem("token")
    }
})



/**
 * 获取对应用户的公开文章
 * @param {用户的id} user_id 
 * @returns 
 */
export const getArticlesPublicByUser=(user_id,page,size) => axios.get("article/pri/v1/get_articles_public",
{
    params:{
        user_id,
        page,
        size,
        token: utils.localStorageGetItem("token")
    }
},
{
    Headers:{
        user_id
    }
})

/**
 * 获取对应用户的审核中文章
 * @param {用户的id} user_id 
 * @returns 
 */
export const getArticlesExaminingByUser=(page,size) => axios.get("article/pri/v1/get_articles_examining",
{
    params:{
        page,
        size,
        token: utils.localStorageGetItem("token")
    }
})

/**
 * 获取对应用户的回收站文章
 * @param {用户的id} user_id 
 * @returns 
 */
export const getArticlesRecycleByUser=(page,size) => axios.get("article/pri/v1/get_articles_recycle",
{
    params:{
        page,
        size,
        token: utils.localStorageGetItem("token")
    }
})

/**
 * 获取对应用户的草稿文章
 * @param {用户的id} user_id 
 * @returns 
 */
export const getArticlesDraftByUser=(page,size) => axios.get("article/pri/v1/get_articles_draft",
{
    params:{
        page,
        size,
        token: utils.localStorageGetItem("token")
    }
})






/**
 * 获取对应用户的私有分类回收站数据
 * @param {用户的id} user_id 
 * @returns 
 */
 export const getClassifyRecycleByUser=(user_id) => axios.get("classify/pri/v1/get_recycle_by_user_id",
 {
     params:{
         user_id,
         token: utils.localStorageGetItem("token")
     }
 })




/**
 * 根据用户的id获取评论
 * @param user_id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCommentsByUserId=(user_id)=>axios.get("comment/pri/v1/get_comments_by_user_id",{
    params:{
        user_id,
        token: utils.localStorageGetItem("token")
    }
})

/**
 * 获取当前用户评论其他用户文章的评论
 * @param user_id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCommentsToOtherByUserId=(user_id)=>axios.get("comment/pri/v1/get_comments_to_other_by_user_id",{
    params:{
        user_id,
        token: utils.localStorageGetItem("token")
    }
})

/**
 * 获取当前用户待审核的评论
 * @param user_id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCommentsUserVerifyByUserId=(user_id)=>axios.get("comment/pri/v1/get_comments_user_verify_by_user_id",{
    params:{
        user_id,
        token: utils.localStorageGetItem("token")
    }
})




/**
 * 获取文章的收藏记录
 * @param {文章id} article_id 
 * @returns 
 */
export const getArticleCollection=()=>axios.get("collection/pri/v1/get_collection",{
    params:{
        token:utils.localStorageGetItem("token")
    }
})

/**
 * 获取文章的点赞记录
 * @param {文章id} article_id 
 * @returns 
 */
export const getArticlePraise=()=>axios.get("praise/pri/v1/get_praise",{
    params:{
        token:utils.localStorageGetItem("token")
    }
})

/**
 * 获取文章的踩记录
 * @param {文章id} article_id 
 * @returns 
 */
export const getArticleCriticise=()=>axios.get("criticise/pri/v1/get_criticise",{
    params:{
        token:utils.localStorageGetItem("token")
    }
})
