<template>
    <div class="row" ref="pronbit">
        <div class="col-12" >       
            <div :class="classx" >
                <div class="col-1"> 
                </div> 
                <div class="col-3"> 
                    <div class="row" > 
                        <div class="col-2" style="line-height:40px;text-align: center;"> 
                            <router-link to="/"><img class="logo" src="https://blog-pc-front.oss-cn-beijing.aliyuncs.com/logo/xiaoqingshu_01.png"/></router-link>
                        </div>
                        <div class="col-2" > 
                            <router-link to="/">主页</router-link>
                        </div>
                        <div class="col-2"> 
                            <router-link to="/">资源</router-link>
                        </div>
                        <div class="col-2"> 
                            <router-link to="/">学习</router-link>
                        </div>
                        <div class="col-2"> 
                            <el-popover
                                placement="top-start"
                                
                                width="200"
                                trigger="hover"
                                >
                                <el-button slot="reference" style="" type="text" >题库</el-button>
                                <router-link to="/examimportview" style="text-decoration:none">编辑题库</router-link>
                                <p></p>
                                <router-link to="/examquestionview"  style="text-decoration:none">查找题目</router-link>
                                <p></p>
                                <router-link to="/examshowview"  style="text-decoration:none">我的试卷</router-link>
                            </el-popover>
                            <!-- <router-link to="/examimportview">题库</router-link> -->
                            <!-- <a href="../assets/高性能MySQL（第3版）高清带目录.pdf">课程/题库</a> -->
                        </div>
                        <div class="col-2"> 
                            <router-link to="/personview">认证</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-4"> 
                     <div class="input-group mb-3 " v-if="showSearch">
                        <input @keyup.enter="toSearch" v-model="big_title" type="text" class="form-control head-search-input" placeholder="输入关键词搜索……" aria-label="Example text with button addon" aria-describedby="button-addon1"/>
                        <button @click="toSearch" class="btn btn-outline-secondary head-search-button"  type="button" id="button-addon1">
                            <b-icon icon="search" variant="warning"></b-icon>
                        </button>                        
                    </div>
                </div>
                <div class="col-3"> 
                    <div class="row">                
                        <div class="col-2"> 
                            <router-link to="/talkview">动态</router-link>
                        </div>
                        <div class="col-2"> 
                            <router-link to="/personview">足迹</router-link>
                        </div>
                        <div class="col-2"> 
                            <router-link to="/personview">关注</router-link>
                        </div>  
                        <div class="col-2"> 
                            <router-link to="/personview">消息</router-link>
                        </div>
                        <div class="col-2"> 
                            <router-link to="/articlecreateview">创作</router-link>
                        </div>
                        <div class="col-2"> 
                            <router-link v-if="UserDetail.head_img==null||UserDetail.head_img==''" :to="{name:'LoginView',path:'/loginview'}">
                                <a >登录</a>
                            </router-link>
                            <router-link v-else :to="{name:'PersonView',path:'/personview'}">
                                <img  :src="UserDetail.head_img" class="img-circle-25"/>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-2"> 
                </div>
                <!-- <hr/> -->
                         
                <div class="row"> 
                </div>
            </div>  
        </div>
    </div>
</template>

<script>



export default{
data() {
    return {
        classx:'row head-top text-center-vh-40 ',
        showSearch:true,
        big_title: ''
    }
},

mounted(){
	window.addEventListener('scroll',this.handleScrollx,true)
},

methods: {

    /**
     * 判断指定的div距离顶部的距离
     */
	handleScrollx() {
	  console.log('滚动高度',window.pageYOffset)
	  console.log('距离顶部高度',this.$refs.pronbit.getBoundingClientRect().top)
      if(this.$refs.pronbit.getBoundingClientRect().top<-40){
        this.classx="row fixed-top head-top text-center-vh-40 "
      }else{
        this.classx='row head-top text-center-vh-40'
      }
	},
  toSearch(){
	  this.$router.push(
        {
          name: "SearchView",
          params:{
            "big_title":this.big_title
          }
        }
    )
  }
},

props:{
    UserDetail:{
        type:Object,
        required:true
    }
},


created(){

    if(this.$route.name==="SearchView"){
      this.showSearch=false;
    }else {
      this.showSearch=true;
    }
}


}

</script>


<style lang="scss" scoped>


.fixed-top{
//     position: fixed;
    width: 1920px;
    top: 0;
}

.logo{
    position: relative;
    width: 90px;
    height: 28px;
    left:-5px;
}

.head-top{
    background-color: #ffffff;
    font-size: 14px;
}

.head-top a{
     color: #000000;
     text-decoration: none;
}
.head-search{
    position: sticky;
    margin-left: 35%;
    margin-top: 150px;
    top: 0;   
}
.input-group{
    height: 20px;
    position:sticky;
    top: 0;
}
.head-search-input{
   border-radius: 40px; 
//    height: 42px;
   width: 80%;//给另一个元素按钮留出20%空间
}
.head-search-button{
   border-radius: 40px; 
//    height: 38px;
   background-color: #e73803;
   border-top-color: #ffffff;
   border-bottom-color: #ffffff;
   border-left-color: #ffffff;
   border-right-color: #ffffff;
}



</style>