<template>

    <div class="row"> 
        <p></p>
        <div class="row classify-list-title"> 
                        <div class="col-4"> 
                专栏名称
            </div>
            <div class="col-2"> 
                创建时间
            </div>

            <div class="col-2"> 
                操作
            </div>
            <div class="col-1"> 
                是否收费
            </div>
            <div class="col-1"> 
                主页显示
            </div>
            <div class="col-1"> 
                文章数
            </div>
            <div class="col-1"> 
                订阅数
            </div>

        </div>       
        <hr style="color:darkgrey"/>
        
        <div class="row classify-list" v-for="classify in classify_list" :key="classify"> 
            <p></p>
            <div class="col-4 text-center-vh-50"> 
                <div class="row"> 
                    <div class="col-4"> 
                        <a>
                        <b-card-img style="width:50px;height:50px" :src="classify.head_img"  alt="Image" class="rounded-0"></b-card-img>
                    </a>
                    </div>
                    <div class="col-8" style="text-align:left"> 
                        {{classify.name.substring(0,20)}}
                    </div>
                </div>
            </div>
             <div class="col-2 text-center-vh-50"> 
                <small>{{classify.create_time}}</small>
            </div>

            
            <div class="col-2 classify-list-operate text-center-vh-50"> 
                <el-button @click="recoveryClassify(classify)" type="success" icon="el-icon-refresh-left" size="small"  circle></el-button>
                <!-- <el-button type="primary" icon="el-icon-edit" size="small" circle></el-button> -->
                <el-button type="danger" icon="el-icon-delete" size="small" circle></el-button>
            </div>
            <div class="col-1 text-center-vh-50"> 
                <el-switch 
                        v-model="classify.charge" :active-value="1" :inactive-value="0"
                        active-text=""
                        inactive-text="" 
                        @change="updateCharge(classify.id,classify.charge)"
                        >
                </el-switch>
            </div>
            <div class="col-1 text-center-vh-50"> 
                <el-switch
                        v-model="classify.open"
                        :active-value="1"
                        :inactive-value="0"
                        active-text=""
                        inactive-text=""
                        @change="updateOpen(classify.id,classify.open)">
                </el-switch>
            </div>
            <div class="col-1 classify-list-article text-center-vh-50"> 
                {{classify.article}}
            </div>
            <div class="col-1 classify-list-subscribe text-center-vh-50"> 
                {{classify.subscribe}}
            </div>
            <p></p>
            <p></p>
            <hr style="color:darkgrey"/>
        </div>
    </div>
  
</template>

<script>

import { recoveryClassify } from '@/api/article-server/saveData'

  export default {
    data() {
      return {
        classify_id:''
      }
    },
    props:{
        classify_list:{
            type:Object,
            required:true
        }
    },
    methods: {

        updateCharge(classify_id,classify_charge){
            console.log("updateCharge:"+classify_id+","+classify_charge)
        },
        updateOpen(classify_id,classify_open){
            console.log("updateOpen:"+classify_id+","+classify_open)
        },
        async recoveryClassify(classify){
            this.classify_id = classify.id
            const result = await recoveryClassify(this.classify_id)
            if(result.data.code==0){
                alert("专栏恢复成功")
                this.$emit('refreshRecycle');
            }
        }
      
    }
  }
</script>

<style lang="scss" scoped>
.classify-list-title{
    color: rgb(146, 146, 146);
    font-size: 14px;
    text-align: center;
}

.classify-list{
    text-align: center;
}

.classify-list-operate a{
    font-size: medium;
    text-decoration: none;
}

.classify-list-article{
    font-size: 15px;
}

.classify-list-subscribe{
    font-size: 15px;
}

</style>