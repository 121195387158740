<template>
    <div class="row">
        <div class="row">
            <p></p>
            <div class="row"> 
                <!-- <div class="col-1" style="text-align:left"> 
                    <el-button type="text" @click="goback()">
                        <i class="el-icon-back" ></i>
                        返回
                        文章管理
                    </el-button>
                </div> -->
                <div class="col-11" style="text-align:left">
                    <el-row> 
                        <el-col span=1> 
                            <el-button type="text" @click="goback()">
                                <i class="el-icon-back" ></i>
                                返回
                            </el-button>
                        </el-col>
                        <el-col span=23> 
                            <el-form > 
                                <el-form-item label="" >
                                    <el-input v-model="editor_big_title" placeholder="文章标题"></el-input>
                                </el-form-item>                        
                            </el-form>    
                        </el-col>
                    </el-row>
                </div>
                <div class="col-1"> 
                    <div>                        
                        <el-button id="show-btn" type="danger" circle @click="showModal">发布/草稿</el-button>
                        <b-modal ref="my-modal" hide-footer title="发布/草稿">
                            <div class="d-block text-center" style="text-align:left">
                                <el-form>
                                    
                                    <el-form-item label="文章简介" label-width="68px">
                                        <el-input type="textarea" v-model="editor_summary" placeholder="若为空，取文章前30字"></el-input>
                                    </el-form-item>

                                    <el-form-item label="所属分类" style="text-align:left">
                                        <el-select v-model="editor_classify_basic_id"    placeholder="请选择分类">
                                            <el-option
                                                v-for="classify_basic in editor_classify_basic"
                                                :key="classify_basic.id"
                                                :label="classify_basic.name"
                                                :value="classify_basic.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="我的专栏" style="text-align:left">                   
                                        <el-select v-model="editor_classify_id"   placeholder="请选择分类">                   
                                            <el-option
                                            v-for="classify in editor_classify"
                                            :key="classify.id"
                                            :label="classify.name"
                                            :value="classify.id"
                                             >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>                                    
                                    <el-form-item label="是否公开" style="text-align:left">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <el-switch v-model="editor_publiced"></el-switch>
                                    </el-form-item>    
                                    <el-form-item label="定时发布" style="text-align:left">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <el-switch
                                            v-model="editor_publish_date_enable"
                                            active-text=""
                                            inactive-text="">
                                        </el-switch>
                                    </el-form-item>
                                    <el-form-item label="发布时间" >
                                        <el-row>
                                        <el-col :span="6">
                                        <el-date-picker :disabled="editor_publish_date_disable" type="date" 
                                                        :picker-options="{
                                                            disabledDate(time) {
                                                                return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000;
                                                                // return time.getTime() < Date.now();
                                                            },
                                                            // selectableRange: startTimeRange
                                                        }"                   
                                                        placeholder="选择日期"  v-model="editor_published_date" style="width: 100%;"></el-date-picker>
                                        </el-col>
                                        <!-- - -->
                                        <el-col class="line" :span="1"><a style="color:darkgray">&nbsp;&nbsp;&nbsp;&nbsp;—&nbsp;&nbsp;</a></el-col>
                                        <el-col :span="6">
                                        <el-time-picker :disabled="editor_publish_date_disable" @change="handle" placeholder="选择时间" v-model="editor_published_time" style="width: 100%;"></el-time-picker>
                                        </el-col>
                                        </el-row>
                                        <small>注意：定时不能在2分钟以内，否则直接发布，建议发布在十分钟以后</small>
                                    </el-form-item>   
                                    
                                    
                                    <el-form-item label="文章封面" label-width="68px">
                                        <el-row> 
                                            <el-col span="8">
                                                <el-upload
                                                    class="avatar-uploader"
                                                    :action="article_cover_upload_action"
                                                    :show-file-list="false"
                                                    :on-success="handleAvatarSuccess1"
                                                    :before-upload="beforeAvatarUpload"
                                                    name="article_cover_img"
                                                    >
                                                    <img v-if="article_cover_img_1" :src="article_cover_img_1" class="avatar">
                                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                                </el-upload>
                                            </el-col>
                                            <el-col span="8">
                                                <el-upload
                                                    class="avatar-uploader"
                                                    :action="article_cover_upload_action"
                                                    :show-file-list="false"
                                                    :on-success="handleAvatarSuccess2"
                                                    :before-upload="beforeAvatarUpload"
                                                    name="article_cover_img"
                                                    >
                                                    <img v-if="article_cover_img_2" :src="article_cover_img_2" class="avatar">
                                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                                </el-upload>
                                            </el-col>
                                            <el-col span="8">
                                                <el-upload
                                                    class="avatar-uploader"
                                                    :action="article_cover_upload_action"
                                                    :show-file-list="false"
                                                    :on-success="handleAvatarSuccess3"
                                                    :before-upload="beforeAvatarUpload"
                                                    name="article_cover_img"
                                                    >
                                                    <img v-if="article_cover_img_3" :src="article_cover_img_3" class="avatar">
                                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                                </el-upload>
                                            </el-col>
                                        </el-row>                                                                                          
                                    </el-form-item>
                                    <el-form-item label="发布/草稿" style="text-align:left"> 
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <el-button type="danger" :disabled="publish_draft_disable" circle  @click="savePublishArticle">立即发布</el-button>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <el-button type="success" :disabled="publish_draft_disable" circle @click="saveDraftArticle">存为草稿</el-button>
                                    </el-form-item>                                                    
                                </el-form>
                            </div>
                        </b-modal>                        
                    </div>  
                </div>         
            </div>
        </div>
        <!-- <p></p> -->
        <hr style="color:darkgray"/>
        <div class="row">
            <div class="col-6"> 
                <el-form label-width="80px" label-position="left">               
                    <el-form-item label="关键词" >
                        <el-input v-model="editor_small_title" placeholder="输入关键词，使用空格隔开，若为空，将默认使用标题作为关键词"></el-input>
                    </el-form-item>
                    <el-form-item label="文章内容">
                        <editor-view @childEditorContent="getContent"></editor-view> 
                    </el-form-item>

                </el-form>
            </div>
            <div class="col-6"> 
                <div > 
                    <div class="row" style="text-align:left">
                        <p></p> 
                        <h5 v-if="editor_big_title!=''">{{editor_big_title}}</h5>
                        <h5 v-else style="color:red">输入标题以显示</h5>
                        <p></p>
                    </div>
                    <div class="row " style="text-align:left;background-color:#eaeaea;color:dimgrey;"> 
                        <div class="col-1 text-left-vh-32"> 
                            原创
                        </div>
                        <div class="col-11">            
                            <div class="row text-left-vh-32"> 
                                <div class="col-8"> 
                                    <small>
                                        <i class="el-icon-user-solid"></i>
                                        &nbsp;
                                        {{this.$utils.localStorageGetItem("user_name")}}
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        于
                                        &nbsp;
                                        2022-08-03 12:26:32
                                        &nbsp;
                                        发布
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <i class="el-icon-view"></i>
                                        {{0}}
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <i class="el-icon-star-off"></i>
                                        {{0}}
                                    </small>                                   
                                </div>
                                <div class="col-4"> 
                                </div>                       
                            </div>
                            <div class="row text-left-vh-28">
                                <div class="col-6"> 
                                    <small> 
                                        <i class="el-icon-notebook-2"></i>
                                        &nbsp;
                                        文章标签：{{editor_small_title}}
                                    </small>
                                </div>                        
                            </div>
                        </div>
                    </div>
                    <p></p>           
                    <div class="row layout-scroll-parent" style="height:700px"> 
                        <div class="layout-scroll-child"> 
                            <p v-html="editor_content"></p>
                        </div>
                        
                    </div>
                </div>
            </div>             
            
        </div>
    

    
    </div>
</template>

<script>
import EditorView from './Components/EditorView.vue'
import {getClassifyByUser,getClassifyBasic} from '@/api/article-server/getData'
import {saveArticle} from '@/api/article-server/saveData'

export default{

    components: { 
        EditorView 
    },

    data() {



        return {
            article_cover_upload_action:this.$utils.getApiHostPort()+this.$url.get_article_cover_upload_action(),
            article_cover_img_1:'',
            article_cover_img_2:'',
            article_cover_img_3:'',


            publish_draft_disable:false,

            editor_big_title: '',
            editor_small_title:'',
            editor_content:'',
            editor_summary:'',
            editor_classify_id:'',
            editor_classify_basic_id:'',
            editor_publiced:false,
            editor_published:'',
            editor_publish_date_enable:false,
            editor_published_date_time:'',
            editor_publish_date_disable:true,

            editor_published_date:'',
            editor_published_time:'',

            editor_classify:[{id:0,name:'默认'}],
            editor_classify_basic:'',

            time_temp:''
            
        }
    },
    watch:{
        editor_publish_date_enable(){
            if(this.editor_publish_date_enable==true){
                console.log(this.editor_publish_date_enable)
                //启用定时发布组件
                this.editor_publish_date_disable=false;
            }else{
                //清除日期数据
                this.editor_published_date='',
                this.editor_published_time='',
                console.log(this.editor_publish_date_enable)
                //禁用定时发布的组件
                this.editor_publish_date_disable=true;
            }
        },

    }
    ,


    methods:{

        goback(){
            this.$router.back();
        },

        handleAvatarSuccess1(res, file) {
            this.article_cover_img_1 = URL.createObjectURL(file.raw);
            this.article_cover_img_1 = res.data
            console.log(res.data)
        },
        handleAvatarSuccess2(res, file) {
            this.article_cover_img_2 = URL.createObjectURL(file.raw);
            this.article_cover_img_2 = res.data
            console.log(res.data)
        },
        handleAvatarSuccess3(res, file) {
            this.article_cover_img_3 = URL.createObjectURL(file.raw);
            this.article_cover_img_3 = res.data
            console.log(res.data)
        },
        beforeAvatarUpload(file) {
            let types = ['image/jpeg', 'image/jpg', 'image/png'];
            const isImage = types.includes(file.type);
            // const isJPG = file.type === 'image/*';
            
            if (!isImage) {
                this.$message.error('上传文章封面只能是 jpeg、jpg、png 格式!');
            }

            const isLt2M = file.size / 1024 / 1024 < 4;
            if (!isLt2M) {
                this.$message.error('上传文章封面大小不能超过 4 MB!');
            }
            return isImage && isLt2M;
        },

        /**
         * 弹出发布窗口
         */
        showModal() {
            if(this.editor_big_title.length<5){
                alert("标题过短")
            }else if(this.editor_content.length<20){
                alert("文章内容过短")                             
            }else {
                if(this.editor_small_title==''){
                    this.editor_small_title = this.editor_big_title  
                }                
                this.$refs['my-modal'].show()
            }
            
        },

        /**
         * 时间组件限制时间为当前时刻之后的时间
         */
        handle: function() {
            var startAt = new Date(this.editor_published_time) * 1000 /1000;
            if(startAt < Date.now()) {
                this.editor_published_time = new Date();
            }
        },

        getContent(editorContent){
            this.editor_content = editorContent;
        },

    /**
     * 保存发布文章
     */
    //   user_id,big_title,small_title,summary,content,classify_id,classify_basic_id,publiced,published,published_time
      async savePublishArticle(){
          this.publish_draft_disable=!this.publish_draft_disable;
          //最后两项，公开，发布
          if(this.editor_publiced){
            // console.log(this.getClassifyId()) 
            const result = await saveArticle(this.$utils.localStorageGetItem('user_id'),this.editor_big_title,this.editor_small_title,this.editor_summary,this.article_cover_img_1,this.article_cover_img_2,this.article_cover_img_3,this.editor_content,this.editor_classify_id,this.editor_classify_basic_id,1,1,this.editor_published_date,this.editor_published_time)
            if(result.data.code == 0){
                alert("文章发布成功")
                this.$router.push({path:'/articleview'})
            }else{
                this.publish_draft_disable=!this.publish_draft_disable;
            }
          }else{
          //最后两项，私有，发布
        //   console.log(this.getClassifyId()) 
            const result = await saveArticle(this.$utils.localStorageGetItem('user_id'),this.editor_big_title,this.editor_small_title,this.editor_summary,this.article_cover_img_1,this.article_cover_img_2,this.article_cover_img_3,this.editor_content,this.editor_classify_id,this.editor_classify_basic_id,0,1,this.editor_published_date,this.editor_published_time)
            if(result.data.code == 0){
                alert("文章发布成功")
                this.$router.push({path:'/articleview'})
            }else{
                this.publish_draft_disable=!this.publish_draft_disable;
            }
          }
          
        //   console.log("publishArticle："+this.editor_big_title+","+this.editor_small_title+","+this.ediotr_summary+","+this.editor_content)
      },
      /**
       * 保存草稿文章
       */
      async saveDraftArticle(){
        this.publish_draft_disable=!this.publish_draft_disable;
          //最后两项，私有，不发布
        //   console.log(this.getClassifyId()) 
            const result = await saveArticle(this.$utils.localStorageGetItem('user_id'),this.editor_big_title,this.editor_small_title,this.editor_summary,this.article_cover_img_1,this.article_cover_img_2,this.article_cover_img_3,this.editor_content,this.editor_classify_id,this.editor_classify_basic_id,0,0,this.editor_published_date,this.editor_published_time)
            if(result.data.code == 0){
                alert("草稿保存成功")
                this.$router.push({path:'/articleview'})
            }else{
                this.publish_draft_disable=!this.publish_draft_disable;
            }                       
        //   console.log("draftArticle："+localStorage.getItem('id')+","+this.editor_big_title+","+this.editor_small_title+","+this.ediotr_summary+","+this.editor_content)
      },

        /**
         * 获取公共分类
         */
      async getClassifyBasic(){
        const result = await getClassifyBasic();
        if(result.data.code==0){
            this.editor_classify_basic = result.data.data
            this.editor_classify_basic_id = this.editor_classify_basic[0].id
        }
      },

        /**
         * 获取私有分类
         */
      async getClassifyByUser(){
        const result = await getClassifyByUser(this.$utils.localStorageGetItem("user_id"),1,1000);
        if(result.data.code==0){
            this.editor_classify = result.data.data.current_data;
            this.editor_classify_id = this.editor_classify[0].id
        }else{
            this.editor_classify_id =this.editor_classify[0].id
        }
      },

      getCurrentTime(){
        var datetime = new Date()
        var year = datetime.getFullYear()
        var month = datetime.getMonth()+1
        var day = datetime.getDate()
        var hour = datetime.getHours()
        var min = datetime.getMinutes()
        var seconds = datetime.getSeconds()
        this.time_temp = year +"-"+month+"-"+day+" "+hour+":"+min+":"+seconds

      }

    

    },
    created(){
        this.getClassifyByUser();
        this.getClassifyBasic();
        this.getCurrentTime()
    }


}

</script>

<style lang="scss" scoped>

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 160px;
    text-align: center;
    margin-bottom: 50px;
  }
  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }


</style>











                    <!-- <br/>
                    <el-form-item label="是否公开">
                        <el-switch v-model="editor_publiced"></el-switch>
                    </el-form-item>
                    <el-form-item label="所属分类">
                        <el-select v-model="editor_classify_basic_id"   placeholder="请选择分类">
                            <el-option
                            v-for="classify_basic in editor_classify_basic"
                            :key="classify_basic.id"
                            :label="classify_basic.name"
                            :value="classify_basic.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="我的专栏">                   
                        <el-select v-model="editor_classify_id" placeholder="请选择分类">                   
                            <el-option
                            v-for="classify in editor_classify"
                            :key="classify.id"
                            :label="classify.name"
                            :value="classify.id">
                            </el-option>
                        </el-select>
                    </el-form-item>               

                    <el-form-item>
                        <div class="row"> 
                            <div class="col-2"> 
                            </div>
                            <div class="col-2"> 
                                <el-button type="danger"  @click="savePublishArticle">立即发布</el-button>
                            </div>
                            <div class="col-2"> 
                            </div>
                            <div class="col-2"> 
                                <el-button type="success" @click="saveDraftArticle">保存草稿</el-button>
                            </div>
                        </div>
                    </el-form-item> -->

                    <!-- <el-form-item label="活动性质">
                        <el-checkbox-group v-model="form.type">
                        <el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
                        <el-checkbox label="地推活动" name="type"></el-checkbox>
                        <el-checkbox label="线下主题活动" name="type"></el-checkbox>
                        <el-checkbox label="单纯品牌曝光" name="type"></el-checkbox>
                        </el-checkbox-group>
                    </el-form-item> -->
                    <!-- <el-form-item label="特殊资源">
                        <el-radio-group v-model="form.resource">
                        <el-radio label="线上品牌商赞助"></el-radio>
                        <el-radio label="线下场地免费"></el-radio>
                        </el-radio-group>
                    </el-form-item> -->