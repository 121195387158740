<template>
    <div>
        <el-row > 
            <el-col span=24>
                <el-card>
                    <el-row> 
                        <el-col span=24> 
                            <el-row > 
                                <el-col span=4> 
                                    <!-- <p></p> -->
                                    <el-image :src="classify.cover_img" round style="width:100px;height:100px;"></el-image>
                                </el-col>
                                <el-col span=19 style="text-align:left"> 
<!--                                    <p></p>-->
                                    <h4>{{classify.name}}</h4>
                                    <p>
                                      <small>
                                        {{classify.summary.substring(0,100)}}
                                      </small>
                                    </p>
                                    <p>
                                        <small>
                                            关注：{{classify.subscribe}}
                                            &nbsp;&nbsp;&nbsp;
                                            文章数：{{classify.article}}
                                            &nbsp;&nbsp;&nbsp;
                                            文章阅读量：123456
                                            &nbsp;&nbsp;&nbsp;
                                            文章收藏量：123456
                                        </small>
                                    </p>
                                </el-col>
                            </el-row>
                            <p></p>
                            <el-row>
                                <!-- <el-col span=1 class="layout-el-col-blank"></el-col>                             -->
                                <el-col span=24 style="background-color:#eaeaea;text-align: left;"> 
                                    <p></p>
                                    <h6>&nbsp;&nbsp;&nbsp;&nbsp;作者：{{UserDetail.name}}</h6>
                                    <p><small>&nbsp;&nbsp;&nbsp;&nbsp;简介：{{UserDetail.summary}}</small></p>
                                </el-col>
                                <!-- <el-col span=1></el-col> -->
                            </el-row>
                            <hr style="color:darkgray"/>
                            <el-row> 
                                <el-col> 
                                    <div 
                                        class="infinite-list" 
                                        v-infinite-scroll="load"  
                                        infinite-scroll-distance="1"  
                                        style="overflow-y:scroll;height:555px"
                                        >
                                        <el-row v-for="(article,index) in article_classify" :key="index">
                                            <router-link :to="{name:'ArticleShowView',path:'/articleshowview',query:{id:article.id,user_id:article.user_id}}"> 
                                                <el-col span=24 style="text-align:left"> 
                                                    <el-row> 
                                                        <el-col span=24> 
                                                            <h6>{{article.big_title}}</h6>
                                                        </el-col>
                                                    </el-row>
                                                    <el-row> 
                                                        <el-col span=24> 
                                                            <small>{{article.summary}}</small>
                                                        </el-col>
                                                    </el-row>
                                                    <p></p>
                                                    <el-row> 
                                                        <el-col span=24> 
                                                            <small class="text-muted">发布日期：{{article.published_date_time}}&nbsp;&nbsp;&nbsp;阅读量：{{article.viewed}}</small>
                                                        </el-col>
                                                    </el-row>
                                                    <hr style="color:darkgray"/>
                                                </el-col>   
                                            </router-link>
                                        </el-row>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                    <el-row> 
                        <el-col span=24> 

                        </el-col>
                    </el-row>
                </el-card> 
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { getArticlesByClassifyId } from '@/api/article-server/getData';


export default{

    data(){
        return{
            page:1,
            size:5,
            total_page:0,
            article_classify:''

        }
    },
    methods:{
        load(){
            if(this.page<this.total_page){
                this.page+=1
                this.getArticleByClassifyIdLoad()
            }
        },
        async getArticleByClassifyIdLoad(){
            const result = await getArticlesByClassifyId(this.$route.query.classify_id,this.page,this.size,-1)
            if(result.data.code==0){
                this.article_classify = this.article_classify.concat(result.data.data.current_data)
                this.total_page = result.data.data.total_page
            }
        },
        async getArticleByClassifyId(){
            const result = await getArticlesByClassifyId(this.$route.query.classify_id,this.page,this.size,-1)
            if(result.data.code==0){
                this.article_classify = result.data.data.current_data
                this.total_page = result.data.data.total_page
            }
        }
    },

    props:{

        // article_classify:{
        //     type:Object,
        //     required:true
        // },

        classify:{
            type:Object,
            required:true
        },

        UserDetail:{
            type:Object,
            required:true
        }

    },
    created(){
        this.getArticleByClassifyId()
    }


}

</script>


<style lang="scss" scoped>
a{
    text-decoration: none;
    color: black;
}

</style>