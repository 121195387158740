import axios from "./base";

export const login=(phone,pwd,image_code_key,image_code)=>axios.post("account/pri/v1/login",{
    phone,
    pwd,
    image_code_key,
    image_code
})


export const sendSms = (phone)=>axios.get("notify/pub/v1/send_register_code",{
    params:{
        phone
    }
})

export const sendForgetPwdSms = (phone)=>axios.get("notify/pub/v1/send_forget_pwd_code",{
    params:{
        phone
    }
})

export const isExistPhone = (phone)=>axios.get("account/pri/v1/is_exist_phone",{
    params:{
        phone
    }
})