import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeView from '@/views/Home/HomeView.vue'

import PersonView from "@/views/Person/PersonView.vue"
import PersonShowView from "@/views/Person/Show/PersonShowView.vue"

import LoginView from "@/views/Login/LoginView.vue"
import FindPasswordView from "@/views/Login/FindPasswordView.vue"
import RegisterView from "@/views/Register/RegisterView.vue"

import ArticleView from "@/views/Article/ArticleView.vue"
import ArticleContentView from '@/views/Article/ArticleContent/ArticleContentView.vue'
import ArticleCommentView from '@/views/Article/ArticleComment/ArticleCommentView.vue'
import ArticleShowView from '@/views/Article/ArticleShow/ArticleShowView.vue'
import ArticleCreateView from '@/views/Article/ArticleCreate/ArticleCreateView.vue'
import ArticleEditView from '@/views/Article/ArticleEdit/ArticleEditView.vue'

import ClassifyView from '@/views/Classify/ClassifyView.vue'

import SearchView from "@/views/Search/SearchView";

import TestView from '@/views/test.vue'

Vue.use(VueRouter)

const routes = [

  {
    name: "refresh",
    path: `/refresh`,
    component: () => import('@/views/Refresh/refresh.vue') 
     // 目录更改为你自己的refresh.vue的目录
  },

  {
    path:'/test',
    name:'Test',
    // component:TestView,
    component:()=>import('@/views/test.vue')
  },

  {
    path: '/',
    name: 'HomeView',
    // component: HomeView
    component:()=>import('@/views/Home/HomeView.vue')
  },

{
  path:"/personview",
  name:"PersonView",
  meta:{requiresAuth:true},
  // component:PersonView,
  component:()=>import('@/views/Person/PersonView.vue')
},

{
  path:"/personshowview",
  name:"PersonShowView",
  // component:PersonShowView
  component:()=>import('@/views/Person/Show/PersonShowView.vue')
},

{
  path:"/examview",
  name:"ExamView",
  component:()=>import('@/views/exam/ExamView.vue')
},

{
  path:"/examquestionview",
  meta:{requiresAuth:true},
  name:"ExamQuestionView",
  component:()=>import('@/views/exam/question/ExamQuestionView.vue')
},

{
  path:"/examimportview",
  meta:{requiresAuth:true},
  name:"ExamImportView",
  component:()=>import('@/views/exam/import/ExamImportView.vue')
},

{
  path:"/examshowview",
  meta:{requiresAuth:true},
  name:"ExamShowView",
  component:()=>import('@/views/exam/show/ExamShowView.vue')
},

{
  path:"/examrecordview",
  meta:{requiresAuth:true},
  name:"ExamRecordView",
  component:()=>import('@/views/exam/record/ExamRecordView.vue')
},

{
  path:"/examtestview",
  meta:{requiresAuth:true},
  name:"ExamTestView",
  component:()=>import('@/views/exam/test/ExamTestView.vue')
},

{
  path:"/articleview",
  name:"ArticleView",
  meta:{requiresAuth:true},
  // component:ArticleView,
  component:()=>import('@/views/Article/ArticleView.vue')
},
{
  path:"/articlebrowse",
  name:"ArticleBrowseView",
  meta:{requiresAuth:true},
  component:()=>import('@/views/Article/ArticleBrowse/ArticleBrowseView.vue')
},
{
  path:"/articlecontentview",
  name:"ArticleContentView",
  meta:{requiresAuth:true},
  // component:ArticleContentView,
  component:()=>import('@/views/Article/ArticleContent/ArticleContentView.vue')
},
{
  path:"/articleshowview",
  name:"ArticleShowView",
  // component:ArticleShowView,
  component:()=>import('@/views/Article/ArticleShow/ArticleShowView.vue')
},

{
  path:"/articlecommentview",
  name:"ArticleCommentView",
  meta:{requiresAuth:true},
  // component:ArticleCommentView,
  component:()=>import('@/views/Article/ArticleComment/ArticleCommentView.vue')
},

{
  path:"/articleeditview",
  name:"ArticleEditView",
  meta:{requiresAuth:true},
  // component:ArticleEditView,
  component:()=>import('@/views/Article/ArticleEdit/ArticleEditView.vue')
},

{
  path:"/articlecreateview",
  name:"ArticleCreateView",
  meta:{requiresAuth:true},
  // component:ArticleCreateView,
  component:()=>import('@/views/Article/ArticleCreate/ArticleCreateView.vue')
},

{
  path:"/classifyview",
  name:"ClassifyView",
  // component:ClassifyView,
  component:()=>import('@/views/Classify/ClassifyView.vue')
},

{
  path:"/searchview",
  name:"SearchView",
  // component:SearchView,
  component:()=>import('@/views/Search/SearchView')
},

{
  path:"/talkview",
  name:"TalkView",
  component:()=>import('@/views/talk/TalkView')
},

{
  path:"/loginview",
  name:"LoginView",
  // component:LoginView
  component:()=>import('@/views/Login/LoginView.vue')
},

{
  path:"/findpasswordview",
  name: "FindPassword",
  // component:FindPasswordView,
  component:()=>import('@/views/Login/FindPasswordView.vue')
},

{
  path:"/registerview",
  name:"RegisterView",
  // component:RegisterView,
  component:()=>import('@/views/Register/RegisterView.vue')
},

{
  path:"/adminhomeview",
  name:"AdminHomeView",
  component:()=>import('@/views/admin/home/AdminHomeView.vue')
}
  
  // {
  //   path: '/about',
  //   name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
