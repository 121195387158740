import axios from "axios";
import utils from '@/utils/yujianbo-js-utils-1.0.0'

const service = axios.create(
    {
        baseURL:utils.getApiHostPort()+"/article-server/api/", //请求的基础路径
        timeout:8000 //请求的超时时间
    }
)

export default service