import Vue from 'vue'
import Vuex from 'vuex'
import yujianboutils from '@/utils/yujianbo-js-utils-1.0.0'

Vue.use(Vuex)

export default new Vuex.Store({
  /**
   * 储存数据：定义键值对
   * 可以理解为缓存数据库
   */
  state: {
    //键:token，值：localStorage.getItem("token")或 ''(空字符串)
    token:localStorage.getItem("token")||''||yujianboutils.localStorageGetItem("token")
    // token:localStorage.getItem("token")||''
    // token:localStorage.getItem("token")||''||this.$utils.localStorageGetItem("token")
    // token:yujianboutils.localStorageGetToken("token")||''
  },
  /**
   * 暂时用不到
   */
  getters: {
  },
  /**
   * 定义方法，修改state中数据的值
   */
  mutations: {
    /**
     * 对token赋值
     * @param {state缓存数据库} state 
     * @param {自定义的token值} token 
     */
    SET_TOKEN:(state,token)=>{
      //将新的token值赋给state数据库中对应的键
      state.token = token
    }
  },
  /**
   * 定义方法，异步调用mutations中的方法
   * context.commit利用上下文触发mutations某个方法
   * 在vue代码里使用 this.$store.dispatch 触发里面放置token和清除token的方法
   */
  actions: {
    /**
     * 放置token值
     * @param {上下文} context 
     * @param {token} token 
     */
    setToken(context,token){
      context.commit("SET_TOKEN",token)
    },
    /**
     * 清除token
     * @param {上下文} context 
     */
    clearToken(context){
      context.commit("SET_TOKEN",'')
    }
  },
  /**
   * 暂时用不到
   */
  modules: {
  }
})
