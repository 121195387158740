import utils from "@/utils/yujianbo-js-utils-1.0.0"

export default{


    /**
     * 用于登录的图形验证码基础链接地址
     * @returns 图形验证码基础链接地址
     */
    get_image_code_login_src_base(){
        return "/user-server/api/notify/pub/v1/send_login_image_code?image_code_key="
    },

    /**
     * 用于注册的图形验证码基础链接地址
     * @returns 图形验证码基础链接地址
     */
    get_image_code_register_src_base(){
        return "/user-server/api/notify/pub/v1/send_register_image_code?image_code_key="
    },

    /**
     * 用于忘记密码时的图形验证码基础链接地址
     * @returns 图形验证码基础链接地址
     */
    get_image_code_forget_pwd_src_base(){
        return "/user-server/api/notify/pub/v1/send_forget_pwd_image_code?image_code_key="
    },

    /**
     * 上传文章的封面
     * @returns 
     */
    get_article_cover_upload_action(){
        return "/article-server/api/upload/pri/v1/article_cover_img?token="+utils.localStorageGetItem("token")
    },

    /**
     * 上传私有专栏封面图的请求连接地址
     * @returns 
     */
    get_classify_upload_action(){
        return '/article-server/api/upload/pri/v1/classify_cover_img?token='+utils.localStorageGetItem("token")
    },

    /**
     * 上传文章插图的连接地址
     * @returns 上传文章插图的连接地址
     */
    get_upload_image_server(){
        return '/article-server/api/upload/pri/v1/article_content_img?token='+utils.localStorageGetItem("token")
    },

    /**
     * 上传说说说插图
     */
    get_talk_upload_image_server(){
        return '/talk-server/api/upload/pri/v1/talk_content_img?token='+utils.localStorageGetItem("token")
    },

    /**
     * 上传说题目内容插图
     */
    get_exam_question_content_upload_image_server(){
        return '/exam-server/api/upload/pri/v1/question/content/img?token='+utils.localStorageGetItem("token")
    },

    /**
     * 上传说答案内容插图
     */
    get_exam_answer_content_upload_image_server(){
        return '/exam-server/api/upload/pri/v1/answer/content/img?token='+utils.localStorageGetItem("token")
    },

    /**
     * 上传说答案解析插图
     */
    get_exam_answer_analysis_upload_image_server(){
        return '/exam-server/api/upload/pri/v1/answer/analysis/img?token='+utils.localStorageGetItem("token")
    }

}