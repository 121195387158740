<template>
    <div>
        <div class="row"> 
            <div class="col-2"> 
                <el-form>
                    <el-form-item label="">
                        <el-select v-model="editor_classify_basic_id"   placeholder="公共分类">
                            <el-option
                            v-for="classify_basic in editor_classify_basic"
                            :key="classify_basic.id"
                            :label="classify_basic.name"
                            :value="classify_basic.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="col-2"> 
                <el-form>
                    <el-form-item label="">                   
                        <el-select v-model="editor_classify_id" placeholder="我的专栏">                   
                            <el-option
                            v-for="classify in editor_classify"
                            :key="classify.id"
                            :label="classify.name"
                            :value="classify.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="col-2"> 
                <el-form>
                    <el-form-item label="">
                        <el-input @keyup.enter.native="search" v-model="editor_big_title" placeholder="关键词"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="col-2"> 
                <el-form>
                    <el-button @click="search" type="danger" plain>搜索</el-button>
                </el-form>
            </div>
            <div class="col-2"> 

            </div>

        </div>
            <div class="infinite-list" 
                v-infinite-scroll="load"  
                infinite-scroll-distance="1"  
                style="overflow-y:scroll;
                height:600px"> 
                <div class="row" v-for="article in article_list" :key="article"> 
                    <div class="row"> 
                        <div class="col-8" style="word-break:break-all"> 
                            <h6 v-html="article.big_title"></h6>
                            <p></p>
                        </div>
                        <div class="col-2"> 

                        </div>
                        <div class="col-2" style="text-align:right"> 
                            <small style="color:darkgrey">{{article.published_date_time}}</small>
                        </div>
                    </div>
                    <div class="row"> 
                        <div class="col-2">                 
                            <small v-if="article.publiced==0&&article.published==0&&article.deleted==0" style="font-size:12px;color:darkgray"><p>草稿</p></small>
                            <small v-if="article.publiced==1&&article.published==1&&article.status==0&&article.deleted==0" style="font-size:12px;color:darkgray"><p>公开</p></small>
                            <small v-if="article.publiced==0&&article.published==1&&article.deleted==0" style="font-size:12px;color:darkgray"><p>私有</p></small>                   
                            <small v-if="article.status==2" style="font-size:12px;color:darkgray"><p>审核中</p></small>
                            <small v-if="article.deleted==1" style="font-size:12px;color:darkgray"><p>回收站</p></small>
                        </div>

                    </div>
                    <div class="row"> 
                        <div class="col-3"> 
                            <small style="color:darkgrey"><p>展现量 0 &nbsp;阅读 0 评论 {{article.viewed}} 收藏 0</p></small>
                        </div>
                        <div class="col-6"> 

                        </div>
                        <div class="col-3" style="font-size:14px;text-align: right;"> 
                            <el-button @click="toEdit(article.id)" type="primary" icon="el-icon-edit" size="small" circle></el-button>
                            <el-button @click="toShow(article.id)" type="success" icon="el-icon-document" size="small"  circle></el-button>
                            <el-button @click="toDelete(article.id)" type="danger" icon="el-icon-delete" size="small" circle></el-button>
                        </div>
                    </div>
                    <hr/>
                </div>
            </div>
        </div>
</template>

<script>

import {getClassifyBasic, getClassifyByUser} from '@/api/article-server/getData'
import { deleteArticleByArticleId,recoveryArticleByArticleId } from '@/api/article-server/saveData'


export default{

data(){
    return{
        editor_classify_basic_id:'',
        editor_classify_id:'',
        editor_big_title:'',
        editor_classify:'',
        editor_classify_basic:'',
    }
},
props:{
    article_list:{
        type:Object,
        required:true
    },
}
,
methods:{

        load(){
            this.$emit("refreshArticle")
        },

        search(){
            this.article_list=''
            this.$emit("searchArticle",this.editor_classify_basic_id,this.editor_classify_id,this.editor_big_title);
        },

        /**
         * 获取公共分类
         */
        async getClassifyBasic(){
            const result = await getClassifyBasic();
            if(result.data.code==0){
                this.editor_classify_basic = result.data.data
            }
        },

        /**
         * 获取私有分类
         */
        async getClassifyByUser(){
            const result = await getClassifyByUser(this.$utils.localStorageGetItem("user_id"));
            if(result.data.code==0){
                this.editor_classify = result.data.data;
            }
        },

        async deleteArticleByArticleId(article_id){
            const result = await deleteArticleByArticleId(article_id)
            if(result.data.code==0){
            alert("删除成功")
            this.$emit("refreshAll")
            }
        },

        async recoveryArticleByArticleId(article_id){
            const result = await recoveryArticleByArticleId(article_id)
            if(result.data.code==0){
            alert("恢复成功")
            }
        },
        /**
         * 前往文章的内容页面
         * @param {文章的id} article_id 
         */
        toShow(article_id){
            this.$router.push(
                {
                path:'/articleshowview',
                query:{
                    user_id:this.$utils.localStorageGetItem('user_id'),
                    id:article_id
                }
                }
            )
        },
        /**
         * 前往文章的编辑页面
         * @param {文章的id} article_id 
         */
        toEdit(article_id){
            this.$router.push(
                {
                path:'/articleeditview',
                query:{
                    id:article_id
                }
                }
            )
        },
        toDelete(article_id){
            this.deleteArticleByArticleId(article_id);
        }
},
created(){
    this.getClassifyByUser();
    this.getClassifyBasic();
}


}

</script>


<style lang="scss" scoped>

::v-deep(em){
  font-style: normal;
  color: #b84d4d;
}

</style>                       