<template>

  <el-row>
    <el-row v-loading="loading" >
      <el-col span=2 style="text-align: center;">
        <!-- <el-checkbox  v-model="allChecked" >全选&nbsp;&nbsp;&nbsp;&nbsp;</el-checkbox> -->
<!--        <input type="checkbox" id="all" @click="allChecked"/><small>全选</small>-->
      </el-col>
      <el-col span=20 class="layout-el-col-blank">
      </el-col>
      <el-col span=2>
        <!-- <small style="color:dodgerblue">一键公开</small> -->
      </el-col>
    </el-row>
    <br/>
    <el-col span=24 v-for="(comment,index) in article_comments_user_verify" :key="index">
      <el-row>
        <el-col span=1 style="text-align: center">
          <!-- <el-checkbox  v-model="oneChecked" ></el-checkbox> -->
<!--              <input type="checkbox" name="one"/>-->
        </el-col>
        <el-col span=2 style="text-align: center">
          <img :src="comment.userDO.head_img" class="img-circle-60"/>
        </el-col>
        <el-col span=16>
          <el-row>
            <el-col span=24>
              <p class="text-muted">

                <small v-if="comment.from_id!=comment.user_id" style="color: black">
                  {{comment.userDO.name}}
                </small>
                <small v-else style="color: black">
                  你自己
                </small>
                &nbsp;&nbsp;
                <small>评论了&nbsp;&nbsp;你的文章</small>
              </p>
            </el-col>
          </el-row>
          <el-row>
            <el-col span=24>
              <h6>{{comment.content}}</h6>
            </el-col>
          </el-row>
          <el-row>
            <el-col span=24>
              <small class="text-muted">{{comment.article_title}}</small>
            </el-col>
          </el-row>
        </el-col>
        <el-col span=4>
          <small class="text-muted">{{comment.create_time}}</small>
        </el-col>
      </el-row>
      <hr/>
    </el-col>
  </el-row>
</template>

<script>

export default {
  data(){
    return{
      loading:true,
      allChecked:false,
      oneChecked:false,
    }
  },
  props:{
    article_comments_user_verify:{
      type:Object,
      required:true
    }
  },
  watch:{
    article_comments_user_verify(){
      this.loading=false
    },
    allChecked(){
      if(this.allChecked==true){
        this.oneChecked=true;
      }else {
        this.oneChecked=false;
      }
    }
  },
  methods:{

    AllChecked(){

    }

    // allChecked(){
    //   var arr = document.getElementsByName("one");
    //   if(document.getElementById("all").checked == true)
    //     for(var i = 0;i < arr.length;i++) {
    //       arr[i].checked = true;
    //     }
    //   else {
    //     for(var i = 0;i < arr.length;i++) {
    //       arr[i].checked = false;
    //     }
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>


</style>