<template>
    <div>
        <el-row> 
            <el-col span=24>
                <div class="card text-dark bg-light mb-3 layout-scroll-parent" style="width: 100%;height: auto;background-color: white;" >                        
                    <div class="card-body" style="position:relative;top:-5px">
                        <el-tabs v-model="activeName" @tab-click="handleClick">

                            <el-tab-pane name="forth"> 
                                <span slot="label" style="font-size: 16px;font-weight: normal;"> 
                                    动态
                                </span>
                                <el-row> 
                                    <el-col class="infinite-list" 
                                        v-infinite-scroll="load4"  
                                        infinite-scroll-distance="1"  
                                        style="overflow-y:scroll;height:555px"> 

                                        <el-row v-for="talk,index in talk_list" :key="index"> 
                                            <el-col span="24" > 
                                                <!-- 用户信息 -->
                                                <el-row> 
                                                    <el-col :span="2"> 
                                                        <router-link :to="{path:'/personshowview',query:{user_id:talk.user_id}}">
                                                            <img :src="talk.user_head_img" class="img-circle-50"/>
                                                        </router-link>
                                                    </el-col>
                                                    <el-col span="17" style="text-align: left;"> 
                                                        <a style="color:black;font-weight:bolder">{{ talk.user_name }}</a>
                                                        &nbsp;&nbsp;
                                                        <small style="color:red;font-weight: bolder;">Lv{{ talk.user_level }}</small>
                                                        <p><small class="text-muted">{{ talk.create_time }}</small></p>
                                                    </el-col>
                                                    <el-col span="5"> 
                                                        <!-- <small>{{ talk.create_time }}</small> -->
                                                    </el-col>
                                                </el-row>
                                                <!-- 说说内容 -->
                                                <el-row style="text-align: left;">
                                                    <el-col :span="2" class="layout-el-col-blank">
                                                    </el-col>
                                                    <el-col :span="22" style="font-size:14px" class="content_img"> 
                                                        <p></p>
                                                        <p v-html="talk.content"></p>
                                                    </el-col>
                                                </el-row>
                                                <hr/>
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>

                            <el-tab-pane  name="first">                                
                                <span slot="label" style="font-size:16px;font-weight: normal;">
                                    最近
                                </span>
                                <el-row>
                                    <el-col> 
                                        <div 
                                            class="infinite-list" 
                                            v-infinite-scroll="load1"  
                                            infinite-scroll-distance="1"  
                                            style="overflow-y:scroll;height:555px"
                                            >
                                            <el-row v-for="(article,index) in article_list" :key="index">
                                                <router-link :to="{path:'/articleshowview',query:{id:article.id,user_id:UserDetail.id}}"> 
                                                    <el-col>
                                                        <el-row>
                                                           <el-col span=12 style="text-align:left"> 
                                                                <el-row> 
                                                                    <el-col span=24> 
                                                                        <h6>{{article.big_title}}</h6>
                                                                    </el-col>
                                                                </el-row>
                                                                <el-row> 
                                                                    <el-col span=24> 
                                                                        <small>{{article.summary}}</small>
                                                                    </el-col>
                                                                </el-row>
                                                                <p></p>
                                                                <el-row> 
                                                                    <el-col span=24> 
                                                                        <small class="text-muted">发布日期：{{article.published_date_time}}&nbsp;&nbsp;&nbsp;阅读量：{{article.viewed}}</small>
                                                                    </el-col>
                                                                </el-row>                                                                
                                                            </el-col>
                                                            <el-col span=12>
                                                                <a v-if="article.cover_vo!=null"> 
                                                                    <a  v-for="url,index in article.cover_vo.pics" :key="index">
                                                                        <el-image style="width: 110px; height: 90px" :src="url"></el-image>                            
                                                                        &nbsp;
                                                                    </a>
                                                                </a>
                                                            </el-col>                                                    
                                                        </el-row>
                                                        <hr style="color:darkgray"/>
                                                    </el-col>
                                                    
                                                </router-link>
                                            </el-row>

                                        </div>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane  name="second">
                                <span slot="label" style="font-size:16px;font-weight: normal;">
                                    全部
                                </span>
                                <el-row>
                                    <el-col> 
                                        <div 
                                            class="infinite-list" 
                                            v-infinite-scroll="load2"  
                                            infinite-scroll-distance="1"  
                                            style="overflow-y:scroll;height:555px"
                                            >
                                            <el-row v-for="article,index in article_list" :key="index"> 
                                                <router-link :to="{path:'/articleshowview',query:{id:article.id,user_id:UserDetail.id}}"> 
                                                    <el-col span=24 style="text-align:left"> 
                                                        <el-row> 
                                                            <el-col span=24> 
                                                                <h6>{{article.big_title}}</h6>
                                                            </el-col>
                                                        </el-row>
                                                        <el-row> 
                                                            <el-col span=24> 
                                                                <small>{{article.summary}}</small>
                                                            </el-col>
                                                        </el-row>
                                                        <p></p>
                                                        <el-row> 
                                                            <el-col span=24> 
                                                                <small class="text-muted">发布日期：{{article.published_date_time}}&nbsp;&nbsp;&nbsp;阅读量：{{article.viewed}}</small>
                                                            </el-col>
                                                        </el-row>
                                                        <hr style="color:darkgray"/>
                                                    </el-col>    
                                                </router-link>                                                                                   
                                            </el-row>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane name="third">
                                <span slot="label" style="font-size:16px;font-weight: normal;">
                                    专栏
                                </span>
                                <el-row>
                                    <el-col span=24>
                                        <div 
                                            class="infinite-list" 
                                            v-infinite-scroll="load3"  
                                            infinite-scroll-distance="1"  
                                            style="overflow-y:scroll;height:555px"
                                            >
                                            <el-row v-for="classify,index in classify_list" :key="index">
                                                <router-link :to="{path:'/classifyview',query:{user_id:UserDetail.id,classify_id:classify.id}}">
                                                <el-col span=3 style="text-align: center">
                                                    <img :src="classify.cover_img"  style="width:70px;height: 70px"/>
                                                </el-col>
                                                <el-col span=21 style="text-align:left">
                                                        <el-row> 
                                                            <el-col span=24> 
                                                                <h6>{{classify.name}}</h6>
                                                            </el-col>
                                                        </el-row>
                                                        <el-row> 
                                                            <el-col span=24> 
                                                                <small>{{classify.summary.substring(0,80)}}……</small>
                                                            </el-col>
                                                        </el-row>
                                                        <p></p>
                                                        <el-row> 
                                                            <el-col span=24> 
    <!--                                                            <small class="text-muted">创建日期：{{classify.create_time}}</small>-->
                                                            </el-col>
                                                        </el-row>
                                                    <hr style="color:darkgray"/>
                                                    </el-col>
                                                </router-link>
                                            </el-row>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="资源" name="fourth">
                                <span slot="label" style="font-size:16px;font-weight: normal;">
                                    评论
                                </span>
                                按时间倒序的评论
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>               
            </el-col>
        </el-row>        
    </div>
</template>

<script>
import { getClassifyByUser,getArticleNewByUser,getArticlesPublicByUserShow } from '@/api/article-server/getData';
import { get_talk_by_user_public } from '@/api/talk-server/getData';

export default{

    data() {
      return {
        activeName: 'forth',
        classify_list:'',
        article_list:'',
        talk_list:'',
        page:1,
        size:10,
        total_page:0
      };
    },

    props:{
        UserDetail:{
            type:Object,
            required:true
        }
    },

    methods: {



        load1(){
            if(this.page<this.total_page){
                this.page+=1;
                this.loadNew();
            }
        },
                
        load2(){
            if(this.page<this.total_page){
                this.page+=1;
                this.loadPublic();
            }
        },

        load3(){
            if(this.page<this.total_page){
                this.page+=1;
                this.loadClassify();
            }
        },

        load4(){
            if(this.page<this.total_page){
                this.page+=1
                this.loadTalk();
            }
        },

        async loadNew(){
            const result = await getArticleNewByUser(this.$route.query.user_id,this.page,this.size,-1)
            if(result.data.code==0){
                this.article_list = this.article_list.concat(result.data.data.current_data)
            }
        },

        async loadPublic(){
            const result = await getArticlesPublicByUserShow(this.$route.query.user_id,this.page,this.size)
            if(result.data.code==0){
                this.article_list=this.article_list.concat(result.data.data.current_data)
            }
        },
        
        async loadClassify(){
            const result = await getClassifyByUser(this.$route.query.user_id,this.page,this.size)
            if(result.data.code==0){
                this.classify_list = this.classify_list.concat(result.data.data.current_data)
            }
        },
        
        async loadTalk(){
            const result = await get_talk_by_user_public(this.$route.query.user_id,this.page,this.size)
            if(result.data.code==0){
                this.talk_list = this.talk_list.concat(result.data.data.current_data)
            }
        },

        handleClick(tab, event) {
            console.log(tab, event);
            if(tab.name=='first'){
                this.page=1
                this.article_list=''
                this.getArticleNewByUser();
            }
            if(tab.name=='second'){
                this.page=1
                this.article_list=''
                this.getArticlesPublicByUser();
            }
            if(tab.name=='third'){
                this.page=1
                this.getClassifyByUser();
            }
            if(tab.name=='forth'){
                this.page=1
                this.getTalkByPublicUser();
            }
        },
        async getClassifyByUser(){
            const result = await getClassifyByUser(this.$route.query.user_id,this.page,this.size)
            if(result.data.code==0){
                this.classify_list = result.data.data.current_data
                this.total_page = result.data.data.total_page
            }
        },
        async getArticleNewByUser(){
            const result = await getArticleNewByUser(this.$route.query.user_id,this.page,this.size,-1)
            if(result.data.code==0){
                this.article_list = result.data.data.current_data
                this.total_page = result.data.data.total_page
            }
        },
        async getArticlesPublicByUser(){
            const result = await getArticlesPublicByUserShow(this.$route.query.user_id,this.page,this.size)
            if(result.data.code==0){
                this.article_list = result.data.data.current_data
                this.total_page = result.data.data.total_page
            }
        },

        async getTalkByPublicUser(){
            const result = await get_talk_by_user_public(this.$route.query.user_id,this.page,this.size)
            if(result.data.code==0){
                this.talk_list = result.data.data.current_data
                this.total_page = result.data.data.total_page
            }
        }
    },

    created(){
        this.getArticleNewByUser();
        this.getTalkByPublicUser();
    }

}

</script>


<style lang="scss" scoped>

a{
    text-decoration: none;
    color: black;
}

.content_img ::v-deep img{
    max-width: 15%;
    max-height: auto;
    margin-left: 5px;
    margin-top:5px;
}
</style>