<template>
    <div class="head-bg">
        <div class="row head-search"> 
            <div class="col-6">
                <div class="input-group mb-3 ">
                    <button @click="toSearch" class="btn btn-outline-secondary head-search-button"  type="button" id="button-addon1">
                        <b-icon icon="search" variant="warning"></b-icon>
                    </button>
                    <input @keyup.enter="toSearch" v-model="big_title" type="text" class="form-control head-search-input" placeholder="输入关键词搜索……" aria-label="Example text with button addon" aria-describedby="button-addon1">
                </div>
            </div>
        </div>
    </div>
</template>

<script>



export default{
data() {
    return {
        head_img:'',
        big_title: ''
    }
},


 
created(){
    this.head_img = this.$utils.localStorageGetItem('user_head_img')
},
mounted(){
 
},
methods:{
  toSearch(){
    this.$router.push(
        {
          name: "SearchView",
          params:{
            "big_title":this.big_title
          }
        }
    )
  }
}
}

</script>


<style lang="scss" scoped>



.head-bg{
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-image: url(https://blog-pc-front.oss-cn-beijing.aliyuncs.com/logo/home_head_03.png);
    background-size: 100% 100%;  

}


.head-search{
    // position: sticky;
    margin-left: 35%;
    // margin-top: 50px;
    // top: 0;   
}
.input-group{
    position:relative;
    top: 200px;
}
.head-search-input{
   border-radius: 40px; 
   width: 80%;//给另一个元素按钮留出20%空间
}
.head-search-button{
   border-radius: 40px; 
   background-color: #ffffff;
   border-top-color: #ffffff;
   border-bottom-color: #ffffff;
   border-left-color: #ffffff;
   border-right-color: #ffffff;
}



</style>