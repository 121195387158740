<template>
    <div class="copyright-bg">
       
        <div class="row one-bg"> 
            <div class="col-3"> 

            </div>
            <div class="col-6 text-secondary" style="font-size:14px"> 
                <!-- <small> -->
                    <p></p>
                    网站介绍&nbsp;&nbsp;&nbsp;&nbsp;
                    关于站长&nbsp;&nbsp;&nbsp;&nbsp;
                    商务合作&nbsp;&nbsp;&nbsp;&nbsp;
                    1638319057@qq.com&nbsp;&nbsp;&nbsp;&nbsp;
                    QQ讨论群&nbsp;&nbsp;&nbsp;&nbsp; 
                    使用条款&nbsp;&nbsp;&nbsp;&nbsp;
                    用户体验/调查问卷
                <!-- </small> -->
            </div>
            <div class="col-3"> 

            </div>
            
        </div>

        <div class="row two-bg"> 
            <div class="col-1"> 

            </div>
            <div class="col-10 text-secondary" style="font-size:13px"> 
                <small>
                    <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备18013055号-3&nbsp;&nbsp;&nbsp;&nbsp;</a>
                    北京互联网违法和不良信息举报中心&nbsp;&nbsp;&nbsp;&nbsp;
                    家长监护&nbsp;&nbsp;&nbsp;&nbsp;
                    网络110报警服务&nbsp;&nbsp;&nbsp;&nbsp;
                    中国互联网举报中心&nbsp;&nbsp;&nbsp;&nbsp;
                    版权与免责声明&nbsp;&nbsp;&nbsp;&nbsp;
                </small>
            </div>
            <div class="col-1"> 

            </div>
        </div>
        <div class="row"> 
            

        </div>
    </div>
</template>

<script>



export default{



}

</script>


<style lang="scss" scoped>

.copyright-bg{
    background-color: #ffffff;
}

.one-bg{
    margin-top: 20px;
    margin-bottom: 10px;
    
}

.two-bg{
    margin-bottom: 20px;
}

</style>