<template>
  <el-row>
    <el-col span=24>
        <el-row>
          <el-col span=24>
            <head-view :UserDetail="UserDetail"></head-view>
          </el-col>
        </el-row>
        <br/>
        <br/>
        <br/>
        <el-row>
          <el-col span=6 class="layout-el-col-blank"></el-col>
          <el-col span=12>
            <h3>找回密码</h3>
          </el-col>
          <el-col span=6></el-col>
        </el-row>
        <br/>
      <br/>
        <el-row>
          <el-col span=6 class="layout-el-col-blank"></el-col>
          <el-col span=12>
            <el-steps :active="active"  finish-status="success">
              <el-step title="步骤一"></el-step>
              <el-step title="步骤二"></el-step>
              <el-step title="步骤三"></el-step>
<!--              <el-step title="步骤四"></el-step>-->
            </el-steps>
            <el-row>
              <el-col span=24 v-if="showStep1">
                <br/>
                <br/>
                <el-row>
                  <el-col span=6 class="layout-el-col-blank"></el-col>
                  <el-col span=10>
                    <el-form >
                      <el-row>
                        <el-col span=18>
                          <el-form-item  label="找回账号" label-width="100px">
                            <el-input v-model="find_phone"   placeholder="手机号"/>
                          </el-form-item>
                        </el-col>
                        <el-col span=6 >
                            <small style="color:#b84d4d;" v-show="find_phone_error_show">手机号不存在</small>
                        </el-col>
                      </el-row>
                    </el-form>
                  </el-col>
                  <el-col span=8></el-col>
                </el-row>
                <el-row>

                </el-row>
              </el-col>
              <el-col span=24 v-if="showStep2">
                <br/>
                <br/>
                <el-row>
                  <el-col span=8 class="layout-el-col-blank"></el-col>
                  <el-col span=8>
                    <el-form>
                      <el-form-item >
                        <el-input v-model="find_phone" disabled placeholder="找回的账号"/>
                      </el-form-item>
                      <el-form-item >
                        <el-input type="password"  v-model="find_phone_pwd" placeholder="请输入新密码" />
                      </el-form-item >
                      <el-form-item >
                        <el-input type="password" v-model="find_phone_pwd2" placeholder="确认新密码" />
                      </el-form-item>
                      <el-form-item >
                        <div class="input-group mb-3 ">
                          <input v-model="find_phone_code" type="text" class="form-control head-search-input" placeholder="6位数字验证码" aria-label="Example text with button addon" aria-describedby="button-addon1">
                          <button :disabled="isShowPhoneCodeButtonDisabled"  @click="sendSms" class="btn btn-outline-secondary head-search-button"  type="button" id="button-addon2">
                            <a v-if="isShowPhoneCodeButton">获取手机验证码</a>
                            <a v-else> {{phone_code_seconds}}秒后再次发送</a>
                          </button>
                        </div>
                      </el-form-item>
                      <el-form-item>
                        <div class="input-group mb-3 ">
                          <input type="text" class="form-control head-search-input" v-model="image_code" placeholder="4位图形验证码" aria-label="Example text with button addon" aria-describedby="button-addon1">
                          <img v-if="isShowFindPwdImageCode" @click="getCaptcha" ref="captcha" style="width:40%;" :src="image_code_src"/>
                          <button v-else @click="deleteFindPwdGetCaptchaButton" class="btn btn-outline-secondary head-search-button"  type="button" id="button-addon1">
                            获取图形验证码
                          </button>
                        </div>
                      </el-form-item>

                    </el-form>
                  </el-col>
                  <el-col span=8></el-col>
                </el-row>
                <el-row>

                </el-row>
              </el-col>
              <el-col span=24 v-if="showStep3">
                <br/>
                <br/>
                <el-row>
                  <el-col span=6 class="layout-el-col-blank"></el-col>
                  <el-col span=10>
                    <el-form>
                      <el-form-item label="找回的账号"  label-width="100px">
                        <el-input v-model="find_phone" disabled/>
                      </el-form-item>
                      <el-form-item label="">
<!--                        <el-button type="success"  round>密码找回成功！！</el-button>-->
                        密码找回成功，点击下一步进入登录页，或者<router-link :to="{path:'/loginview'}">由此进入登录页-></router-link>
                      </el-form-item>
                    </el-form>
                  </el-col>
                  <el-col span=8></el-col>
                </el-row>
                <el-row>

                </el-row>
              </el-col>
              <el-col span=24 v-if="showStep4">
                <br/>
                <br/>
                <el-row>
                  <el-col span=6 class="layout-el-col-blank"></el-col>
                  <el-col span=10>
                    <el-form>
                      <el-form-item label="找回的账号"  label-width="100px">
                        <el-input v-model="find_phone" disabled />
                      </el-form-item>
                    </el-form>
                  </el-col>
                  <el-col span=8></el-col>
                </el-row>
                <el-row>

                </el-row>
              </el-col>
            </el-row>
            <el-button style="margin-top: 12px;" @click="next2">下一步</el-button>
          </el-col>
          <el-col span=6 class="layout-el-col-blank"></el-col>
        </el-row>
    </el-col>
  </el-row>
</template>

<script>
import HeadView from "@/components/HeadView"
import {isExistPhone, sendForgetPwdSms} from "@/api/user-server/checkData";
import {getImageCodeKey} from "@/api/user-server/getData";
import {findPwd} from '@/api/user-server/saveData'

export default {

  components:{
    HeadView
  },

  data(){
    return{
      UserDetail:'',



      //第二步
      //手机
      phone_code_seconds:120,               //两次手机验证码间隔秒数
      find_phone_code:'',                   //手机验证码
      find_phone_pwd:'',                    //手机密码
      find_phone_pwd2:'',                   //手机密码确认
      isShowPhoneCodeButton:true,           //是否显示获取手机验证码按钮
      isShowPhoneCodeButtonDisabled:false,  //是否禁用获取手机验证码按钮
      error_tip:'',

      //图片
      image_code_key:'',                    //图片验证码的key
      image_code:'',                        //图片验证码
      image_code_src:'',                    //图片验证码的请求链接


      image_code_src_base:this.$utils.getApiHostPort()+this.$url.get_image_code_forget_pwd_src_base(),

      isShowFindPwdImageCode:false,//是否显示图片验证码
      //第一步
      find_phone_error_show:false,
      find_phone:'',
      //步间跳转
      active: 0,
      showStep1:true,
      showStep2:false,
      showStep3:false,
      showStep4:false,

    }
  },

  watch:{
    find_phone(){
      this.isExistPhone(this.find_phone)
    }
  },

  methods: {

    next2(){
      if(this.active===0){
        if(this.find_phone===null||this.find_phone===''){
          alert("账号不能为空")
        }else {
          if(!this.find_phone_error_show){
            this.showStep(2)
            this.active++;
          }else{
            alert("请输入正确的手机号")
          }
        }
      }
      else if(this.active===1){
        this.forgetPwdUpdate();
        // if(this.error_tip===""){
        //   this.showStep(3)
        //   this.active+=2;
        // }else {
        //   alert("错误")
        // }
      }
      else if(this.active===3){
        this.$router.push("/loginview")
      }

    },



    /**
     * 根据具体步骤索引显示指定步骤标签
     */
    showStep(active){
      if(active==0){
        this.stepSetFalse();
      }else if(active==1){
        this.stepSetFalse();
        this.showStep1=true;
      }else if(active==2){
        this.stepSetFalse();
        this.showStep2=true;
      }else if(active==3){
        this.stepSetFalse();
        this.showStep3=true;
      }else if(active==4){
        this.stepSetFalse();
        this.showStep4=true;
      }
    },

    /**
     * 不显示所有步骤标签
     */
    stepSetFalse(){
      this.showStep1=false;
      this.showStep2=false;
      this.showStep3=false;
      this.showStep4=false;
    },







    /**
     *第一步，验证账号（手机号）是否存在
     */
    async isExistPhone(phone){
      const result = await isExistPhone(phone)
      if(result.data.code==0){
        this.find_phone_error_show=false
      }else {
        this.find_phone_error_show=true;
      }
    },

    /**
     * 第二步
     * 发送短信间隔
     */
    sendSms(){
      //判断手机号长度length、手机号是否为数字Number("",10)、手机号是否为空isNaN()
      if(this.find_phone.length==11 && !isNaN(Number(this.find_phone,10))){
        sendForgetPwdSms(this.find_phone).then(
            res=>{
              if(res.data.code==0){
                //不显示获取验证码
                this.isShowPhoneCodeButton=false
                //禁止操作获取验证码的按钮
                this.isShowPhoneCodeButtonDisabled=true
                //显示提示注册的错误信息
                // this.register_error_show=true
                //将注册错误信息赋值
                // this.register_error=res.data.msg
                //使用计时器缩减时间间隔
                this.phone_code_seconds_();

              }else{
                // this.register_error = res.data.msg
                // this.register_error_show = true
              }
            }
        )

      }
      //否则，弹出提示信息
      else{
        alert("手机号不正确！！")
      }
    },

    /**
     * 第二步
     * 递减发送短信时间间隔
     */
    phone_code_seconds_(){
      //定义计时器
      var interval = setInterval(()=>{
        this.phone_code_seconds--;
        if(this.phone_code_seconds==0){
          //显示获取验证码
          this.isShowPhoneCodeButton=true
          //允许点击发送验证码按钮
          this.isShowPhoneCodeButtonDisabled=false
          //将手机验证码时间间隔重置为 120 秒
          this.phone_code_seconds=120
          //关闭计时器
          clearInterval(interval)
        }
        //时间间隔1秒
      },1000)
    },


    /**
     * 第二步
     * 删除获取图片验证码的按钮
     */
    deleteFindPwdGetCaptchaButton(){
      //图形验证码基础链接地址 拼接key
      this.image_code_src = this.image_code_src_base + this.image_code_key
      //显示图形验证码
      this.isShowFindPwdImageCode = true
    },

    /**
     * 第二步
     * 动态刷新图形验证码
     * @param {点击事件} event
     */
    getCaptcha(event) {
      //重置图片源地址
      this.$refs.captcha.src = this.image_code_src_base+this.image_code_key+'&time='+Date.now()
    },

    /**
     * 第二步
     * 从服务器获取图形验证码的key
     */
    async getImageCodeKeyFromServer(){
      const result  = await getImageCodeKey();
      if(result.data.code==0){
        this.image_code_key = result.data.data
      }
    },

    /**
     *
     */
    async forgetPwdUpdate(){
      if(this.find_phone_pwd===this.find_phone_pwd2){
        const result = await findPwd(this.find_phone,this.find_phone_code,this.find_phone_pwd,this.image_code_key,this.image_code)
        if(result.data.code===0){
          this.error_tip=''
          this.showStep(3)
          this.active+=2;
        }else {
          alert(result.data.msg)
          this.error_tip = result.data.msg
        }
      }else {
        alert("两次输入的密码不一致")
        this.error_tip="两次输入的密码不一致"
      }
    }





  },

  created() {
    this.UserDetail={"user_head_img":this.$utils.localStorageGetItem("user_head_img")}
    this.getImageCodeKeyFromServer();
  }

}

</script>

<style scoped lang="scss">

</style>