<template>
  <el-row>

    <el-row v-loading="loading">
<!--      <el-col span=2 style="text-align: center;">-->
<!--&lt;!&ndash;        <el-checkbox  v-model="allChecked" >全选&nbsp;&nbsp;&nbsp;&nbsp;</el-checkbox>&ndash;&gt;-->
<!--        &lt;!&ndash;        <input type="checkbox" id="all" @click="allChecked"/><small>全选</small>&ndash;&gt;-->
<!--      </el-col>-->
<!--      <el-col span=20 class="layout-el-col-blank">-->
<!--      </el-col>-->
<!--      <el-col span=2>-->
<!--        <small style="color:dodgerblue">一键公开</small>-->
<!--      </el-col>-->
    </el-row>
    <br/>

    <el-col span=24 v-for="(comment,index) in article_comments_to_other" :key="index">
      <el-row>
<!--        <el-col span=2 style="text-align: center">-->
<!--          <img :src="comment.userDO.head_img" class="img-circle-60"/>-->
<!--        </el-col>-->
        <el-col span=18>
          <el-row>
            <el-col span=24>
              <p class="text-muted">
                <small>你评论了&nbsp;&nbsp;</small>
                <small v-if="comment.from_id!=comment.user_id" style="color: black">
                  {{comment.userDO.name}}
                </small>
                <small v-else style="color: black">
<!--                  {{comment.userDO.name}}-->自己
                </small>
                &nbsp;&nbsp;
                <small>的文章</small>
              </p>
            </el-col>
          </el-row>
          <el-row>
            <el-col span=24>
              <h6>{{comment.content}}</h6>
            </el-col>
          </el-row>
          <el-row>
            <el-col span=24>
              <small class="text-muted">{{comment.article_title}}</small>
            </el-col>
          </el-row>
        </el-col>
        <el-col span=4>
          <small class="text-muted">{{comment.create_time}}</small>
        </el-col>
      </el-row>
      <hr/>
    </el-col>
  </el-row>
</template>

<script>

export default {
  data(){
    return{
      loading:true
    }
  },
  props:{
    article_comments_to_other:{
      type:Object,
      required:true
    }
  },
  watch:{
    article_comments_to_other(){
      this.loading=false
    }
  }
}
</script>

<style lang="scss" scoped>


</style>