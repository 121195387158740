<template>
<div >
    <br>
    <el-card class="box-card" >
    <div class="right" ref="pronbit" >                    
            <div class="row menu text-center-vh-20" :style="style"> 
                <div class="col-1"> 
                    <el-button @click="getArticleListStart(1,8)"   type="text">综合/推荐</el-button>
                </div>
                <div class="col-9"> 
                    <marquee direction="left" behavior="scroll" style="color:red;font-size: medium;height: 20px;">
                        欢迎来到小情书，在新的一年里，小情书祝福大家心想事成，万事如意
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        欢迎来到小情书，在新的一年里，小情书祝福大家心想事成，万事如意
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        欢迎来到小情书，在新的一年里，小情书祝福大家心想事成，万事如意
                    </marquee>
                </div>
                <div class="col-2"> 
                    <el-dropdown>
                        <span class="el-dropdown-link">
                            更多分类
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <div class="col-12" v-for="classifyBasic in ClassifyBasic" :key="classifyBasic">
                                <el-dropdown-item @click.native="getArticleListByClassifyBasicStart(classifyBasic.id)">
                                    &nbsp;&nbsp;&nbsp;{{classifyBasic.name}}&nbsp;&nbsp;&nbsp;
                                </el-dropdown-item>
                            </div>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <hr/>
            </div>
            <div class="load"> 
                <b-spinner  v-if="isShowLoadSpinner" style="position:relative;top:260px" variant="primary" label=""></b-spinner>
            </div>                    
            <div class="layout-scroll-parent" >
                <div class="row summary">

                    <div  class="col-12 infinite-list" v-infinite-scroll="load" style="overflow-y:scroll;height:1100px">                
                        <!-- 卡片 -->
                    <div class="row" v-for="(article,index) in ArticleList" :key="index">
                        <!-- 用户信息 -->
                        <div class="row"  style="font-size:15px"> 
                            <div class="col-1" > 
                                <router-link :to="{path:'/personshowview',query:{user_id:article.user_vo.id}}">
                                    <img :src="article.user_vo.head_img" class="img-circle-50"/>
                                </router-link>
                            </div>
                            <div class="col-3 text-left-h"> 
                                <router-link :to="{path:'/personshowview',query:{user_id:article.user_vo.id}}">
                                    <b style="color:black">{{article.user_vo.name}}</b>
                                </router-link>
                                &nbsp;
                                <a style="color:chocolate">Lv{{ article.user_vo.level }}</a>
                                <p>
                                    <small>{{article.create_time}} </small>
                                </p>
                            </div>
                            <div class="col-1"> 
                                
                            </div>
                            <div class="col-6"> 
                                
                            </div>
                        </div>
                        <!-- 文章信息 -->

                        <el-row>
                            <el-col span=2 class="layout-el-col-blank">

                            </el-col>
                            <el-col span=22>
                                <el-row>
                                    <el-col span=12  v-if="Object.keys(article).includes('cover_vo')&&article.cover_vo.pics.length==3">
                                        <p class="text-left-h" style="color:coral;font-size:15px;">
                                            <router-link :to="{name:'ArticleShowView',path:'/articleshowview',query:{id:article.id,user_id:article.user_id}}">
                                                <p style="color:#EB7340;font-weight: bold;">#{{article.big_title}}#</p>
                                            </router-link>
                                        </p>
                                        <p style="text-align:left;color: black;font-size: 14px;" >
                                            {{article.summary}}
                                        </p>                                        
                                    </el-col>
                                    <el-col span=16  v-else-if="Object.keys(article).includes('cover_vo')&&article.cover_vo.pics.length==2">
                                        <p class="text-left-h" style="color:coral;font-size:15px;">
                                            <router-link :to="{name:'ArticleShowView',path:'/articleshowview',query:{id:article.id,user_id:article.user_id}}">
                                                <p style="color:#EB7340;font-weight: bold;">#{{article.big_title}}#</p>
                                            </router-link>
                                        </p>
                                        <p style="text-align:left;color: black;font-size: 14px;" >
                                            {{article.summary}}
                                        </p>
                                    </el-col>
                                    <el-col span=18  v-else-if="Object.keys(article).includes('cover_vo')&&article.cover_vo.pics.length==1">
                                        <p class="text-left-h" style="color:coral;font-size:15px;">
                                            <router-link :to="{name:'ArticleShowView',path:'/articleshowview',query:{id:article.id,user_id:article.user_id}}">
                                                <p style="color:#EB7340;font-weight: bold;">#{{article.big_title}}#</p>
                                            </router-link>
                                        </p>
                                        <p style="text-align:left;color: black;font-size: 14px;" >
                                            {{article.summary}}
                                        </p>
                                    </el-col>
                                    <el-col span=24  v-else>
                                        <p class="text-left-h" style="color:coral;font-size:15px;">
                                            <router-link :to="{name:'ArticleShowView',path:'/articleshowview',query:{id:article.id,user_id:article.user_id}}">
                                                <p style="color:#EB7340;font-weight: bold;">#{{article.big_title}}#</p>
                                            </router-link>
                                        </p>
                                        <p style="text-align:left;color: black;font-size: 14px;" >
                                            {{article.summary}}
                                        </p>
                                    </el-col>
                                    <el-col span="6" v-if="Object.keys(article).includes('cover_vo')&&article.cover_vo.pics.length==1"> 
                                        <a v-if="Object.keys(article).includes('cover_vo')"> 
                                            <a  v-for="url,index in article.cover_vo.pics" :key="index">
                                                <el-image style="width: 130px; height: 100px" :src="url"></el-image>                            
                                                &nbsp;&nbsp;&nbsp;
                                            </a>
                                        </a>
                                    </el-col>
                                    <el-col span="8" v-if="Object.keys(article).includes('cover_vo')&&article.cover_vo.pics.length==2"> 
                                        <a v-if="Object.keys(article).includes('cover_vo')"> 
                                            <a  v-for="url,index in article.cover_vo.pics" :key="index">
                                                <el-image style="width: 130px; height: 100px" :src="url"></el-image>                            
                                                &nbsp;&nbsp;&nbsp;
                                            </a>
                                        </a>
                                    </el-col>
                                    <el-col span="12" v-if="Object.keys(article).includes('cover_vo')&&article.cover_vo.pics.length==3"> 
                                        <a v-if="Object.keys(article).includes('cover_vo')"> 
                                            <a  v-for="url,index in article.cover_vo.pics" :key="index">
                                                <el-image style="width: 130px; height: 100px" :src="url"></el-image>                            
                                                &nbsp;&nbsp;&nbsp;
                                            </a>
                                        </a>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>

                        <!-- <div class="row" style="font-size:15px"> 
                            <div class="col-1"> 

                            </div>
                            <div class="col-11" v-if="Object.keys(article).includes('cover_vo')">
                                <div class="row"> 
                                    <div class="col" style="text-align:left;"> 
                                        <p class="text-left-h" style="color:coral;font-size:15px;">
                                            <router-link :to="{name:'ArticleShowView',path:'/articleshowview',query:{id:article.id,user_id:article.user_id}}">
                                                <p style="color:#EB7340;font-weight: bold;">#{{article.big_title}}#</p>
                                            </router-link>
                                        </p>
                                        <p style="text-align:left;color: black;font-size: 14px;" >
                                            {{article.summary}}
                                        </p>
                                    </div>   
                                    <div class="col">
                                        <a v-if="Object.keys(article).includes('cover_vo')"> 
                                            <a  v-for="url,index in article.cover_vo.pics" :key="index">
                                                <el-image style="width: 130px; height: 100px" :src="url"></el-image>                            
                                                &nbsp;&nbsp;&nbsp;
                                            </a>
                                        </a>
                                    </div>
                                </div>                                
                            </div>
                            <div class="col-11" v-else>
                                <div class="row"> 
                                    <div class="col-12" style="text-align:left;"> 
                                        <p class="text-left-h" style="color:coral;font-size:15px;">
                                            <router-link :to="{name:'ArticleShowView',path:'/articleshowview',query:{id:article.id,user_id:article.user_id}}">
                                                <p style="color:#EB7340;font-weight: bold;">#{{article.big_title}}#</p>
                                            </router-link>
                                        </p>
                                        <p style="text-align:left;color: black;font-size: 14px;" >
                                            {{article.summary}}
                                        </p>
                                    </div>
                                </div>                                
                            </div>                                 
                        </div> -->
                        <!-- 文章评论 -->
                        <div class="row"> 
                            <div class="col-2"> </div>
                            <div class="col-2"> 
                                <!-- <b-icon icon="box-arrow-up-right"></b-icon> -->
                                <b-icon icon="hand-thumbs-up"></b-icon>
                                {{ article.praise }}
                            </div>
                            <div class="col-1"></div>
                            <div class="col-2"> 
                                <b-icon icon="chat-square-dots"></b-icon>
                                {{ article.comments }}
                            </div>
                            <div class="col-1"></div>
                            <div class="col-2"> 
                                <b-icon icon="hand-thumbs-down"></b-icon>
                                {{ article.criticise }}
                            </div>
                            <div class="col-1"></div>
                        </div>
                    <!-- <p></p> -->
                    <hr/>    
                    </div>
                    
                    </div>            
                </div>
            </div>
        </div>
    </el-card>

</div>
</template>

<script>
import { getArticlePublic,getClassifyBasic,getArticleListByClassifyBasicId } from '@/api/article-server/getData';


export default{

data(){
    return{
        isShowLoadSpinner:false,
        head_img:'',
        style:'',
        ArticleList:[],
        ClassifyBasic:'',
        page:1,
        size:8,
        total_page:0,
        classify_basic_id:0
    }
},

mounted(){
	window.addEventListener('scroll',this.handleScrollx,true)
},

methods: {

    load(){
        if(this.classify_basic_id==0){
            if(this.page<this.total_page){
                this.page+=1
                this.getArticleList(this.page,this.size)
            }
        }
        else{
            if(this.page<this.total_page){
                this.page+=1
                this.getArticleListByClassifyBasic(this.classify_basic_id,this.page,this.size)
            }
        }
    },

    
    async getArticleListStart(page,size){
        this.classify_basic_id=0
        this.page=1
        this.size=8
        const result = await getArticlePublic(page,size)
        if(result.data.code==0){
            this.ArticleList=result.data.data.current_data
        }
    },

    async getArticleListByClassifyBasicStart(classify_basic_id){
        this.classify_basic_id=classify_basic_id
        this.page=1
        this.size=8
        const result = await getArticleListByClassifyBasicId(this.classify_basic_id,this.page,this.size)
        if(result.data.code==0){
            this.ArticleList=result.data.data.current_data
        }
    },


    /**
     * 判断指定的div距离顶部的距离
     */
	handleScrollx() {
	  console.log('滚动高度',window.pageYOffset)
	  console.log('距离顶部高度',this.$refs.pronbit.getBoundingClientRect().top)
      if(this.$refs.pronbit.getBoundingClientRect().top<50){
        this.style="position:fixed;top:40px"
      }else{
        this.style=''
      }
	},

    /**
     * 获取文章的数据
     */
    async getArticleList(page,size){
        const result = await getArticlePublic(page,size);
        if(result.data.code==0){
            this.ArticleList = this.ArticleList.concat(result.data.data.current_data)
            this.total_page = result.data.data.total_page
        }else{
            console.log("获取数据失败")
        }
    },

    /**
     * 获取基础分类
     */
    async getClassifyBasicList(){
        const result = await getClassifyBasic();
        if(result.data.code==0){
            this.ClassifyBasic = result.data.data
        }else{
            console.log("获取数据失败")
        }
    },

    /**
     * 根据分类的id获取对应的文章数据
     * @param {分类的id} classify_basic_id 
     */
    async getArticleListByClassifyBasic(classify_basic_id,page,size){
        this.isShowLoadSpinner=true
        const result = await getArticleListByClassifyBasicId(classify_basic_id,page,size)
        if(result.data.code == 0){
            this.isShowLoadSpinner=false;
            this.ArticleList = this.ArticleList.concat(result.data.data.current_data)
            this.total_page = result.data.data.total_page
        }
    }
},

created(){
    this.head_img = this.$utils.localStorageGetItem('user_head_img')
    this.getArticleList(this.page,this.size);
    this.getClassifyBasicList();
}

}

</script>


<style lang="scss" scoped>

a{
    text-decoration:none
}

.right{
    // margin-top: 20px;
}

.menu{
    background: #fff;
}

.summary{
    background: #fff;
}

.load{
    position: fixed;
    top:150px;
    left:50%;
}


</style>