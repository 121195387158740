<template>
    <div class="bg"> 
        <head-view :UserDetail="UserDetail"></head-view>
        <div class="load">
          <b-spinner  v-if="isShowLoadSpinner" style="position:relative;top:260px" variant="primary" label=""></b-spinner>
        </div>
        <div class="row" >
            <el-row   >
                <el-col span="2"></el-col>
                <el-col span="20">
                    <el-row :gutter="5">
                        <el-col span="5" style="text-align:right">
                            <article-show-left-view @toRefreshAll="refreshAll" @toRefreshFollow="refreshFollow" :isFollow="isFollow" :article="article" :article_hot="article_hot" :article_new="article_new" :follow="follow"></article-show-left-view>
                        </el-col>                   
                        <el-col span="14" style="text-align:right">
                            <article-show-center-view 
                             ref="centerview"
                             @toRefreshAll="refreshAll" :article="article" 
                             @refreshComment="getArticleComments" :article_comment="article_comment" 
                             @toRefreshFollow="refreshFollow" :isFollow="isFollow"
                             @toRefreshCollection="refreshCollection" :isCollection="isCollection" :collection="collection" 
                             @toRefreshCriticise="refreshCriticise" :isCriticise="isCriticise" :criticise="criticise"  
                             @toRefreshPraise="refreshPraise" :isPraise="isPraise" :praise="praise" 
                             ></article-show-center-view>
                        </el-col>
                        <el-col span="5" style="text-align:left"> 
                            <article-show-right-view @toRefreshAll="refreshAll"  :article_classify="article_classify" :classify_name="classify_name"></article-show-right-view>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col span="2"></el-col>
            </el-row>
        </div>
        <copy-right-view></copy-right-view>
    </div>   
</template>

<script>

import HeadView from '@/components/HeadView.vue'
import ArticleShowLeftView from './Components/ArticleShowLeftView.vue'
import ArticleShowCenterView from './Components/ArticleShowCenterView.vue'
import ArticleShowRightView from './Components/ArticleShowRightView.vue'
import {getNumberOfArticleCollection,getNumberOfArticlePraise,getNumberOfArticleCriticise, getArticleAndOther,getArticleHotByUser,getArticleNewByUser,getArticleComments,getArticleRelate,getArticlesByClassifyId} from '@/api/article-server/getData'
import { verifyFollow } from '@/api/follofans-server/checkData'
import { verifyCollection,verifyPraise,verifyCriticise } from '@/api/article-server/checkData'
import { increaseViewed } from '@/api/article-server/saveData'
import CopyRightView from '@/components/CopyRightView.vue'

export default{

  components: { 
    ArticleShowLeftView,
    HeadView, 
    ArticleShowCenterView ,
    ArticleShowRightView,
    CopyRightView,
  },

  data(){
    return {

        //是否关注，关注量
        isFollow:false,     follow:0,
        //是否收藏，收藏量
        isCollection:false, collection:'',
        //是否点赞，点赞量
        isPraise:false,     praise:'',
        //是否踩，踩量
        isCriticise:false,  criticise:'',
    
        //是否显示加载条
        isShowLoadSpinner:false,

        //用户信息，传入顶部导航条
        UserDetail:'',

        //文章对象
        article:'',
        //热门榜单对象
        article_hot:'',
        //最新榜单对象
        article_new:'',
        //评论对象
        article_comment:'',
        //相关文章对象
        article_relate:'',
        //文章专栏对象
        article_classify:'',
        //专栏名
        classify_name:'',

        classify_basic_id:'',
        article_title:'',

        // loading:true
    }
  },


    methods:{

        /**
         * 刷新页面内容
         * @param {文章的id} id 
         * @param {用户的id} user_id 
         */
        refreshAll(id,user_id){
            //返回页面顶部
            this.$utils.backToTopOfPage(0,0)
            //获取全部数据
            this.getAll(id,user_id)

        },

        /**
         * 刷新关注的状态
         */
        refreshFollow(){
            if(this.isFollow){
                this.isFollow=false
            }else{
                this.isFollow=true
            }
        },

        /**
         * 刷新收藏的状态
         */
        refreshCollection(){
            if(this.isCollection){
                this.collection=this.collection-1
                this.isCollection=false
            }else{
                this.collection=this.collection+1
                this.isCollection=true
            }
        },

        /**
         * 刷新点赞的状态
         */
         refreshPraise(){
            if(this.isPraise){
                this.praise=this.praise-1
                this.isPraise=false
            }else{
                this.praise=this.praise+1
                this.isPraise=true
            }
        },

        /**
         * 刷新踩的状态
         */
         refreshCriticise(){
            if(this.isCriticise){
                this.criticise=this.criticise-1
                this.isCriticise=false
            }else{
                this.criticise=this.criticise+1
                this.isCriticise=true
            }
        },


        /**
         * 获取页面数据
         * @param {文章的id} id 
         * @param {用户的id} user_id 
         */
        getAll(id,user_id){
            this.isShowLoadSpinner=true;
            this.getArticle(id);
            this.getArticleHot(user_id);
            this.getArticleNew(user_id);
            this.getArticleComments(id);
            this.UserDetail = {"head_img":this.$utils.localStorageGetItem("user_head_img")}
            // this.loading=false
            this.isShowLoadSpinner=false;
        },

        /**
         * 获取文章内容和文章对应用户信息分类信息
         */
        async getArticle(id){
            const result = await getArticleAndOther(id)
            if(result.data.code == 0){
                this.article = result.data.data
                this.verifyFollowed(result.data.data.user_vo.id)
                this.verifyCollected(id)
                this.verifyPraised(id)
                this.verifyCriticised(id)
                this.getArticleCollection()
                this.getArticlePraise()
                this.getArticleCriticise()
                this.getArticleByClassifyId(result.data.data.classify_id)

                this.classify_name = result.data.data.classify_vo.name
                this.classify_basic_id = result.data.data.classify_basic_id
                this.article_title = result.data.data.big_title

                // this.$refs.centerview.getRelateArticle(this.article.id)
                // this.$refs.load()
            }
        },

        /**
         * 校验用户是否被关注
         * @param {被关注用户的id} follow_id 
         */
        async verifyFollowed(follow_id){
            if(this.$utils.localStorageGetItem("token")!=null){
                const result = await verifyFollow(follow_id)
                if(result.data.code==0){
                    this.isFollow=true;
                }else{
                    this.isFollow=false;
                }
            }
            
        },

        /**
         * 校验文章是否被收藏
         * @param {文章id} article_id 
         */
        async verifyCollected(article_id){

            if(this.$utils.localStorageGetItem("token")!=null){
                const result = await verifyCollection(article_id)
                if(result.data.code==0){
                    this.isCollection=true
                }else{
                    this.isCollection=false
                }
            }
        },
        
        /**
         * 校验文章是否被点赞
         * @param {文章id} article_id 
         */
         async verifyPraised(article_id){

            if(this.$utils.localStorageGetItem("token")!=null){
                const result = await verifyPraise(article_id)
                if(result.data.code==0){
                    this.isPraise=true
                }else{
                    this.isPraise=false
                }
            }
        },
        /**
         * 校验文章是否被踩
         * @param {文章id} article_id 
         */
         async verifyCriticised(article_id){

            if(this.$utils.localStorageGetItem("token")!=null){
                const result = await verifyCriticise(article_id)
                if(result.data.code==0){
                    this.isCriticise=true
                }else{
                    this.isCriticise=false
                }
            }
        },
        

        /**
         * 获取文章的收藏量
         */
        async getArticleCollection(){
            const result = await getNumberOfArticleCollection(this.$route.query.id)
            if(result.data.code==0){
                this.collection=result.data.data
            }
        },

        /**
         * 获取文章的点赞量
         */
         async getArticlePraise(){
            const result = await getNumberOfArticlePraise(this.$route.query.id)
            if(result.data.code==0){
                this.praise=result.data.data
            }
        },

        /**
         * 获取文章的踩量
         */
         async getArticleCriticise(){
            const result = await getNumberOfArticleCriticise(this.$route.query.id)
            if(result.data.code==0){
                this.criticise=result.data.data
            }
        },

        async increaseArticleViewed(){
            const result = await increaseViewed(this.$route.query.id)
            if(result.data.code==0){
                
            }
        },
        

        /**
         * 获取对应文章的评论
         */
        async getArticleComments(id){
            this.article_comment=''
            const result = await getArticleComments(id)
            if(result.data.code==0){
                this.article_comment = result.data.data
            }
        },

        /**
         * 获取对应用户的热点文章
         */
        async getArticleHot(user_id){
            const result = await getArticleHotByUser(user_id,1,10,32)
            if(result.data.code == 0){
                this.article_hot = result.data.data.current_data
            }
        },
        /**
         * 获取对应用户的最新文章
         */
        async getArticleNew(user_id){
            const result = await getArticleNewByUser(user_id,1,10,32)
            if(result.data.code==0){
                this.article_new = result.data.data.current_data
            }  
        },


        /**
         * 当前文章所属的私有分类的其他文章
         * @param {私有分类的id} classify_id 
         */
        async getArticleByClassifyId(classify_id){
            const result = await getArticlesByClassifyId(classify_id,1,10,32)
            if(result.data.code==0){
                this.article_classify = result.data.data.current_data
            }
        }

    },

  created(){
    this.getAll(this.$route.query.id,this.$route.query.user_id);
    this.increaseArticleViewed();
    
  }
}

</script>


<style lang="scss" scoped>

.bg{
    background-color: rgb(199, 199, 199);
}

.el-col{
  border:1px solid transparent
}

.load{
  position: fixed;
  top:150px;
  left:50%;
}


pre code{
    background-color: #1c1d21;
    border-radius: 12px;
    padding: 1.5em;
}

</style>