import axios from "./base";

export const register=(phone,code,image_code_key,image_code)=>axios.post("account/pri/v1/register",{
    phone,
    code,
    image_code_key,
    image_code
})

export const findPwd=(phone,code,pwd,image_code_key,image_code)=>axios.post("account/pri/v1/forget_update_pwd",{
    phone,
    code,
    pwd,
    image_code_key,
    image_code
})