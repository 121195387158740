import axios from "./base";
import utils from "@/utils/yujianbo-js-utils-1.0.0"


/**
 * 保存关注
 * @param {被关注的用户id} followed_id 
 * @returns 
 */
export const saveFollow=(followed_id)=>axios.get("follow/pri/v1/save_follow",{

    params:{
        followed_id,
        token:utils.localStorageGetItem("token")
    }

})

/**
 * 取消关注
 * @param {被关注的用户id} followed_id 
 * @returns 
 */
export const cancelFollow=(followed_id)=>axios.get("follow/pri/v1/cancel_follow",{

    params:{
        followed_id,
        token:utils.localStorageGetItem("token")
    }

})