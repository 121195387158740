<template>
  <div class="">
<!--3、使用组件-->
  <head-view :UserDetail="UserDetail"></head-view>
  <!-- <br/>
  <br/> -->
  <div class="row"> 
    <div class="col-1">      
    </div>
    <div class="col-10"> 
      <div class="person_center">
        <b-card no-body class="card">
          <b-tabs  pills card vertical  class="tabs">
            <b-tab  title="个人资料" class="tab" active>
              <b-card-text>
                <person-info :UserDetail="UserDetail"></person-info>                 
              </b-card-text>
            </b-tab>               
            <b-tab title="账号设置">
              <b-card-text>
                <person-set :UserDetail="UserDetail"></person-set>
              </b-card-text>
            </b-tab>
            <b-tab title="隐私设置">
              <b-card-text>
                <person-privte></person-privte>
              </b-card-text>
            </b-tab>
            <b-tab title="信息认证">
              <b-card-text>
                <person-auth></person-auth>
              </b-card-text>
            </b-tab>
            <b-tab title="我的收藏">
              <b-card-text>
                <person-collect></person-collect>
              </b-card-text>
            </b-tab>
            <b-tab title="浏览历史">
              <b-card-text>
                <person-history></person-history>
              </b-card-text>
            </b-tab>
            <b-tab title="内容管理" @click="toArticle">
              <b-card-text>
                Tab contents 3
              </b-card-text>
            </b-tab>
            <b-tab title="学习计划管理">
              <b-card-text>
                Tab contents 3
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
    <div class="col-1"> 

    </div>           
  </div>
      </div>
</template>

<script>
//1、导入组件
import HeadView from '@/components/HeadView.vue'
import PersonInfo from './Components/PersonInfo.vue';
import PersonSet from './Components/PersonSet.vue'
import PersonPrivte from './Components/PersonPrivte.vue'
import PersonAuth from './Components/PersonAuth.vue'
import PersonCollect from './Components/PersonCollect.vue'
import PersonHistory from './Components/PersonHistory.vue'
import { getUserInfo } from '@/api/user-server/getData';
// import {getxxx} from '@/api/.....'

export default{
    //2、注册组件
    components:{
    HeadView,
    PersonInfo,
    PersonSet,
    PersonPrivte,
    PersonAuth,
    PersonCollect,
    PersonHistory
},
    //存储数据
    data(){
      return {
          UserDetail:'',
          login_expire:2*60*60*1000
      }
    },

    methods:{

      toArticle(){
        this.$router.push('/articleview')
      },
      async getUserDetail(){
        const result = await getUserInfo(this.$utils.localStorageGetItem('token'));
        if(result.data.code ==0){
          this.UserDetail = result.data.data
          this.$utils.localStorageSetItem("user_id",result.data.data.id,this.login_expire)
          this.$utils.localStorageSetItem("user_name",result.data.data.name,this.login_expire)
          this.$utils.localStorageSetItem("user_phone",result.data.data.phone,this.login_expire)
          this.$utils.localStorageSetItem("user_email",result.data.data.email,this.login_expire)
          this.$utils.localStorageSetItem("user_head_img",result.data.data.head_img,this.login_expire)
          this.$utils.localStorageSetItem("user_score",result.data.data.score,this.login_expire)
          this.$utils.localStorageSetItem("user_money",result.data.data.money,this.login_expire)
          this.$utils.localStorageSetItem("user_follow",result.data.data.follow,this.login_expire)
          this.$utils.localStorageSetItem("user_fans",result.data.data.fans,this.login_expire)
          this.$utils.localStorageSetItem("user_visit",result.data.data.visit,this.login_expire)
          this.$utils.localStorageSetItem("user_article",result.data.data.article,this.login_expire)
          this.$utils.localStorageSetItem("user_summary",result.data.data.summary,this.login_expire)
          this.$utils.localStorageSetItem("user_create_time",result.data.data.create_time,this.login_expire)
          this.$utils.localStorageSetItem("user_update_time",result.data.data.update_time,this.login_expire)

          // this.$utils.sendToastByYJB("加载用户信息成功",1000)

        }else{
            this.$utils.sendToastByYJB("获取用户信息失败",1000)
        }
      },
    },
    
    mounted(){
      //⑥ 渲染页面完成后，请求拿到数据
      // this.CustomName();
      // yujianboJsUtils.localStorageSaveToken("token","dataxxx",5*1000)
      // console.log(yujianboJsUtils.localStorageGetToken("token"))

      // yujianboJsUtils.localStorageSaveToken("token","dataxxx",1)
      
      // console.log(yujianboJsUtils.localStorageGetToken("token"))

    },
    created(){
      this.getUserDetail()
    }
}

</script>


<style lang="scss" scoped>

.person_center{
position: relative;
margin-top: 15px;
}

.card{
  background-color: white;
  border: 0;
}






</style>