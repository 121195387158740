<template>
    <div>
        <el-row> 
            <el-col> 
                <div class="card text-dark bg-light mb-3 layout-scroll-parent" style="width: 100%;height: auto;background-color: white;" >
                    <div class="card-header"><h6 style="color:orange">实力等级&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lv{{ UserDetail.level }}</h6></div>
                    <div class="card-body layout-scroll-child">
                        <p class="card-text" style="color:black">
                            总积分：{{UserDetail.score}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本月：{{UserDetail.score}}
                        </p>                    
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-row> 
            <el-col> 
                <div class="card text-dark bg-light mb-3 layout-scroll-parent" style="width: 100%;height: auto;background-color: white;" >
                    <div class="card-header"><h6 >个人成就</h6></div>
                    <div class="card-body layout-scroll-child">
                        <el-row> 
                            <el-col span=24> 
                                <el-row> 
                                    <el-col span=24> 
                                        访问情况
                                    </el-col>
                                </el-row>
                                <p></p>
                                <el-row> 
                                    <el-col span=2> 
                                        <el-image  src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" class="img-circle-20"/>
                                    </el-col>
                                    <el-col span=20 style="text-align:left"> 
                                        获得{{UserDetail.comments}}次评论
                                    </el-col>

                                </el-row>
                                <p></p>
                                <el-row> 
                                    <el-col span=2> 
                                        <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" class="img-circle-20"/>
                                    </el-col>
                                    <el-col span=20 style="text-align:left"> 
                                        获得{{UserDetail.praise}}次点赞
                                    </el-col>
                                </el-row>
                                <p></p>
                                <el-row> 
                                    <el-col span=2> 
                                        <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" class="img-circle-20"/>
                                    </el-col>
                                    <el-col span=20 style="text-align:left"> 
                                        获得{{UserDetail.collection}}次收藏
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                        <hr style="color:darkgray"/>
                        <el-row> 
                            <el-col span=24> 
                                <el-row> 
                                    <el-col span=24> 
                                        创作历程
                                    </el-col>
                                </el-row>
                                <p></p>
                                <el-row style="background-color:#eaeaea;text-align: center;"> 
                                    <p></p>
                                    <el-col span=8> 
                                        {{ beforeLastYear }}&nbsp;篇
                                        <p>{{ Object.keys(this.article_number_year)[0] }}</p>
                                    </el-col>
                                    <el-col span=8> 
                                       {{ lastYear }}&nbsp;篇
                                        <p>{{ Object.keys(this.article_number_year)[1] }}</p>
                                    </el-col>
                                    <el-col span=8> 
                                        {{ thisYear }}&nbsp;篇
                                        <p>{{ Object.keys(this.article_number_year)[2] }}</p>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                        <hr style="color:darkgray"/>
                        <el-row> 
                            <el-col span=24> 
                                <el-row> 
                                    <el-col span=24> 
                                        成就勋章
                                    </el-col>
                                </el-row>
                                <p></p>
                                <el-row style="text-align: center;"> 
                                    <p></p>
                                    <el-col span=3> 
                                        <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" class="img-circle-30"/>
                                    </el-col>
                                    <el-col span=3> 
                                        <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" class="img-circle-30"/>
                                    </el-col>
                                    <el-col span=3> 
                                        <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" class="img-circle-30"/>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>                                           
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>



export default{

    data(){
        return{
        }
    },

    props:{
        UserDetail:{
            type:Object,
            required:true
        },
        article_number_year:{
            type:Object,
            required:true
        },
        thisYear:{
            type:Object,
            required:true
        },
        lastYear:{
            type:Object,
            required:true
        },
        beforeLastYear:{
            type:Object,
            required:true
        }
    },

    methods:{

    },
    mounted(){
    }
    ,
    created(){
    }

}

</script>


<style lang="scss" scoped>

.card-header{
    padding-top: 15px;
    text-align: left;
    background-color: #ffffff;
}
.card-body{
    text-align: left;
    background-color: #ffffff;
}
.card-num{
    color: #c75e2a;
    font-size: 15px;
}
.card-title{
    color: black;
    font-size:14px;
}

</style>