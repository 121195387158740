import axios from "./base";
import utils from '@/utils/yujianbo-js-utils-1.0.0'

/**
 * 保存文章
 * 提交方式：json
 * @param {用户id} user_id 
 * @param {大标题} big_title 
 * @param {副标题} small_title 
 * @param {简介} summary 
 * @param {内容} content 
 * @param {公共分类} classify_id
 * @param {私有分类} classify_basic_id
 * @param {公开为1或私有为0} publiced
 * @param {发布1或草稿0} published
 * @param {发布时间} published_date_time
 * @returns 
 */
 export const saveArticle=(user_id,big_title,small_title,summary,article_cover_img_1,article_cover_img_2,article_cover_img_3,content,classify_id,classify_basic_id,publiced,published,published_date,published_time)=>axios.post("article/pri/v1/save_article_by_token",{
    user_id,
    big_title,
    small_title,
    summary,
    article_cover_img_1,
    article_cover_img_2,
    article_cover_img_3,
    content,
    classify_basic_id,
    classify_id,
    publiced,
    published,
    published_date,
    published_time
}
,
{
    headers:{
        token:utils.localStorageGetItem("token")
    }
}
)

/**
 * 更新文章
 * @param {文章id} id 
 * @param {登录令牌} token 
 * @param {用户手机号} user_phone 
 * @param {文章标题} big_title 
 * @param {文章副标题} small_title 
 * @param {文章简介} summary 
 * @param {文章内容} content 
 * @param {私有专栏id} classify_id 
 * @param {公共分类id} classify_basic_id 
 * @param {是否公开} publiced 
 * @param {是否发布} published 
 * @returns 
 */
export const updateArticle=(id,big_title,small_title,summary,article_cover_img_1,article_cover_img_2,article_cover_img_3,content,classify_id,classify_basic_id,publiced,published)=>axios.post("article/pri/v1/update_article",{
    id,
    big_title,
    small_title,
    summary,
    article_cover_img_1,
    article_cover_img_2,
    article_cover_img_3,
    content,
    classify_basic_id,
    classify_id,
    publiced,
    published,
}
,
{
    headers:{
        token:utils.localStorageGetItem("token")
    }
}
)


/**
 * 删除文章
 * @param article_id
 * @param user_id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteArticleByArticleId=(article_id)=>axios.get("article/pri/v1/delete_article",
    {
        params:{
            article_id,
            token:utils.localStorageGetItem("token")
        }
    }
    )

/**
 * 恢复被删除的文章
 * @param article_id
 * @param user_id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const recoveryArticleByArticleId=(article_id)=>axios.get("article/pri/v1/recovery_article",
    {
        params:{
            article_id,
            token:utils.localStorageGetItem("token")
        }
    }
)



/**
 * 保存私有专栏
 * @param {专栏名称} name 
 * @param {专栏简介} summary 
 * @param {专栏封面} cover_img 
 * @param {专栏公开} open 
 * @param {专栏收费} charge 
 * @returns 
 */
export const saveClassify=(user_id,name,summary,cover_img,open,charge)=>axios.post("classify/pri/v1/save_classify_by_token",
{
    user_id,
    name,
    summary,
    cover_img,
    open,
    charge
},
{
   headers:{
       token:utils.localStorageGetItem("token")
   }
}
)

/**
 * 更新私有专栏
 * @param {专栏id} id
 * @param {专栏名称} name 
 * @param {专栏简介} summary 
 * @param {专栏封面} cover_img 
 * @param {专栏公开} open 
 * @param {专栏收费} charge 
 * @returns 
 */
 export const updateClassify=(id,name,summary,cover_img,open,charge)=>axios.post("classify/pri/v1/update_classify_by_token",
 {
     id,
     name,
     summary,
     cover_img,
     open,
     charge
 },
 {
    headers:{
        token: utils.localStorageGetItem("token")
    }
 }
 )

 /**
  * 删除专栏
  * @param {私有专栏id} id 
  * @returns 
  */
 export const deleteClassify=(id)=>axios.post("classify/pri/v1/delete_classify_by_token",
 {
    id
 },
 {
    headers:{
        token: utils.localStorageGetItem("token")
    }
 }
 )

 /**
  * 恢复专栏
  * @param {私有专栏id} id 
  * @returns 
  */
 export const recoveryClassify=(id)=>axios.post("classify/pri/v1/recovery_classify_by_token",
 {
    id,
 },
 {
    headers:{
        token: utils.localStorageGetItem("token")
    }
 }
 )

 /**
  * 发布评论
  * @param {文章id} article_id 
  * @param {评论内容} content 
  * @param {用户id} user_id 
  * @param {*} from_id 
  * @param {*} to_id 
  * @returns 
  */
 export const saveComment=(article_id,content,user_id,from_id,to_id)=>axios.post("/comment/pri/v1/save_comment_by_article_id",
 {
    article_id,
    content,
    user_id,
    from_id,
    to_id,
    
 },
 {
    headers:{
        token:utils.localStorageGetItem("token")
    }
 }
 )


 /**
  * 保存收藏
  * @param {文章id} article_id 
  * @returns 
  */
export const saveCollection=(article_id)=>axios.get("/collection/pri/v1/save_collection",{
    params:{
        article_id,
        token:utils.localStorageGetItem("token")
    }
})

/**
 * 取消收藏
 * @param {文章id} article_id 
 * @returns 
 */
export const cancelCollection=(article_id)=>axios.get("/collection/pri/v1/cancel_collection",{
    params:{
        article_id,
        token:utils.localStorageGetItem("token")
    }
})

 /**
  * 保存点赞
  * @param {文章id} article_id 
  * @returns 
  */
 export const savePraise=(article_id)=>axios.get("/praise/pri/v1/save_praise",{
    params:{
        article_id,
        token:utils.localStorageGetItem("token")
    }
})

/**
 * 取消点赞
 * @param {文章id} article_id 
 * @returns 
 */
export const cancelPraise=(article_id)=>axios.get("/praise/pri/v1/cancel_praise",{
    params:{
        article_id,
        token:utils.localStorageGetItem("token")
    }
})


 /**
  * 保存踩
  * @param {文章id} article_id
  * @returns 
  */
 export const saveCriticise=(article_id)=>axios.get("/criticise/pri/v1/save_criticise",{
    params:{
        article_id,
        token:utils.localStorageGetItem("token")
    }
})

/**
 * 取消踩
 * @param {文章id} article_id
 * @returns 
 */
export const cancelCriticise=(article_id)=>axios.get("/criticise/pri/v1/cancel_criticise",{
    params:{
        article_id,
        token:utils.localStorageGetItem("token")
    }
})


/**
 * 增加文章阅读量
 * @param {文章id} article_id 
 * @returns 
 */
export const increaseViewed=(article_id)=>axios.get("/article/pub/v1/increase_number_of_viewed",{
    params:{
        article_id
    }
})
