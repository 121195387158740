<template>
    <div>
        
        <el-tabs v-model="activeName1" @tab-click="handleClick1">
            <el-tab-pane label="文章" name="1.1">
                <el-tabs v-model="activeName2" @tab-click="handleClick2">
                    <el-tab-pane label="全部" name="1.1.1">                     
                        <article-all-list-view ref="all"></article-all-list-view>
                    </el-tab-pane>
                    <el-tab-pane label="公开可见" name="1.1.2">
                        <article-public-list-view ref="public"></article-public-list-view>
                    </el-tab-pane>
                    <el-tab-pane label="仅我可见" name="1.1.3">
                        <article-private-list-view ref="private"></article-private-list-view>
                    </el-tab-pane>
                    <el-tab-pane label="审核中" name="1.1.4">
                        <article-examning-list-view ref="examning"></article-examning-list-view>
                    </el-tab-pane>
                    <el-tab-pane label="草稿箱" name="1.1.5">
                        <article-draft-list-view ref="draft"></article-draft-list-view>
                    </el-tab-pane>
                    <el-tab-pane label="回收站" name="1.1.6">
                        <article-recycles-list-view ref="recycle"></article-recycles-list-view>
                        <!-- <article-recycle-list-view @refreshArticle="refreshArticle" @refreshRecycle="getArticlesRecycleByUser" :article_list="article_list"></article-recycle-list-view> -->
                    </el-tab-pane>
                </el-tabs>
            </el-tab-pane>
            <el-tab-pane label="其他" name="1.2">
                其他
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import ArticleContentListView from './Components/ArticleContentListView.vue';

import ArticleRecycleListView from "@/views/Article/ArticleContent/Components/ArticleRecycleListView";
import ArticleAllListView from './Components/ArticleAllListView.vue'


import ArticlePublicListView from './Components/ArticlePublicListView.vue';
import ArticlePrivateListView from './Components/ArticlePrivateListView.vue';
import ArticleExamningListView from './Components/ArticleExamningListView.vue';
import ArticleDraftListView from './Components/ArticleDraftListView.vue';
import ArticleRecyclesListView from './Components/ArticleRecyclesListView.vue';


export default{

  components: { 
    ArticleContentListView,
    ArticleRecycleListView,
    ArticleAllListView, 
    ArticlePublicListView,
    ArticlePrivateListView,
    ArticleExamningListView,
    ArticleDraftListView,
    ArticleRecyclesListView 
  },

  data() {
        return {
          activeName1: '1.1',
          activeName2: '1.1.1',
        }
  },

    
      methods: {

      handleClick1(tab, event) {
        if(tab.name=='1.1'){
            console.log(tab.name)
        }else if(tab.name=='1.2'){
            console.log(tab.name)
        }
      },

      handleClick2(tab, event) {
        if(tab.name=='1.1.1'){
            this.$refs.all.getArticlesAllByUser()
            console.log(tab.name)
        }else if(tab.name=='1.1.2'){
            this.$refs.public.getArticlesPublicByUser()
            console.log(tab.name)
        }else if(tab.name=='1.1.3'){
          this.$refs.private.getArticlesPrivateByUser()
            console.log(tab.name)
        }else if(tab.name=='1.1.4'){
          this.$refs.examning.getArticlesExaminingByUser()
            console.log(tab.name)
        }else if(tab.name=='1.1.5'){
          this.$refs.draft.getArticlesDraftByUser()
            console.log(tab.name)
        }else if(tab.name=='1.1.6'){
          this.$refs.recycle.getArticlesRecycleByUser()
            console.log(tab.name)
        }
      },
    },
  

    created(){
    }


}

</script>


<style lang="scss" scoped>


</style>