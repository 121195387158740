<template>

    <div class="row"> 
        <p></p>
        <div class="row classify-list-title"> 
                        <div class="col-4"> 
                专栏名称
            </div>
            <div class="col-2"> 
                创建时间
            </div>

            <div class="col-2"> 
                操作
            </div>
            <div class="col-1"> 
                收费
            </div>
            <div class="col-1"> 
                公开
            </div>
            <div class="col-1"> 
                文章数
            </div>
            <div class="col-1"> 
                订阅数
            </div>

        </div>       
        <hr style="color:darkgrey"/>
        
        <div class="row classify-list" v-for="classify in classify_list" :key="classify"> 
            <p></p>
            <div class="col-4 text-center-vh-50"> 
                <div class="row"> 
                    <div class="col-4"> 
                        <a>
                            <b-card-img style="width:50px;height:50px" :src="classify.cover_img"  alt="Image" class="rounded-0"></b-card-img>
                        </a>
                    </div>
                    <div class="col-8" style="text-align:left"> 
                        {{classify.name.substring(0,20)}}
                    </div>
                </div>
            </div>
             <div class="col-2 text-center-vh-50"> 
                <small>{{classify.create_time}}</small>
            </div>            
            <div class="col-2 classify-list-operate text-center-vh-50"> 
                <!-- <el-button type="success" icon="el-icon-notebook-2" size="small"  circle></el-button> -->
                <el-button @click="showModal(classify)" type="primary" icon="el-icon-edit" size="small" circle></el-button>
                <el-button @click="deleteClassify(classify)" type="danger" icon="el-icon-delete" size="small" circle></el-button>
            </div>
            <div class="col-1 text-center-vh-50"> 
                <el-switch 
                        disabled
                        v-model="classify.charge" :active-value="1" :inactive-value="0"
                        active-text=""
                        inactive-text="" 
                        @change="updateCharge(classify.id,classify.charge)"
                        >
                </el-switch>
            </div>
            <div class="col-1 text-center-vh-50"> 
                <el-switch
                        disabled
                        v-model="classify.open"
                        :active-value="1"
                        :inactive-value="0"
                        active-text=""
                        inactive-text=""
                        @change="updateOpen(classify.id,classify.open)">
                </el-switch>
            </div>
            <div class="col-1 classify-list-article text-center-vh-50"> 
                {{classify.article}}
            </div>
            <div class="col-1 classify-list-subscribe text-center-vh-50"> 
                {{classify.subscribe}}
            </div>
            <p></p>
            <p></p>
            <hr style="color:darkgrey"/>
        </div>
        <div class="row"> 
            <div class="col-12"> 
                <b-modal ref="my-modal" hide-footer title="编辑专栏">
                    <el-row> 
                        <el-col span=20> 
                            <el-form label-width="80px"> 
                                <el-form-item label="专栏标题"> 
                                    <el-input v-model="classify_name"></el-input>
                                </el-form-item>
                                <el-form-item label="专栏简介"> 
                                    <el-input v-model="classify_summary" type="textarea"></el-input>
                                </el-form-item>                               
                                <el-form-item label="专栏公开"> 
                                    <el-switch 
                                        
                                        v-model="classify_open"
                                        :active-value=1
                                        :inactive-value=0
                                        active-text=""
                                        inactive-text="">
                                    </el-switch>
                                </el-form-item>   
                                <el-form-item label="专栏付费"> 
                                    <el-switch 
                                        v-model="classify_charge"
                                        :active-value=1
                                        :inactive-value=0
                                        active-text=""
                                        inactive-text="">
                                    </el-switch>
                                </el-form-item>                          
                                <el-form-item label="选择封面"> 
                                    <el-upload
                                            class="avatar-uploader"
                                            :action=classify_action
                                            :show-file-list="false"
                                            :on-success="handleAvatarSuccess"
                                            :before-upload="beforeAvatarUpload">
                                        <img v-if="classify_cover_img" :src="classify_cover_img" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                </el-form-item>  
                                <el-form-item label-width="180px">                         
                                    <el-button @click="updateClassify" type="danger" size="small" round>立即修改</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>
                </b-modal>
            </div>
            
        </div>
        
    </div>
  
</template>

<script>

import {updateClassify,deleteClassify,recoveryClassify} from '@/api/article-server/saveData'

  export default {
    data() {
      return {
        classify_action:this.$utils.getApiHostPort()+this.$url.get_classify_upload_action(),
        classify_id:'',
        classify_name:'',
        classify_summary:'',
        classify_cover_img:'',
        classify_open:'',
        classify_charge:''

      }
    },
    props:{
        classify_list:{
            type:Object,
            required:true
        }
    },
    methods: {

        handleAvatarSuccess(res, file) {
            this.classify_cover_img = URL.createObjectURL(file.raw);
            this.classify_cover_img = res.data
            console.log(res.data)
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },

        updateCharge(classify_id,classify_charge){
            console.log("updateCharge:"+classify_id+","+classify_charge)
        },

        updateOpen(classify_id,classify_open){
            console.log("updateOpen:"+classify_id+","+classify_open)
        },

        /**
         * 弹出发布窗口
         */
        showModal(classify) {
            this.classify_id = classify.id
            this.classify_name =classify.name
            this.classify_summary = classify.summary
            this.classify_cover_img = classify.cover_img
            this.classify_open = classify.open
            this.classify_charge = classify.charge       

            this.$refs['my-modal'].show() 
        },

        async updateClassify(){
            const result = await updateClassify(this.classify_id,this.classify_name,this.classify_summary,this.classify_cover_img,this.classify_open,this.classify_charge)
            if(result.data.code==0){
                alert("专栏更新成功")
                this.$refs['my-modal'].hide()
                this.$emit('refreshClassify')
            }else{
                alert("专栏更新失败")
                this.$refs['my-modal'].hide()
                // this.$emit('refreshClassify')
            }
        },
        
        async deleteClassify(classify){
            this.classify_id = classify.id
            const result = await deleteClassify(this.classify_id)
            if(result.data.code==0){
                this.$emit('refreshClassify')
                alert("专栏删除成功")
                
            }
        },
        
    }
  }
</script>

<style lang="scss" scoped>
.classify-list-title{
    color: rgb(146, 146, 146);
    font-size: 14px;
    text-align: center;
}

.classify-list{
    text-align: center;
}

.classify-list-operate a{
    font-size: medium;
    text-decoration: none;
}

.classify-list-article{
    font-size: 15px;
}

.classify-list-subscribe{
    font-size: 15px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

</style>