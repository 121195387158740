<template>
   <div>  
      <div class="row" style="text-align:left">
         <h6>隐私设置 </h6>
         <hr/>
      </div>
      <div class="row" >
         <div class="col-4" style="text-align:left"> 
            <h5>工作信息</h5>
            <p><small>开启后在个人主页等模块向他人展示工作信息</small></p>
         </div>
         <div class="col-6"></div>
         <div class="col-2">
            <!-- <b-button variant="outline-secondary">已关闭</b-button> -->
            <b-button variant="outline-success">已开启</b-button>
         </div>
         
         <hr/> 
      </div>
      
      <div class="row" >
         <div class="col-4" style="text-align:left"> 
              <h5>教育信息</h5>
            <p><small>开启后在个人主页等模块向他人展示教育信息</small></p>
         </div>
         <div class="col-6"></div>
         <div class="col-2">
           <!-- <b-button variant="outline-secondary">已关闭</b-button> -->
            <b-button variant="outline-success">已开启</b-button>
         </div>
         <hr/> 
      </div>

      <div class="row" >
         <div class="col-4" style="text-align:left"> 
            <h5>屏蔽设置</h5>
            <p><small>屏蔽后，对方将不可在您的内容下进行评论</small></p>
         </div>
         <div class="col-6"></div>
         <div class="col-2">
            <b-button variant="outline-danger">管理…</b-button>
         </div>
         <hr/> 
      </div>
   

   </div>

    


</template>


<script>
export default {
   data(){
      return{
         selected:'关闭',
         options:[
            {text:"开启",value:"1"},
            {text:"关闭",value:"2"}
         ]
      }
   }
}
</script>