<template>
<!--  <div> -->
    <el-row>
      <el-col span=24 v-for="(article,index) in ArticleList" :key="index" style="text-align: left">
        <router-link :to="{path:'/articleshowview',query:{id:article.id,user_id:article.user_id}}" style="color: black">
          <el-row>
            <el-col span=24 >
              <h6 v-html="article.big_title"></h6>
            </el-col>
          </el-row>
          <el-row>
            <el-col span=24>
              <small class="text-muted">{{article.summary}}</small>
            </el-col>
          </el-row>
          <el-row>
            <el-col span=24>
              <small class="text-muted">{{article.viewed}}</small>
            </el-col>
          </el-row>
          <hr/>
        </router-link>
      </el-col>
    </el-row>
<!--  </div>-->
</template>
<script>

export default {

  props:{
    ArticleList:{
      type:Object,
      required:true
    }
  }
}

</script>

<style lang="scss" scoped>
::v-deep(em){
  font-style: normal;
  color: #b84d4d;
}
</style>