<template>
    <div>
        <el-row v-for="praise,index in article_praise" :key="index"> 
            <el-col span="24"> 
                <el-row> 
                    <el-col span="4">
                        <small class="text-muted">赞的时间：{{ praise.create_time }}</small>
                        <p></p>
                    </el-col>

                </el-row>
                <el-row> 
                    <el-col span="18"> 
                        <p style="font-size:15px;color:#FF7F00">
                            <router-link :to="{path:'articleshowview',query:{id:praise.article_id,user_id:praise.article_user_id}}">
                            <p style="font-size:15px;color:#FF7F00">
                                {{ praise.article_title }}
                            </p>
                        </router-link>
                        </p>
                    </el-col>
                    <el-col span="6">
                        <el-button @click="cancelPraise(praise.article_id)" type="danger" icon="el-icon-delete" size="small" circle></el-button>
                    </el-col>
                </el-row>
                <el-row> 
                    <el-col span="1"> 
                        <img :src="praise.article_user_head_img" class="img-circle-30"/>
                    </el-col>
                    <el-col span="23"> 
                        <p style="font-size:14px">{{ praise.article_user_name }}</p>
                    </el-col>
                </el-row>
                <hr/>
            </el-col>
            
        </el-row>
    
    </div>
</template>

<script>

import { cancelPraise } from '@/api/article-server/saveData';


export default{

    props:{
        article_praise:{
            type:Object,
            required:true
        }
    },
    methods:{
        async cancelPraise(articleId){
            const result = await cancelPraise(articleId)
            if(result.data.code==0){
                this.$emit('toRefreshPraise')
            }
        }
    }

}

</script>


<style lang="scss" scoped>

a{
    text-decoration: none;
}

</style>