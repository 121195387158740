<template>
<div class="row"> 
    <div class="col-12 leftone-bg">
        <div > 
            <div> 
                <el-card class="box-card" style="text-align:left;"> 
                    <div slot="header" class="clearfix" style="text-align:left;font-weight:bold;">
                        <span>热门榜单</span>   
                    </div>
                    <div> 
                        <p class="card-text text-secondary" v-for="(article,index) in ArticlesHot" :key="index">
                            <router-link :to="{name:'ArticleShowView',path:'/articleshowview',query:{id:article.id,user_id:article.user_id}}">
                                <b class="card-num">{{index+1}}</b>&nbsp;&nbsp;<small class="card-title"><a>{{article.big_title}}</a></small>
                                <!-- <b-icon icon="eye"></b-icon> -->
                                &nbsp;
                                <small class="text-muted">{{article.viewed}}</small>
                            </router-link>
                        </p>
                    </div> 
                </el-card>
            </div>
            <br>
            <div> 
                <el-card class="box-card" style="text-align:left;"> 
                    <div slot="header" class="clearfix" style="text-align:left;font-weight:bold;">
                        <span>最新发布</span>   
                    </div>
                    <div> 
                        <p class="card-text text-secondary" v-for="(article,index) in ArticlesNew" :key="index">
                            <router-link :to="{name:'ArticleShowView',path:'/articleshowview',query:{id:article.id,user_id:article.user_id}}">
                                <b class="card-num">{{index+1}}</b>&nbsp;&nbsp;<small class="card-title"><a>{{article.big_title}}</a></small>
                                <!-- <b-icon icon="eye"></b-icon> -->
                                &nbsp;
                                <small class="text-muted">{{article.viewed}}</small>
                            </router-link>
                        </p>
                    </div> 
                </el-card>
            </div>
            <br>
            <!-- <div class="card text-dark bg-light mb-3 layout-scroll-parent" style="width: 100%;height: auto;" >
                <div class="card-header"><h6>会员精选</h6></div>
                <div class="card-body layout-scroll-child" >
                   <div class="row">
                        <div class="row"> 
                            <div class="col-4"> 
                                <img src="https://img-bss.csdnimg.cn/20208521939823_27859.jpg" width="80px" height="60px"/>
                            </div>
                            <div class="col-8"> 
                                &nbsp;
                                <small>新的优惠券来了</small>
                            </div>
                        </div>
                        <p></p>
                        <div class="row"> 
                            <div class="col-4"> 
                                <img src="https://img-bss.csdnimg.cn/2020852177577_64385.jpg" width="80px" height="60px"/>
                            </div>
                            <div class="col-8"> 
                                &nbsp;
                                <small>新的优惠券来了</small>
                            </div>
                        </div>
                        <p></p>
                        <div class="row"> 
                            <div class="col-4"> 
                                <img src="https://img-bss.csdnimg.cn/20208521916893_74866.jpg" width="80px" height="60px"/>
                            </div>
                            <div class="col-8"> 
                                &nbsp;
                                <small>新的优惠券来了</small>
                            </div>
                        </div>
                        <p></p>
                        <div class="row"> 
                            <div class="col-4"> 
                                <img src="https://img-bss.csdnimg.cn/20208521939823_27859.jpg" width="80px" height="60px"/>
                            </div>
                            <div class="col-8"> 
                                &nbsp;
                                <small>新的优惠券来了</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card text-dark bg-light mb-3 layout-scroll-parent" style="width: 100%;height: auto;" >
                <div class="card-header"><h6>专题推荐</h6></div>
                <div class="card-body layout-scroll-child" >
                   <div class="row">
                        <div class="row"> 
                            <div class="col-4"> 
                                <img src="https://img-bss.csdnimg.cn/20208521939823_27859.jpg" width="80px" height="60px"/>
                            </div>
                            <div class="col-8"> 
                                &nbsp;
                                <small>SpringBoot专题</small>
                            </div>
                        </div>
                        <p></p>
                        <div class="row"> 
                            <div class="col-4"> 
                                <img src="https://img-bss.csdnimg.cn/2020852177577_64385.jpg" width="80px" height="60px"/>
                            </div>
                            <div class="col-8"> 
                                &nbsp;
                                <small>MyBatis专题</small>
                            </div>
                        </div>
                        <p></p>
                        <div class="row"> 
                            <div class="col-4"> 
                                <img src="https://img-bss.csdnimg.cn/20208521916893_74866.jpg" width="80px" height="60px"/>
                            </div>
                            <div class="col-8"> 
                                &nbsp;
                                <small>Sentinel专题</small>
                            </div>
                        </div>
                        <p></p>
                        <div class="row"> 
                            <div class="col-4"> 
                                <img src="https://img-bss.csdnimg.cn/20208521939823_27859.jpg" width="80px" height="60px"/>
                            </div>
                            <div class="col-8"> 
                                &nbsp;
                                <small>alibaba-nacos专题</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card text-dark bg-light mb-3 layout-scroll-parent" style="width: 100%;height: auto;" >
                <div class="card-header"><h6>社区推荐</h6></div>
                <div class="card-body layout-scroll-child" >
                   <div class="row">
                        <div class="row"> 
                            <div class="col-4"> 
                                <img src="https://img-bss.csdnimg.cn/20208521939823_27859.jpg" width="80px" height="60px"/>
                            </div>
                            <div class="col-8"> 
                                &nbsp;
                                <small>底妆社区</small>
                            </div>
                        </div>
                        <p></p>
                        <div class="row"> 
                            <div class="col-4"> 
                                <img src="https://img-bss.csdnimg.cn/2020852177577_64385.jpg" width="80px" height="60px"/>
                            </div>
                            <div class="col-8"> 
                                &nbsp;
                                <small>眼影社区</small>
                            </div>
                        </div>
                        <p></p>
                        <div class="row"> 
                            <div class="col-4"> 
                                <img src="https://img-bss.csdnimg.cn/20208521916893_74866.jpg" width="80px" height="60px"/>
                            </div>
                            <div class="col-8"> 
                                &nbsp;
                                <small>口红社区</small>
                            </div>
                        </div>
                        <p></p>
                        <div class="row"> 
                            <div class="col-4"> 
                                <img src="https://img-bss.csdnimg.cn/20208521939823_27859.jpg" width="80px" height="60px"/>
                            </div>
                            <div class="col-8"> 
                                &nbsp;
                                <small>粉底社区</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->




        </div>

       
        
    </div>
</div>
</template>
<script>

import {getArticleHotByPublic} from "@/api/article-server/getData";
import {getArticleNewByPublic} from "@/api/article-server/getData";

export default{

  data(){
    return{
      ArticlesHot:"",
      ArticlesNew:""
    }
  },
  methods:{
    async getArticlesHot(){
      const result = await getArticleHotByPublic();
      if(result.data.code==0){
        this.ArticlesHot = result.data.data;
      }
    },
    async getArticlesNew(){
      const result = await getArticleNewByPublic();
      if(result.data.code==0){
        this.ArticlesNew = result.data.data;
      }
    }
  },
  created() {
    this.getArticlesHot();
    this.getArticlesNew();
  }


}

</script>


<style lang="scss" scoped>
.leftone-bg{
    margin-top: 20px;
}
.card-header{
    padding-top: 15px;
    text-align: left;
    background-color: #ffffff;
}
.card-body{
    text-align: left;
    background-color: #ffffff;
}
.card-num{
    color: #c75e2a;
    font-size: 15px;
}
.card-title{
    color: black;
    font-size:14px;
}

a{
  text-decoration: none;
  color: black;
}

</style>