import axios from "./base";
import utils from '@/utils/yujianbo-js-utils-1.0.0'

export const get_talk_by_public=(page,size)=>axios.get("talk/pub/v1/get_talk_by_public",{
    params:{
        page,
        size,
    }
})

export const get_talk_by_user=(page,size)=>axios.get("talk/pri/v1/get_talk_by_user",{
    params:{
        page,
        size,
        token:utils.localStorageGetItem("token")
    }
})

export const get_talk_by_user_public=(user_id,page,size)=>axios.get("talk/pub/v1/get_talk_by_user_public",{
    params:{
        user_id,
        page,
        size,
    }
})
