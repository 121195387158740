<template>
<div> 


    <div class="row">
        <!-- 第一行（发布，草稿按钮） -->
        <div class="row"> 
            <p></p>
            <div class="row"> 
                
                <!-- <div class="col-1" style="text-align:right"> -->
                    <!-- <el-button type="text" @click="goback()">
                        <i class="el-icon-back" ></i>
                        返回
                    </el-button>
                </div>
                <div class="col-10" style="text-align:left">
                    <el-form>
                        <el-form-item label="" label-width="0px">
                            <el-input v-model="editor_big_title" placeholder="文章标题"></el-input>                            
                        </el-form-item>               
                    </el-form>                            -->
                    <div class="col-11" style="text-align:left">
                    <el-row> 
                        <el-col span=1> 
                            <el-button type="text" @click="goback()">
                                <i class="el-icon-back" ></i>
                                返回
                            </el-button>
                        </el-col>
                        <el-col span=23> 
                            <el-form > 
                                <el-form-item label="" >
                                    <el-input v-model="editor_big_title" placeholder="文章标题"></el-input>
                                </el-form-item>                        
                            </el-form>    
                        </el-col>
                    </el-row>
                <!-- </div> -->
                </div>
                <div class="col-1"> 
                    <div>                        
                        <el-button id="show-btn" type="danger" circle @click="showModal">发布/草稿</el-button>
                        <b-modal ref="my-modal" hide-footer title="发布/草稿">
                            <div class="d-block text-center" style="text-align:left">
                                <el-form>
                                    <el-form-item label="文章简介" label-width="68px">
                                        <el-input type="textarea" v-model="editor_summary" placeholder="若为空，取文章前30字"></el-input>
                                    </el-form-item>

                                    <el-form-item label="所属分类" style="text-align:left">
                                        <el-select v-model="editor_classify_basic_id"    placeholder="请选择分类">
                                            <el-option
                                                v-for="classify_basic in editor_classify_basic"
                                                :key="classify_basic.id"
                                                :label="classify_basic.name"
                                                :value="classify_basic.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="我的专栏" style="text-align:left">                   
                                        <el-select v-model="editor_classify_id" placeholder="请选择分类">                   
                                            <el-option
                                            v-for="classify in editor_classify"
                                            :key="classify.id"
                                            :label="classify.name"
                                            :value="classify.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>    
                                    <el-form-item label="是否公开" style="text-align:left">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <el-switch v-model="editor_publiced"></el-switch>
                                    </el-form-item>
                                    <el-form-item label="文章封面" label-width="68px">
                                        <el-row> 
                                            <el-col span="8">
                                                <el-upload
                                                    class="avatar-uploader"
                                                    :action="article_cover_upload_action"
                                                    :show-file-list="false"
                                                    :on-success="handleAvatarSuccess1"
                                                    :before-upload="beforeAvatarUpload"
                                                    name="article_cover_img"
                                                    >
                                                    <img v-if="article_cover_img_1" :src="article_cover_img_1" class="avatar">
                                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                                </el-upload>
                                            </el-col>
                                            <el-col span="8">
                                                <el-upload
                                                    class="avatar-uploader"
                                                    :action="article_cover_upload_action"
                                                    :show-file-list="false"
                                                    :on-success="handleAvatarSuccess2"
                                                    :before-upload="beforeAvatarUpload"
                                                    name="article_cover_img"
                                                    >
                                                    <img v-if="article_cover_img_2" :src="article_cover_img_2" class="avatar">
                                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                                </el-upload>
                                            </el-col>
                                            <el-col span="8">
                                                <el-upload
                                                    class="avatar-uploader"
                                                    :action="article_cover_upload_action"
                                                    :show-file-list="false"
                                                    :on-success="handleAvatarSuccess3"
                                                    :before-upload="beforeAvatarUpload"
                                                    name="article_cover_img"
                                                    >
                                                    <img v-if="article_cover_img_3" :src="article_cover_img_3" class="avatar">
                                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                                </el-upload>
                                            </el-col>
                                        </el-row>                                                                                          
                                    </el-form-item>                                     
                                    <el-form-item label="发布/草稿" style="text-align:left"> 
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <el-button type="danger" circle  @click="savePublishArticle">立即发布</el-button>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <el-button type="success" circle @click="saveDraftArticle">存为草稿</el-button>
                                    </el-form-item>                                                                                                 
                                </el-form>
                            </div>
                        </b-modal>
                    </div>                 
                </div>                          
            </div>
            <hr style="color:darkgray"/>    
        </div>
        <!-- 第二行，编辑内容和实时展示 -->
        <div class="row"> 
            <!--  左列编辑内容-->
            <div class="col-6" style="text-align:left"> 
                <el-form  label-width="80px"> 
                    <el-form-item label="关键词" >
                        <el-input v-model="editor_small_title" :placeholder="editor_small_title_placeholder"></el-input>
                    </el-form-item>   
                    <el-form-item label="文章内容">
                        <editor-view @childEditorContent="getContent" :article="article"></editor-view> 
                    </el-form-item>
        
                    <br/>
                </el-form>
            </div>
            <!-- 右列实时显示 -->
            <div class="col-6 ">
                <div > 
                    <div class="row" style="text-align:left">
                        <p></p> 
                        <h5>{{editor_big_title}}</h5>
                        <p></p>
                    </div>
                    <div class="row " style="text-align:left;background-color:#eaeaea;color:dimgrey;"> 
                        <div class="col-1 text-left-vh-32"> 
                            原创
                        </div>
                        <div class="col-11">            
                            <div class="row text-left-vh-32"> 
                                <div class="col-8"> 
                                    <small>
                                        <i class="el-icon-user-solid"></i>
                                        &nbsp;
                                        {{this.$utils.localStorageGetItem("user_name")}}
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        于&nbsp;{{article.published_date_time}}&nbsp;发布
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <i class="el-icon-view"></i>
                                        {{article.viewed}}
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <i class="el-icon-star-off"></i>
                                        {{article.collection}}
                                    </small>                                   
                                </div>
                                <div class="col-4"> 
                                </div>                       
                            </div>
                            <div class="row text-left-vh-28">
                                <div class="col-6"> 
                                    <small> 
                                        <i class="el-icon-notebook-2"></i>
                                        &nbsp;
                                        文章标签：{{editor_small_title}}
                                    </small>
                                </div>                        
                            </div>
                        </div>
                    </div>
                    <p></p>           
                    <div class="row layout-scroll-parent" style="height:700px"> 
                        <div class="layout-scroll-child" > 
                            <p v-html="editor_content"></p>
                        </div>                        
                    </div>

                    <!--
                    <div class="row"> 
                        专栏中文章的条目
                    </div>
                    <hr/>
                    <div class="row" style="text-align:left"> 
                        
                        <div class="row" >
                            <div class="col-4">
                                <p></p> 
                                <h6>{{editor_big_title}}</h6>
                                <p></p>
                            </div>                                   
                        </div>
                        <div class="row">
                            <div class="col-12"> 
                                <small> 
                                    {{editor_summary}}
                                </small>
                            </div>
                        </div>
                        <p></p>
                        <div class="row"> 
                            <div class="col-12"> 
                                <small> 
                                    原创&nbsp;&nbsp;{{editor_published_date_time}}
                                </small>
                                
                            </div>
                        </div>
                        <p></p>
                        <hr/>
                    </div> -->
                </div>
            </div>
                           
        </div>
    </div>
</div>
</template>

<script>

import EditorView from './Components/EditorView.vue'
import {getClassifyByUser,getClassifyBasic} from '@/api/article-server/getData'
import {getArticle} from '@/api/article-server/getData'
import {updateArticle} from '@/api/article-server/saveData'

export default{

    components: { 
        EditorView
    },

    data(){
        return{

            article_cover_upload_action:this.$utils.getApiHostPort()+this.$url.get_article_cover_upload_action(),
            article_cover_img_1:'',
            article_cover_img_2:'',
            article_cover_img_3:'',

            article:'',

            editor_big_title: '',
            editor_small_title:'',
            editor_content:'',
            editor_summary:'',
            editor_classify_id:'',
            editor_classify_basic_id:'',
            editor_publiced:false,
            editor_published:'',
            editor_publish_date_enable:false,
            editor_published_date_time:'',
            editor_publish_date_disable:true,

            editor_small_title_placeholder:'输入关键词，使用空格隔开，若为空，将默认使用标题作为关键词',

            editor_published_date:'',
            editor_published_time:'',

            editor_classify:'',
            editor_classify_basic:''
        }
    },

    watch:{
        editor_big_title(){
            if(this.editor_small_title==''){
                this.editor_small_title = this.editor_big_title
            }
        },
    },

    methods:{

        goback(){
            this.$router.push("/articleview")
        },

        handleAvatarSuccess1(res, file) {
            this.article_cover_img_1 = URL.createObjectURL(file.raw);
            this.article_cover_img_1 = res.data
            console.log(res.data)
        },
        handleAvatarSuccess2(res, file) {
            this.article_cover_img_2 = URL.createObjectURL(file.raw);
            this.article_cover_img_2 = res.data
            console.log(res.data)
        },
        handleAvatarSuccess3(res, file) {
            this.article_cover_img_3 = URL.createObjectURL(file.raw);
            this.article_cover_img_3 = res.data
            console.log(res.data)
        },
        beforeAvatarUpload(file) {
            let types = ['image/jpeg', 'image/jpg', 'image/png'];
            const isImage = types.includes(file.type);
            // const isJPG = file.type === 'image/*';
            
            if (!isImage) {
                this.$message.error('上传文章封面只能是 jpeg、jpg、png 格式!');
            }

            const isLt2M = file.size / 1024 / 1024 < 4;
            if (!isLt2M) {
                this.$message.error('上传文章封面大小不能超过 4 MB!');
            }
            return isImage && isLt2M;
        },

    /**
     * 保存发布文章
     */
    //   id,token,user_phone,big_title,small_title,summary,content,classify_id,classify_basic_id,publiced,published
      async savePublishArticle(){
          //最后两项，公开，发布
          if(this.editor_publiced){
            // console.log(this.getClassifyId()) 
            const result = await updateArticle(this.article.id,this.editor_big_title,this.editor_small_title,this.editor_summary,this.article_cover_img_1,this.article_cover_img_2,this.article_cover_img_3,this.editor_content,this.editor_classify_id,this.editor_classify_basic_id,1,1)
            if(result.data.code == 0){
                alert("文章更新成功")
                this.$router.push({path:'/articleview'})
            }
          }else{
          //最后两项，私有，发布
        //   console.log(this.getClassifyId()) 
            const result = await updateArticle(this.article.id,this.editor_big_title,this.editor_small_title,this.editor_summary,this.article_cover_img_1,this.article_cover_img_2,this.article_cover_img_3,this.editor_content,this.editor_classify_id,this.editor_classify_basic_id,0,1)
            if(result.data.code == 0){
                alert("文章更新成功")
                this.$router.push({path:'/articleview'})
            }
          }
          
        //   console.log("publishArticle："+this.editor_big_title+","+this.editor_small_title+","+this.ediotr_summary+","+this.editor_content)
      },

      /**
       * 保存草稿文章
       */
      async saveDraftArticle(){
          //最后两项，私有，不发布
        //   console.log(this.getClassifyId()) 
            const result = await updateArticle(this.article.id,this.editor_big_title,this.editor_small_title,this.editor_summary,this.article_cover_img_1,this.article_cover_img_2,this.article_cover_img_3,this.editor_content,this.editor_classify_id,this.editor_classify_basic_id,0,0)
            if(result.data.code == 0){
                alert("草稿更新成功")
                this.$router.push({path:'/articleview'})
            }                          
        //   console.log("draftArticle："+localStorage.getItem('id')+","+this.editor_big_title+","+this.editor_small_title+","+this.ediotr_summary+","+this.editor_content)
      },

    /**
     * 弹出发布窗口
     */
    showModal() {
        this.$refs['my-modal'].show()
    },

    /**
    * 获取文章的数据
    * @param {文章的id} article_id 
    */
    async getArticle(article_id){
        const result = await getArticle(article_id)
        if(result.data.code==0){
            this.article = result.data.data
            this.editor_big_title = this.article.big_title
            this.editor_summary = this.article.summary
            this.editor_small_title = this.article.small_title
            if(this.article.publiced==0)this.editor_publiced = false
            if(this.article.publiced==1)this.editor_publiced = true   
            this.editor_published_date_time = this.article.published_date_time  
            this.editor_classify_basic_id = this.article.classify_basic_id        
            this.editor_classify_id = this.article.classify_id 
            this.article_cover_img_1 = this.article.cover.pics[0]
            this.article_cover_img_2 = this.article.cover.pics[1]
            this.article_cover_img_3 = this.article.cover.pics[2]
        }
    },

        /**
         * 获取编辑器内容
         * @param {编辑器内容} editorContent 
         */
        getContent(editorContent){
            this.editor_content = editorContent;
            
        },

    
        /**
         * 获取公共分类
         */
        async getClassifyBasic(){
            const result = await getClassifyBasic();
            if(result.data.code==0){
                this.editor_classify_basic = result.data.data
            }
        },

        /**
         * 获取私有分类
         */
        async getClassifyByUser(){
            const result = await getClassifyByUser(this.$utils.localStorageGetItem("user_id"),1,1000);
            if(result.data.code==0){
                this.editor_classify = result.data.data.current_data;
            }
        }


    },
    created(){
        this.getClassifyByUser();
        this.getClassifyBasic();
        this.getArticle(this.$route.query.id)
    }

}

</script>


<style lang="scss" scoped>

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 160px;
    text-align: center;
    margin-bottom: 50px;
  }
  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }

</style>



<!-- <template>
    <div>
        <el-form  label-width="80px">               
                <el-form-item label="文章标题">
                    <el-input v-model="editor_big_title" placeholder="文章标题"></el-input>
                </el-form-item>                
                <el-form-item label="文章简介">
                    <el-input type="textarea" v-model="editor_summary" placeholder="若为空，取文章前30字"></el-input>
                </el-form-item>
                <el-form-item label="文章内容">
                    <editor-view @childEditorContent="getContent"></editor-view> 
                </el-form-item>
                <br/>
                <el-form-item label="是否公开">
                    <el-switch v-model="editor_publiced"></el-switch>
                </el-form-item>
                <el-form-item label="所属分类">
                    <el-select v-model="editor_classify_basic_id"   placeholder="请选择分类">
                        <el-option
                        v-for="classify_basic in editor_classify_basic"
                        :key="classify_basic.id"
                        :label="classify_basic.name"
                        :value="classify_basic.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="我的专栏">                   
                    <el-select v-model="editor_classify_id" placeholder="请选择分类">                   
                        <el-option
                        v-for="classify in editor_classify"
                        :key="classify.id"
                        :label="classify.name"
                        :value="classify.id">
                        </el-option>
                    </el-select>
                </el-form-item>               
                <el-form-item label="关键词">
                    <el-input v-model="editor_small_title" placeholder="输入关键词，使用空格隔开，若为空，将默认使用标题作为关键词"></el-input>
                </el-form-item>   
                <el-form-item label="定时发布">
                    <el-switch
                        v-model="editor_publish_date_enable"
                        active-text="开启"
                        inactive-text="关闭">
                    </el-switch>
                </el-form-item>               
                <el-form-item>
                    <div class="row"> 
                        <div class="col-2"> 
                        </div>
                        <div class="col-2"> 
                            <el-button type="danger"  @click="savePublishArticle">立即发布</el-button>
                        </div>
                        <div class="col-2"> 
                        </div>
                        <div class="col-2"> 
                            <el-button type="success" @click="saveDraftArticle">保存草稿</el-button>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
    </div>
</template>

<script>
import EditorView from './Components/EditorView.vue'
import {getArticle} from '@/api/article-server/getData'

export default{

    data(){
        article:''
    },

    components: { 
        EditorView
    },

    async getArticle(article_id){
        const result = await getArticle(article_id)
        if(result.data.code==0){
            this.article = result.data.data
        }
    },

    

    created(){
        this.getArticle(this.$route.query.article_id)
    }

}

</script>


<style lang="scss" scoped>


</style> -->