<template>
    <div>
        <el-row> 
            <el-col span=6> 
                <el-form label-width="80px"> 
                    <el-form-item label="专栏标题"> 
                        <el-input v-model="classify_name"></el-input>
                    </el-form-item>
                    <el-form-item label="专栏简介"> 
                        <el-input v-model="classify_summary" type="textarea"></el-input>
                    </el-form-item>
                    <el-form-item label="选择封面"> 
                        <el-upload
                                class="avatar-uploader"
                                :action="classify_upload_action"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload">
                            <img v-if="classify_cover_img" :src="classify_cover_img" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>  
                    <el-form-item label="专栏公开"> 
                        <el-switch 
                            v-model="classify_open"
                            active-value=1
                            inactive-value=0
                            active-text=""
                            inactive-text="">
                        </el-switch>
                    </el-form-item>   
                    <el-form-item label="专栏付费"> 
                        <el-switch 
                            v-model="classify_charge"
                            active-value=1
                             inactive-value=0
                            active-text=""
                            inactive-text="">
                        </el-switch>
                    </el-form-item>   
                    <el-form-item label-width="150px">                         
                        <el-button @click="createClassify" type="primary" size="small" round>立即创建</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    
    </div>
</template>

<script>

import {saveClassify} from '@/api/article-server/saveData'


export default{

data(){
    return{
        classify_upload_action:this.$utils.getApiHostPort()+this.$url.get_classify_upload_action(),
        classify_name:'',
        classify_summary:'',
        classify_cover_img:'',
        classify_charge:'',
        classify_open:''
    }
    
},

methods:{
    handleAvatarSuccess(res, file) {
        this.classify_cover_img = URL.createObjectURL(file.raw);
        this.classify_cover_img = res.data
        console.log(res.data)
      },
    beforeAvatarUpload(file) {
        let types = ['image/jpeg', 'image/jpg', 'image/png'];
        const isImage = types.includes(file.type);
        // const isJPG = file.type === 'image/*';
        
        if (!isImage) {
            this.$message.error('上传分类专栏封面只能是 jpeg、jpg、png 格式!');
        }

        const isLt2M = file.size / 1024 / 1024 < 4;
        if (!isLt2M) {
            this.$message.error('上传专栏封面大小不能超过 4 MB!');
        }
        return isImage && isLt2M;
    },

    async createClassify(){
        const result  =  await saveClassify(this.$utils.localStorageGetItem("user_id"),this.classify_name,this.classify_summary,this.classify_cover_img,this.classify_open,this.classify_charge);
        if(result.data.code==0){
            this.classify_name = ''
            this.classify_summary = ''
            this.classify_cover_img = ''
            this.classify_open = ''
            this.classify_charge = ''
            alert('专栏创建成功！')
        }       
    }
}


}

</script>


<style lang="scss" scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

</style>